//@ts-check
import React from 'react';
import { ValueChangeStatus } from '../common/ValueChangeStatus'

/**
 * 企画詳細画面のアンケートフォーム
 * @param {object} props
 * @param {?Proposal} props.proposal 企画情報
 */
export const ProposalSurveyForm = ({ proposal }) => {
  return (
    <>
      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title"><i className="icn store"></i>おおまかな流通シェアを教えてください</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name">販売経路【流通先のシェアを教えてください】</dt>
            <dd className="ml15">
              <div className="dif a-center">
                <p className='nowrap'>
                  WEB通販
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareWeb}
                        prevValue={proposal.previousVersion.shareWeb}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="WEB通販"
                    title="WEB通販の％を入力してください"
                    aria-label="WEB通販"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareWeb ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　卸売
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareWholesale}
                        prevValue={proposal.previousVersion.shareWholesale}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="卸売"
                    title="卸売の％を入力してください"
                    aria-label="卸売"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareWholesale ?? ''} />
                </div>
                <p>%</p>
              </div>
            </dd>
          </dl>

          <dl className="pt10 mt20 bt-dashed-gray">
            <dt className="form-name">WEB通販</dt>
            <dd className="ml15">
              <p>
                <input type="checkbox" id="check-web"
                  name="自社EC"
                  title="自社EC"
                  disabled
                  checked={proposal?.shareOwnEcFlag ?? false} />
                <label htmlFor="check-web" className="form-checkbox">
                  自社ECを保有している場合はチェックをお願いします
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareOwnEcFlag}
                        prevValue={proposal.previousVersion.shareOwnEcFlag}
                        hideTooltip />
                    )
                  }
                </label>
              </p>
              <div className="dif a-center">
                <p>市場シェアを教えてください。</p>
                <p className='nowrap'>
                  自社EC
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareOwnEc}
                        prevValue={proposal.previousVersion.shareOwnEc}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="自社EC"
                    title="自社ECの％を入力してください"
                    aria-label="自社EC"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareOwnEc ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　他社EC
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareOtherEc}
                        prevValue={proposal.previousVersion.shareOtherEc}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="他社EC"
                    title="他社ECの％を入力してください"
                    aria-label="他社EC"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareOtherEc ?? ''} />
                </div>
                <p>%</p>
              </div>
            </dd>
          </dl>

          <dl className="pt10 mt20 bt-dashed-gray">
            <dt className="form-name">卸売【市場シェア率を教えてください】</dt>
            <dd className="ml15">
              <div className="dif a-center">
                <p className='nowrap'>
                  アニメ専門店
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareAnimation}
                        prevValue={proposal.previousVersion.shareAnimation}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="アニメ専門店"
                    title="アニメ専門店の％を入力してください"
                    aria-label="アニメ専門店"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareAnimation ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　家電量販店
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareKaden}
                        prevValue={proposal.previousVersion.shareKaden}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="家電量販店"
                    title="家電量販店の％を入力してください"
                    aria-label="家電量販店"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareKaden ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　CVS
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareCvs}
                        prevValue={proposal.previousVersion.shareCvs}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="CVS"
                    title="CVSの％を入力してください"
                    aria-label="CVS"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareCvs ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　GMS
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareGms}
                        prevValue={proposal.previousVersion.shareGms}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="GMS"
                    title="GMSの％を入力してください"
                    aria-label="GMS"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareGms ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　スーパーマーケット
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareSm}
                        prevValue={proposal.previousVersion.shareSm}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="スーパーマーケット"
                    title="スーパーマーケットの％を入力してください"
                    aria-label="スーパーマーケット"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareSm ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　ドラッグストア
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareDrug}
                        prevValue={proposal.previousVersion.shareDrug}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="ドラッグストア"
                    title="ドラッグストアの％を入力してください"
                    aria-label="ドラッグストア"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareDrug ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　ホビー
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareHobby}
                        prevValue={proposal.previousVersion.shareHobby}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="ホビー"
                    title="ホビーの％を入力してください"
                    aria-label="ホビー"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareHobby ?? ''} />
                </div>
                <p>%</p>
              </div>
              <div className="dif a-center mt10">
                <p className='nowrap'>
                  オンライン※くじ、プライズ、電子書籍
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareOnline}
                        prevValue={proposal.previousVersion.shareOnline}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    name="オンライン※くじ、プライズ、電子書籍"
                    title="オンライン※くじ、プライズ、電子書籍の％を入力してください"
                    aria-label="オンライン※くじ、プライズ、電子書籍"
                    pattern="\d{1,3}"
                    maxLength={3}
                    disabled
                    value={proposal?.shareOnline ?? ''} />
                </div>
                <p className='nowrap'>
                  %　／　その他
                  {
                    proposal?.previousVersion != null && (
                      <ValueChangeStatus
                        value={proposal.shareOther}
                        prevValue={proposal.previousVersion.shareOther}
                        inline />
                    )
                  }
                </p>
                <div className="input-form wdt50 ml10 mr10 cost">
                  <input type="text"
                    pattern="\d{1,3}"
                    maxLength={3}
                    name="その他"
                    title="その他の％を入力してください"
                    aria-label="その他"
                    disabled
                    value={proposal?.shareOther ?? ''} />
                </div>
                <p>%</p>
              </div>
            </dd>
          </dl>

          <dl>
            <dt className="form-name no-style">
              流通限定施策の場合は具体的な流通先名を、また、その他の場合は内訳をご記入ください。
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.shareWholesaleRemarks}
                    prevValue={proposal.previousVersion.shareWholesaleRemarks} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="市場シェア率"
                  title="流通限定施策の場合は具体的な流通先名を、また、その他の場合は内訳をご記入ください。"
                  aria-label="市場シェア率"
                  disabled
                  value={proposal?.shareWholesaleRemarks ?? ''} />
                <span className="attention">※卸売が無い場合は「無し」と記載をお願いします。</span>
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title"><i className="icn user"></i>契約希望商品のメイン・ターゲット</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name">
              商品のメイン・ターゲットは誰ですか？（年齢層、性別、購買目的、消費動向などのイメージ）
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.target}
                    prevValue={proposal.previousVersion.target} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="契約希望商品のメイン・ターゲット"
                  title="商品のメイン・ターゲットは誰ですか？（年齢層、性別、購買目的、消費動向などのイメージ）"
                  aria-label="契約希望商品のメイン・ターゲット"
                  disabled
                  value={proposal?.target ?? ''} />
              </div>
            </dd>
          </dl>
        </div>
      </section>

      <section className="mt40">
        <div className="title-aniplex">
          <h2 className="title"><i className="icn lightbulb"></i>契約希望商品のための販促施策</h2>
        </div>

        <div>
          <dl>
            <dt className="form-name">
              商品を店頭にならべるために、どのような働きかけをされますか？（対流通施策）
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.promotionChannel}
                    prevValue={proposal.previousVersion.promotionChannel} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4}
                  name="契約希望商品のための販促施策（対流通施策）"
                  title="商品を店頭にならべるために、どのような働きかけをされますか？（対流通施策）"
                  aria-label="契約希望商品のための販促施策（対流通施策）"
                  disabled
                  value={proposal?.promotionChannel ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name">
              店頭でお客様に手にとってもらえるために、どのような働きかけをされますか？（対ユーザー施策）
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.promotionUser}
                    prevValue={proposal.previousVersion.promotionUser} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4}
                  name="契約希望商品のための販促施策（対ユーザー施策）"
                  title="店頭でお客様に手にとってもらえるために、どのような働きかけをされますか？（対ユーザー施策）"
                  aria-label="契約希望商品のための販促施策（対ユーザー施策）"
                  disabled
                  value={proposal?.promotionUser ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name">
              想定しうるWEBでのプロモーション施策を教えて下さい。
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.promotionWeb}
                    prevValue={proposal.previousVersion.promotionWeb} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4}
                  name="WEBでのプロモーション施策"
                  title="想定しうるWEBでのプロモーション施策を教えて下さい。"
                  aria-label="WEBでのプロモーション施策"
                  disabled
                  value={proposal?.promotionWeb ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name">
              自社Twitterの有無を教えて下さい。お持ちの場合はアカウント名の記載をお願いします。
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.promotionTwitter}
                    prevValue={proposal.previousVersion.promotionTwitter} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="自社Twitterの有無"
                  title="自社Twitterの有無を教えて下さい。お持ちの場合はアカウント名の記載をお願いします。"
                  aria-label="自社Twitterの有無"
                  disabled
                  value={proposal?.promotionTwitter ?? ''} />
              </div>
            </dd>
          </dl>

          <dl className="mt15">
            <dt className="form-name">
              展示会、商談会等への出展予定があればご記入ください。
              {
                proposal?.previousVersion != null && (
                  <ValueChangeStatus
                    value={proposal.promotionDisplay}
                    prevValue={proposal.previousVersion.promotionDisplay} />
                )
              }
            </dt>
            <dd className="form-body">
              <div className="form-textarea wdt1200">
                <textarea rows={4} name="出展予定"
                  title="展示会、商談会等への出展予定があればご記入ください。"
                  aria-label="出展予定"
                  disabled
                  value={proposal?.promotionDisplay ?? ''} />
              </div>
            </dd>
          </dl>
        </div>
      </section>
    </>
  )
}

//#region typedef
/**
 * @typedef {import('./ProposalDetailForm').Proposal} Proposal 企画情報
 */
//#endregion typedef
