import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../Constants";
import { duplicateImage, getProposal, getProposalsAll, patchProduct, patchProposal, patchSampleDate, postProposal } from "../../lib/api/licensee";
import { ApiErrorMessage } from "../../lib/message";
import { handleLicenseeApiError } from "../../lib/util";

/**
 * 初期値
 */
const initialState = {
  fetchAll: null,
  /** @type {number|null} 申請された企画書の企画内部コード */
  appliedProposalId: null,
  /** @type {ProposalDetail|null} 取得した企画詳細情報 */
  proposalDetail: null,
  /** @type {CopiedProductImage[]} 商品イメージ複製APIの結果 */
  copiedProductImageList: [],
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus : {
    /** 企画一覧 */
    fetchAll: null,
    /** 企画申請処理 */
    applyProposal: null,
    /** 企画詳細取得処理 */
    fetchProposalDetail: null,
    /** 企画取り下げ処理 */
    cancelProposal: null,
    /** 更新開始処理 */
    permitUpdateProposal: null,
    /** 企画削除処理 */
    deleteProposal: null,
    /** 商品完成イメージURL登録処理 */
    registerProductImageUrl: null,
    /** サンプル送付日更新処理 */
    updateSampleDate: null,
    /** 商品イメージ複製処理 */
    copyProductImage: null,
  },
}

/**
 * 企画情報一覧取得
 */
export const fetchAll = createAsyncThunk(
  'licensee/proposals/fetchAll',
  /**
   * @param {GetProposalsParam} params
   */
  async (params, { dispatch }) => {
    try {
      const data = await getProposalsAll(params);
      return data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch)
    }
  }
)

/**
 * 企画申請処理
 */
export const applyProposal = createAsyncThunk(
  'licensee/proposals/applyProposal',
  /**
   * 企画申請処理
   * @param {PostProposalParam} data 申請する企画のデータ
   * @returns
   */
  async (data, { dispatch }) => {
    const param = filterOnlyApplyProposalParams(data)

    try {
      const result = await postProposal(param);
      return result.result.data;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '20801': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '20802': ApiErrorMessage.UpdateStateMismatch,
        // メッセージ登録失敗
        '20803': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 企画削除処理
 */
export const deleteProposal = createAsyncThunk(
  'licensee/proposals/deleteProposal',
  /**
   * 企画削除処理
   * @param {ProposalDetail} proposal 削除対象の企画情報
   */
  async (proposal, { dispatch }) => {
    /** @type {import('../../lib/api/licensee').PatchProposalParam} */
    const param = {
      proposalStatus: Constants.Licensee.ProposalStatus.Deleted,
      updateDatetime: proposal.updateDatetime,
    };

    try {
      const result = await patchProposal(proposal.proposalId, param);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21001': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21002': ApiErrorMessage.UpdateStateMismatch,
        // 履歴登録失敗
        '21003': ApiErrorMessage.HistoryRegisterFailed,
        // メッセージ登録失敗
        '21004': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 企画取り下げ処理
 */
export const cancelProposal = createAsyncThunk(
  'licensee/proposals/cancelProposal',
  /**
   * 企画削除処理
   * @param {object} params
   * @param {string} params.messageContent  取り下げ理由
   * @param {ProposalDetail} params.proposal 削除対象の企画情報
   */
  async ({messageContent, proposal}, { dispatch }) => {
    /** @type {import('../../lib/api/licensee').PatchProposalParam} */
    const param = {
      proposalStatus: Constants.Licensee.ProposalStatus.Canceled,
      // 取り下げ理由
      messageContent,
      updateDatetime: proposal.updateDatetime,
    };

    try {
      const result = await patchProposal(proposal.proposalId, param);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21001': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21002': ApiErrorMessage.UpdateStateMismatch,
        // 履歴登録失敗
        '21003': ApiErrorMessage.HistoryRegisterFailed,
        // メッセージ登録失敗
        '21004': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 更新開始処理
 */
export const permitUpdateProposal = createAsyncThunk(
  'licensee/proposals/permitUpdateProposal',
  /**
   * 更新開始処理
   * @param {object} params
   * @param {string} params.messageContent 更新理由
   * @param {ProposalDetail} params.proposal 更新開始対象の企画
   */
  async ({ messageContent, proposal }, { dispatch }) => {
    /** @type {PatchProposalParam} */
    const param = {
      proposalStatus: Constants.Licensee.ProposalStatus.Permitted,
      updateDatetime: proposal.updateDatetime,
      messageContent,
    };

    try {
      const result = await patchProposal(proposal.proposalId, param);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21001': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21002': ApiErrorMessage.UpdateStateMismatch,
        // 履歴登録失敗
        '21003': ApiErrorMessage.HistoryRegisterFailed,
        // メッセージ登録失敗
        '21004': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 企画詳細取得処理
 */
export const fetchProposalDetail = createAsyncThunk(
  'licensee/proposals/fetchProposalDetail',
  /**
   * 企画詳細取得処理
   * @param {number} proposalId 企画内部コード
   * @returns
   */
  async (proposalId, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearProposalDetail());

    try {
    const data = await getProposal(proposalId);
    return data;
    } catch (err) {
      handleLicenseeApiError(err, thunkApi.dispatch, {
        // データ不正
        '20901': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 商品完成イメージURL登録処理
 */
export const registerProductImageUrl = createAsyncThunk(
  'licensee/proposals/registerProductImageUrl',
  /**
   * 商品完成イメージURL登録処理
   * @param {RegisterProductImageUrlParams} data
   */
  async (data, { dispatch }) => {
    /** @type {import('../../lib/api/licensee').PatchProductParam} */
    const params = {
      productImageUrl: data.productImageUrl,
      productImageRegDatetime: data.productImageRegDatetime,
    };

    try {
      const result = await patchProduct(data.productId, params);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21201': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21202': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * サンプル送付日更新処理
 */
export const updateSampleDate = createAsyncThunk(
  'licensee/proposals/updateSampleDate',
  /**
   * サンプル送付日更新処理
   * @param {UpdateSampleDateParam} data
   */
  async (data, { dispatch }) => {
    /** @type {import('../../lib/api/licensee').PatchSampleDateParam} */
    const param = {
      productList: data.productList,
    };

    try {
      const result = await patchSampleDate(data.proposalId, param);
      return result;
    } catch (err) {
      handleLicenseeApiError(err, dispatch, {
        // データ不正
        '21301': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '21302': ApiErrorMessage.UpdateStateMismatch,
      });
    }
  }
)

/**
 * 商品イメージ複製処理
 */
export const copyProductImage = createAsyncThunk(
  'licensee/proposals/copyProductImage',
  /**
   * 商品イメージ複製処理
   * @param {number[]} imageNoList 複製する商品イメージNoのリスト
   */
  async (imageNoList) => {
    const result = await duplicateImage(imageNoList, { suppressError: true });
    return result.duplicateImageList;
  }
)

/**
 * ライセンシー向け画面の企画情報周りのスライス
 */
export const proposalsSlice = createSlice({
  name: 'licensee/proposals',
  initialState,
  reducers: {
    /**
     * 保持している申請された企画書の内部コードをクリアする
     * @param {*} state
     */
    clearAppliedProposalId: (state) => {
      state.appliedProposalId = null;
    },
    /**
     * 取得済みの企画一覧情報をクリアする
     * @param {*} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null;
    },
    /**
     * 取得済みの企画詳細情報をクリアする
     * @param {*} state
     */
    clearProposalDetail: (state) => {
      state.proposalDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch(action.payload) {
        // 企画情報一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // 企画申請処理
        case 'applyProposal':
          state.apiStatus.applyProposal = null;
          break;
        // 企画詳細取得処理
        case 'fetchProposalDetail':
          state.apiStatus.fetchProposalDetail = null;
          break;
        // 企画削除処理
        case 'deleteProposal':
          state.apiStatus.deleteProposal = null;
          break;
        // 企画取り下げ処理
        case 'cancelProposal':
          state.apiStatus.cancelProposal = null;
          break;
        // 更新開始処理
        case 'permitUpdateProposal':
          state.apiStatus.permitUpdateProposal = null;
          break;
        // 商品完成イメージURL登録処理
        case 'registerProductImageUrl':
          state.apiStatus.registerProductImageUrl = null;
          break;
        // サンプル送付日更新処理
        case 'updateSampleDate':
          state.apiStatus.updateSampleDate = null;
          break;
        // 商品イメージ複製処理
        case 'copyProductImage':
          state.apiStatus.copyProductImage = null;
          break;
        default:
          break;
      }
    }
  },
  extraReducers: builder => {
    builder
      // 企画情報一覧取得処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // 企画申請処理
      .addCase(applyProposal.pending, (state) => {
        state.apiStatus.applyProposal = 'loading';
      })
      .addCase(applyProposal.fulfilled, (state, action) => {
        state.apiStatus.applyProposal = 'finish';
        // 返却された企画内部コードを保持
        state.appliedProposalId = action.payload.proposalId
      })
      .addCase(applyProposal.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.applyProposal = null;
      })
      // 企画詳細取得処理
      .addCase(fetchProposalDetail.pending, (state) => {
        state.apiStatus.fetchProposalDetail = 'loading';
      })
      .addCase(fetchProposalDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchProposalDetail = 'finish';
        state.proposalDetail = action.payload;
      })
      .addCase(fetchProposalDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchProposalDetail = null;
      })
      // 企画削除処理
      .addCase(deleteProposal.pending, (state) => {
        state.apiStatus.deleteProposal = 'loading';
      })
      .addCase(deleteProposal.fulfilled, (state) => {
        state.apiStatus.deleteProposal = 'finish';
      })
      .addCase(deleteProposal.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.deleteProposal = null;
      })
      // 企画取り下げ処理
      .addCase(cancelProposal.pending, (state) => {
        state.apiStatus.cancelProposal = 'loading';
      })
      .addCase(cancelProposal.fulfilled, (state) => {
        state.apiStatus.cancelProposal = 'finish';
      })
      .addCase(cancelProposal.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.cancelProposal = null;
      })
      // 更新開始処理
      .addCase(permitUpdateProposal.pending, (state) => {
        state.apiStatus.permitUpdateProposal = 'loading';
      })
      .addCase(permitUpdateProposal.fulfilled, (state) => {
        state.apiStatus.permitUpdateProposal = 'finish';
      })
      .addCase(permitUpdateProposal.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.permitUpdateProposal = null;
      })
      // 商品完成イメージURL登録処理
      .addCase(registerProductImageUrl.pending, (state) => {
        state.apiStatus.registerProductImageUrl = 'loading';
      })
      .addCase(registerProductImageUrl.fulfilled, (state) => {
        state.apiStatus.registerProductImageUrl = 'finish';
      })
      .addCase(registerProductImageUrl.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.registerProductImageUrl = null;
      })
      // サンプル送付日更新処理
      .addCase(updateSampleDate.pending, (state) => {
        state.apiStatus.updateSampleDate = 'loading';
      })
      .addCase(updateSampleDate.fulfilled, (state) => {
        state.apiStatus.updateSampleDate = 'finish';
      })
      .addCase(updateSampleDate.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateSampleDate = null;
      })
      // 商品イメージ複製処理
      .addCase(copyProductImage.pending, (state) => {
        state.apiStatus.copyProductImage = 'loading';
      })
      .addCase(copyProductImage.fulfilled, (state, action) => {
        state.apiStatus.copyProductImage = 'finish'
        state.copiedProductImageList = action.payload;
      })
      .addCase(copyProductImage.rejected, (state) => {
        state.apiStatus.copyProductImage = 'error';
      });
  }
});

export const {
  clearApiStatus,
  clearFetchAll,
  clearAppliedProposalId,
  clearProposalDetail,
} = proposalsSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.licensee.proposals.apiStatus;

/**
 * 企画一覧を取得する
 * @param {*} state
 * @returns {{ result: { data: ProposalListItem[] }}|null}
 */
export const fetchAllProposals = (state) => state.licensee.proposals.fetchAll;

/**
 * stateから申請された企画情報の企画内部コードを取得する
 * @param {*} state
 * @returns {number|null} 企画内部コード
 */
export const selectAppliedProposalId = (state) => state.licensee.proposals.appliedProposalId;

/**
 * stateから企画詳細情報を取得する
 * @param {*} state
 * @returns {ProposalDetail|null} 企画詳細情報
 */
export const selectProposalDetail = (state) => state.licensee.proposals.proposalDetail;

/**
 * stateから複製された商品イメージの情報を取得する
 * @param {*} state
 * @returns {CopiedProductImage[]} 複製された商品イメージ情報
 */
export const selectCopiedProductImageList = (state) => state.licensee.proposals.copiedProductImageList;

export default proposalsSlice.reducer;

/**
 * 企画申請APIのパラメータのみに絞り込む
 * @param {ProposalDetail} proposal 企画情報
 * @returns {import('../../lib/api/licensee').PostProposalParam} 企画申請APIのパラメータ
 */
function filterOnlyApplyProposalParams(proposal) {
  /** @type {(keyof PostProposalParam)[]} */
  const proposalParams = [
    'proposalId', 'proposalStatus', 'propertySummaryCode', 'proposalTitle',
    'proposalLaunchDate', 'proposalDetail', 'proposalRemarks', 'contractStartDate',
    'contractEndDate', 'ryReq1', 'ryReq2', 'ryReq3', 'ryReq4', 'ryReq5', 'ryReq6',
    'ryReq7', 'ryReq8', 'ryReq9', 'ryReq10', 'ryReq11', 'ryReq12',
    'shareWeb', 'shareWholesale', 'shareOwnEcFlag', 'shareOwnEc', 'shareOtherEc',
    'shareAnimation', 'shareKaden', 'shareCvs', 'shareGms', 'shareSm', 'shareDrug',
    'shareHobby', 'shareOnline', 'shareOther', 'shareWholesaleRemarks', 'target',
    'promotionChannel', 'promotionUser', 'promotionWeb',
    'promotionTwitter', 'promotionDisplay', 'updateDatetime', 'automaticContractUpdateReq', 'ryReportCategory',
    'messageContent',
  ];
  const periodParams = ['period', 'ryStartDate', 'ryEndDate'];
  const productParams = [
    'productId', 'productName', 'categoryNo', 'categoryDetailNo',
    'character', 'version', 'launchDate', 'rypId', 'planPrice',
    'planCost', 'planProceeds', 'ryRate', 'ryPrice', 'planRyAmount',
    'salesMethod', 'productMethod', 'material', 'characterLineup', 'productRemarks',
    'productOption1', 'productOption2', 'productOption3',
  ];
  const productPeriodParams = ['period', 'planProduction', 'planSales'];
  const renderingImageParams = ['renderingImageNo'];
  const proposalAttachmentListParams = ['proposalAttachmentNo'];

  /** @type {import('../../lib/api/licensee').PostProposalParam} */
  const result = {};

  for (const key in proposal) {
    if (proposalParams.includes(key)) {
      result[key] = proposal[key];
    }

    // TODO: 以下の処理がproposalのkeyの数だけ多重に呼ばれているように見える

    const periodList = [];
    for (const period of proposal.periodList) {
      const p = {};
      for (const pKey in period) {
        if (periodParams.includes(pKey)) {
          p[pKey] = period[pKey];
        }
      }
      periodList.push(p);
    }
    result.periodList = periodList;

    const productList = [];
    for (const product of proposal.productList) {
      const p = {};
      for (const pKey in product) {
        if (productParams.includes(pKey)) {
          p[pKey] = product[pKey];
        }
      }

      const productPeriodList = [];
      for (const period of product.periodList) {
        const pr = {};
        for (const prKey in period) {
          if (productPeriodParams.includes(prKey)) {
            pr[prKey] = period[prKey];
          }
        }
        productPeriodList.push(pr);
      }
      p.periodList = productPeriodList;

      const renderingImageList = [];
      for (const image of product.renderingImageList ?? []) {
        const img = {};
        for (const imgKey in image) {
          if (renderingImageParams.includes(imgKey)) {
            img[imgKey] = image[imgKey];
          }
        }
        renderingImageList.push(img);
      }
      p.renderingImageList = renderingImageList;

      productList.push(p);
    }
    result.productList = productList;
  }

  const proposalAttachmentList = [];
  for (const proposalAttachment of proposal.proposalAttachmentList ?? []) {
    const attachment = {};
    for (const attachmentKey in proposalAttachment) {
      if (proposalAttachmentListParams.includes(attachmentKey)) {
        attachment[attachmentKey] = proposalAttachment[attachmentKey];
      }
    }
    proposalAttachmentList.push(attachment);
  }
  result.proposalAttachmentList = proposalAttachmentList;

  return result
}

//#region typedef
/**
 * @typedef {'fetchAll'|'applyProposal'|'fetchProposalDetail'|'deleteProposal'|'cancelProposal'|'permitUpdateProposal'|'registerProductImageUrl'|'updateSampleDate'|'copyProductImage'} ApiProcessName API呼び出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {import('../../lib/api/licensee').GetProposalsParam} GetProposalsParam
 */
/**
 * @typedef {object} ProposalListItem 企画一覧情報
 * @property {number} proposalId 企画内部コード
 * @property {string} iconFilename アイコンファイル名
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {'TMP'|'CAN'|'REQ'|'REJ'|'APP'|'SUS'|'FIN'} proposalStatus 申請ステータス
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} applicantUserName 申請者氏名
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} startDate 許諾期間開始(YYYY/MM/DD)
 * @property {string} endDate 許諾期間終了(YYYY/MM/DD)
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ProposalDetail 企画詳細
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {number} revision 版数
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {'TMP'|'CAN'|'REQ'|'REJ'|'APP'|'SUS'|'FIN'} proposalStatus 申請ステータス（ライセンシー表示）
 * @property {string} propertySummaryCode 作品コード
 * @property {string} proposalTitle 企画件名
 * @property {string} proposalLaunchDate 発売開始希望日(YYYY/MM/DD)
 * @property {string} proposalDetail 企画説明
 * @property {string} proposalRemarks 備考
 * @property {string} contractStartDate 契約開始日(YYYY/MM/DD)
 * @property {string} contractEndDate 契約終了日(YYYY/MM/DD)
 * @property {boolean} ryReq1 ロイヤリティ報告締め月_1月
 * @property {boolean} ryReq2 ロイヤリティ報告締め月_2月
 * @property {boolean} ryReq3 ロイヤリティ報告締め月_3月
 * @property {boolean} ryReq4 ロイヤリティ報告締め月_4月
 * @property {boolean} ryReq5 ロイヤリティ報告締め月_5月
 * @property {boolean} ryReq6 ロイヤリティ報告締め月_6月
 * @property {boolean} ryReq7 ロイヤリティ報告締め月_7月
 * @property {boolean} ryReq8 ロイヤリティ報告締め月_8月
 * @property {boolean} ryReq9 ロイヤリティ報告締め月_9月
 * @property {boolean} ryReq10 ロイヤリティ報告締め月_10月
 * @property {boolean} ryReq11 ロイヤリティ報告締め月_11月
 * @property {boolean} ryReq12 ロイヤリティ報告締め月_12月
 * @property {number} shareWeb WEB通販比率
 * @property {number} shareWholesale 卸売比率
 * @property {boolean} shareOwnEcFlag 自社EC有無
 * @property {number} shareOwnEc Web通販比率_自社EC
 * @property {number} shareOtherEc Web通販比率_他社EC
 * @property {number} shareAnimation 卸売比率_アニメ専門店
 * @property {number} shareKaden 卸売比率_家電量販店
 * @property {number} shareCvs 卸売比率_CVS
 * @property {number} shareGms 卸売比率_GMS
 * @property {number} shareSm	卸売比率_スーパーマーケット
 * @property {number} shareDrug 卸売比率_ドラッグストア
 * @property {number} shareHobby 卸売比率_ホビー
 * @property {number} shareOnline 卸売比率_オンライン（くじ、プライズ、電子書籍）
 * @property {number} shareOther 卸売比率_その他
 * @property {string} shareWholesaleRemarks 卸売備考（流通先名、その他内訳）
 * @property {string} target メイン・ターゲット
 * @property {string} promotionChannel 対流通施策
 * @property {string} promotionUser 対ユーザー施策
 * @property {string} promotionWeb WEB対策
 * @property {string} promotionTwitter Twitter情報
 * @property {string} promotionDisplay 展示会・カタログ情報
 * @property {ProposalPeriod[]} periodList 第N期のリスト
 * @property {ProposalProduct[]} productList 許諾商品のリスト
 * @property {ProposalSDecision} sDecision S決裁情報
 * @property {string} importLicenseUrl 輸入許諾書URL
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} approvalDatetime 承認日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} rejectMessage 差し戻し理由
 * @property {string} cancelMessage 取り下げ理由
 * @property {boolean} updateLimitFlag 更新制限フラグ
 * @property {boolean} automaticContractUpdateReq 自動契約更新リクエスト
 * @property {boolean} automaticContractUpdateFlag 自動契約更新制御フラグ
 * @property {valueOf<Constants['Licensee']['RyReportCategory']>} ryReportCategory ロイヤリティ報告区分 MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 * @property {MessageHistory[]} messageHistoryList 連絡事項のリスト
 */
/**
 * @typedef {object} ProposalPeriod 企画情報内の第N期の情報
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日
 * @property {?number} ryReportId ロイヤリティ報告書内部コード
 * @property {?'TMP'|'REQ'|'REJ'|'APP'|'EXP'} reportStatus ロイヤリティ報告ステータス
 */
/**
 * @typedef {object} ProposalProduct 企画情報内の許諾商品情報
 * @property {number} productId 許諾商品内部コード
 * @property {string} productNo 品番
 * @property {string} productName	商品名
 * @property {'REG'|'OK'|'SUS'} productStatus 許諾商品ステータス
 * @property {number} categoryNo 商品カテゴリーNo
 * @property {number} categoryDetailNo 商品カテゴリー詳細No
 * @property {string} character キャラクター
 * @property {string} version バージョン
 * @property {string} launchDate 発売希望日
 * @property {string} rypId ロイヤリティ報告パターンID
 * @property {number} planPrice 予定上代
 * @property {number} planCost 予定製造原価
 * @property {number} planProceeds 予定売上金額
 * @property {number} planRyAmount 予定ロイヤリティ金額
 * @property {number} ryRate ロイヤリティ料率
 * @property {number} ryPrice ロイヤリティ単価
 * @property {number} calPlanProceeds 予定販売金額
 * @property {number} calPlanRyTarget 予定ロイヤリティ対象金額
 * @property {number} calPlanRyAmount 予定ロイヤリティ金額
 * @property {number} latestPrice 最新上代
 * @property {number} latestCost 最新製造原価
 * @property {number} calResultProceeds 予定販売金額
 * @property {number} calResultRyTarget 予定ロイヤリティ対象金額
 * @property {number} calResultRyAmount 予定ロイヤリティ金額
 * @property {'1'|'2'} salesMethod 販売方式
 * @property {'1'|'2'} productMethod 生産方式
 * @property {string} [material] 素材他仕様
 * @property {string} characterLineup キャラクターラインナップ
 * @property {string} [productRemarks] 備考
 * @property {ProposalProductPeriod[]} periodList 第N期のリスト
 * @property {ProposalProductRenderingImage[]} renderingImageList 商品イメージのリスト
 * @property {string} sampleDate サンプル送付日(YYYY/MM/DD)
 * @property {string} sampleRegDatetime サンプル送付登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} productImageUrl 完成イメージURL
 * @property {string} productImageRegDatetime 完成イメージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} [productOption1] 自由記入欄1
 * @property {string} [productOption2] 自由記入欄2
 * @property {string} [productOption3] 自由記入欄3
 */
/**
 * @typedef {object} ProposalProductPeriod 企画情報内の商品情報内の第N期の情報
 * @property {number} period 第N期
 * @property {number} planProduction 予定生産数
 * @property {number} planSales 予定販売数
 * @property {number} resultLabel 証紙発行数
 * @property {number} resultProduction 実績生産数
 * @property {number} resultSales 実績販売数
 */
/**
 * @typedef {object} ProposalProductRenderingImage 企画情報内の商品情報内の商品イメージの情報
 * @property {number} renderingImageNo 商品イメージNo
 */
/**
 * @typedef {object} ProposalSDecision 企画情報内のS決裁情報
 * @property {string} sDecisionNo S決裁No
 * @property {string} startDate 許諾期間開始(YYYY/MM/DD)
 * @property {string} endDate 許諾期間終了(YYYY/MM/DD)
 * @property {string} sLaunchDate 発売予定日
 * @property {number} kyodakukin 許諾金
 * @property {number} mg MG
 * @property {number} mgZan mg残高
 * @property {number} ad アドバンス
 * @property {number} adZan アドバンス残高
 * @property {string} propertyCode プロパティコード
 * @property {string} propertyName プロパティ名
 * @property {number} proposalCount S決済情報に紐づく企画書の件数
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'CAN'|'PER'|'REJ'|'SUS'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * CAN: 取り下げ
 * PER: 更新
 * REJ: 差し戻し
 * SUS: 中止
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {'ANX'|'LCS'} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
/**
 * @typedef {object} RegisterProductImageUrlParams 商品完成イメージURL登録処理のパラメータ
 * @property {number} productId 許諾商品内部コード
 * @property {string} productImageUrl 完成イメージURL
 * @property {string} [productImageRegDatetime] 完成イメージ登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} UpdateSampleDateParam サンプル送付日更新処理のパラメータ
 * @property {number} proposalId 企画内部コード
 * @property {UpdateSampleDateParamItem[]} productList 更新する商品のリスト
 */
/**
 * @typedef {object} UpdateSampleDateParamItem サンプル送付日更新処理の商品リストアイテム
 * @property {number} productId 許諾商品内部コード
 * @property {string} sampleDate サンプル送付日(YYYY/MM/DD)
 * @property {string} sampleRegDatetime サンプル送付日最終登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} CopiedProductImage 商品イメージ複製結果
 * @property {number} renderingImageNoBefore 複製元の商品イメージNo
 * @property {number} renderingImageNoAfter 複製後の商品イメージNo
 */
/**
 * @typedef {import('../../lib/api/licensee').PostProposalParam} PostProposalParam
 */
/**
 * @typedef {import('../../lib/api/licensee').PatchProposalParam} PatchProposalParam
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion
