//@ts-check
import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { statusListLabel } from '../../common/headerList';
import { useSaveFormData } from '../../../lib/hooks/common';
import { Constants } from '../../../Constants';

/**
 * 検索パラメーターの初期値
 * @type {SearchFormData}
 */
export const defaultParams = {
  labelStatus: '',
  applicantUserName: '',
  proposalNo: '',
  proposalTitle: '',
  applicationDateFrom: undefined,
  applicationDateTo: undefined,
};

/**
 * 日付型のパラメータ
 * @satisfies {(keyof SearchFormData)[]}
 */
export const dateParams = [
  'applicationDateFrom',
  'applicationDateTo',
];

/**
 * [L]証紙申請一覧検索フォーム
 * @param {object} props
 * @param {SearchFormData} props.params 検索フォームデータ
 * @param {React.Dispatch<React.SetStateAction<SearchFormData>>} props.setParams 検索フォームデータ設定処理
 * @param {(params: SearchFormData) => void} props.onSearch 検索処理
 */
export const LabelRequestListSearchForm = ({ params, setParams, onSearch }) => {

  // 検索パラメータ保存関連
  const { saveData } = useSaveFormData({
    saveKey: Constants.Licensee.SearchFormSaveKey.LabelRequestList,
    dataType: params,
    dateParams,
  });

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value }));
  }, [setParams]);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, [setParams]);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    // 現在のパラメータを保存
    saveData(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefinedにする・日付はフォーマットする)
    onSearch(params);
  }, [saveData, params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    const params = {...defaultParams};
    // 現在のパラメータを保存
    saveData(params);
    setParams(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    onSearch(params);
  }, [onSearch, saveData, setParams]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '89px' }}>企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="proposalNo" title="企画書Noを入力してください" aria-label="企画書No"
                value={params.proposalNo}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="proposalTitle" title="企画件名を選択してください" aria-label="企画件名"
                value={params.proposalTitle}
                onChange={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '89px' }}>申請日</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateFrom"
                title="申請開始日を入力してください"
                aria-label="申請開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateFrom}
                onChange={date => onDateChange('applicationDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateTo"
                title="申請終了日を入力してください"
                aria-label="申請終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateTo}
                onChange={date => onDateChange('applicationDateTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">証紙申請ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="labelStatus" title="証紙申請ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.labelStatus}>
                <option value=""></option>
                {Object.keys(statusListLabel).map(i => (
                  <option value={i} key={i}>{statusListLabel[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">申請者</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="applicantUserName" title="申請者を入力してください" aria-label="申請者"
                value={params.applicantUserName}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt15">
        <p className="btn bg-blue" style={{ width: '160px' }}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>

        <p className="btn c-pink" style={{ width: '160px' }}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームデータ
 * @property {string} proposalNo 企画No※部分一致検索
 * @property {string} proposalTitle 企画件名※部分一致検索
 * @property {Date|undefined|null} applicationDateFrom 申請日From
 * @property {Date|undefined|null} applicationDateTo 申請日To
 * @property {valueOf<Constants['Licensee']['labelStatus']>|''} labelStatus 証紙申請ステータス
 * TMP: 一時保存
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 承認済み
 * @property {string} applicantUserName 申請者氏名※部分一致検索
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
