import React, { useCallback, useMemo } from "react";
import { basename } from "../../../lib/util";
import { UploadFile } from "../UploadFile";
import { DropZoneDisabled } from '../../common/FileDropZone';
import { UploadFileShowOnly } from "../UploadFileShowOnly";

/** アップロード可能なファイル拡張子 */
const acceptableExt = [
  'pdf',
  'doc', 'docm', 'docx',
  'xls', 'xlsm', 'xlsx',
  'jpg', 'jpeg',
  'png', 'gif',
];

/**
 * ロイヤリティ報告画面の証憑アップロードフォーム
 * @param {object} props
 * @param {object[]} props.newFileList 新たにアップロードされた確証のリスト
 * @param {Function} props.setNewFileList 新規アップロード確証リスト変更時のコールバック
 * @param {object[]} props.uploadedFileList アップロード済みの確証のリスト
 * @param {Function} props.setUploadedFileList アップロード済み確証リスト変更時のコールバック
 * @param {(hasError: boolean) => void} props.onChangeHasError アップロードエラー状態変更時のコールバック
 * @param {(hasProgress: boolean) => void} props.onChangeHasProgress アップロード進行状態変更時のコールバック
 * @param {(hasExceeded: boolean) => void} props.onChangeHasExceeded アップロード証憑数超過状態変更時のコールバック
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {boolean} props.isEditable 編集可能モードフラグ
 * @returns
 */
export const RoyaltyProofForm = ({
  newFileList,
  setNewFileList,
  uploadedFileList,
  setUploadedFileList,
  onChangeHasError,
  onChangeHasProgress,
  onChangeHasExceeded,
  formLocked,
  isEditable,
}) => {
  /** アップロードコンポーネント用の新規ファイルリスト */
  const newFileListForUploader = useMemo(() => {
    return newFileList.map(i => ({
      fileNo: i.ryProofNo,
    }));
  }, [newFileList]);

  /** アップロードコンポーネントの用のアップロード済みファイルリスト */
  const uploadedFileListForUploader = useMemo(() => {
    return uploadedFileList.map(i => ({
      fileNo: i.ryProofNo,
      filename: basename(i.ryProofFilepath),
    }));
  }, [uploadedFileList]);

  /** ファイルアップロード時のコールバック */
  const onChangeFileNoList = useCallback((fileNoList) => {
    setNewFileList(
      fileNoList.map(no => ({ ryProofNo: no }))
    );
  }, [setNewFileList]);
  /** アップロード済みファイル変更時のコールバック */
  const onChangeUploaded = useCallback((fileNoList) => {
    setUploadedFileList(prev => {
      return fileNoList.map(no => {
        const found = prev.find(i => i.ryProofNo === no);
        return {
          ryProofNo: no,
          ryProofFilepath: found?.ryProofFilepath ?? '',
        }
      });
    });
  }, [setUploadedFileList]);

  return (
    <div className="l-form mt15">
      <dl className="form-set">
        <dt className="form-name db" style={{ width: '135px' }}>
          <strong className="db fwb">報告数量の証憑となる資料の添付をお願いします。</strong>
          <span className="fwn fss">※複数資料添付可能</span>
        </dt>
        <dd className="form-body ml25 wdt1200">
          {
            isEditable ? (
              <DropZoneDisabled.Provider value={formLocked}>
                <UploadFile
                  maxCnt={5}
                  fileNoList={newFileListForUploader}
                  uploadedFileNoList={uploadedFileListForUploader}
                  onChangeFileNoList={onChangeFileNoList}
                  onChangeHasError={onChangeHasError}
                  onChangeHasProgress={onChangeHasProgress}
                  onChangeHasExceeded={onChangeHasExceeded}
                  onChangeUploaded={onChangeUploaded}
                  acceptableExt={acceptableExt}
                  maxFileSize={5 * 1024 * 1024}
                  maxFileSizeErrMsg='ファイルサイズは5MBまでです。' />
              </DropZoneDisabled.Provider>
            ) :
            (
              <UploadFileShowOnly fileList={uploadedFileListForUploader} />
            )
          }
          <p className="mt10">
            ファイル形式：PDF, Microsoft Word, Microsoft Excel, JPEG, PNG, GIF<br />
            ファイルサイズ：5MB以内<br />
            ファイル数：5つ以内
          </p>
        </dd>
      </dl>
    </div>
  );
}
