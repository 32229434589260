//@ts-check
import { Navigate, Link, useNavigate  } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthRedirect } from "../../../lib/hooks/licensee";
import { TableVisible } from "../../common/table/TableVisible";
import { PagingTable } from "../../common/table/PagingTable";
import { TableCellButton } from "../../common/table/TableCellButton";
import { proposal, statusList } from "../../common/headerList";
import { fetchAll, fetchAllProposals, selectApiStatus, clearApiStatus, clearFetchAll } from "../../../slices/licensee/proposalsSlice";
import { clearApiStatus as clearUsersApiStatus, fetchMyself, selectMyself } from '../../../slices/licensee/usersSlice';
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { ProposalListSearchForm, dateParams, defaultParams } from "./ProposalListSearchForm";
import { immutableSort } from "../../../lib/util";
import { Config } from '../../../config';
import { Constants } from '../../../Constants';
import { useSaveFormData } from '../../../lib/hooks/common';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil';

const ProposalStatusEmphasis = Constants.Licensee.ProposalStatusEmphasis;

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
proposal.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/** 企画状況ページ表示可能な企画ステータス */
const StatusVisibleProposalStatus = [
  Constants.Licensee.ProposalStatus.Approved,
  Constants.Licensee.ProposalStatus.Suspended,
  Constants.Licensee.ProposalStatus.FinishNotReported,
  Constants.Licensee.ProposalStatus.Finished,
];

/**
 * 企画書一覧
 * @returns
 */
export const ProposalListPage = router => {
  const authRedirect = useAuthRedirect();
  const navigate = useNavigate();

  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [headers, setHeaders] = useState(proposal);
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const hasMySelf = Boolean(myself);
  const username = myself?.username;
  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const proposalList = useSelector(fetchAllProposals);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'updateDate', order: 'desc' });

  // 検索パラメータ
  const [searchParams, setSearchParams] = useState(defaultParams);

  // 検索パラメータ保存関連
  const {
    isRestored,
    savedData,
  } = useSaveFormData({
    saveKey: Constants.Licensee.SearchFormSaveKey.ProposalList,
    dataType: searchParams,
    dateParams,
  });

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  // マウント時にログイン中ユーザー情報を取得
  useEffect(() => {
    dispatch(fetchMyself());
  }, [dispatch]);

  // 画面離脱時にAPI通信状況をクリアする
  useEffect(() => {
    return () => {
      dispatch(clearUsersApiStatus('fetch'));
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  /** 検索処理 */
  const onSearch = useCallback(
    /** @param {SearchFormData} params */
    params => {
      dispatch(fetchAll(emptyToUndefined({
      ...params,
      applicationDateFrom: formatDate(params.applicationDateFrom),
      applicationDateTo:   formatDate(params.applicationDateTo),
    })));
    },
  [dispatch]);

  // 画面突入時に保存済み検索パラメータを復元
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }
    setSearchParams(savedData);
    setParamRestored(true);
  }, [isRestored, paramRestored, savedData]);

  // 保存済みパラメータ復元後に検索
  useEffect(() => {
    if (initialFetched) {
      return;
    }
    if (!paramRestored) {
      return;
    }
    if (!hasMySelf) {
      return;
    }
    const params = {...searchParams};
    if (savedData == null) {
      // 保存済みパラメータが無い場合は申請者にログインユーザを設定
      params.applicantUserName = username ?? '';
    }
    setSearchParams(params);
    onSearch(params);
    setInitialFetched(true);
  }, [hasMySelf, initialFetched, onSearch, paramRestored, savedData, searchParams, username]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [proposalList, sortInfo]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const proposals = proposalList?.result?.data || [];
    return proposals.map(proposal => {
      return {
        propertyIcon: proposal.iconFilename,
        propertyName: proposal.propertySummaryName,
        proposalStatus: proposal.proposalStatus,
        applicantUserName: proposal.applicantUserName,
        proposalNo: proposal.proposalNo,
        proposalId: proposal.proposalId,
        proposalTitle: proposal.proposalTitle,
        applicationDate: proposal.applicationDatetime,
        startDate: proposal.startDate,
        endDate: proposal.endDate,
        updateDate: proposal.updateDatetime,
      };
    });
  }, [proposalList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'proposalStatus') {
      return (a, b) => statusList?.[a[sortKey]]?.index - statusList?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {

      // ステータスが強調表示の対象か?
      const statusEmphasis = ProposalStatusEmphasis.includes(record.proposalStatus);
      const statisText = statusList[record.proposalStatus]?.text;

      /** ステータスページ表示可能か */
      const statusPageVisible = StatusVisibleProposalStatus.includes(record.proposalStatus);

      return {
        ...record,
        id: record.proposalId,

        propertyIcon: record.propertyIcon ? (
          <span className="db transition-img work-logo">
            <img src={Config.PropertyIconPath + record.propertyIcon} alt="作品ロゴ" style={{width: '60px'}} />
          </span>
        ) : '',

        proposalStatus: statusEmphasis ? (
          <span className="attention">{statisText}</span>
        ) : statisText,

        //@ts-expect-error
        proposalNo: (<Link to={'../proposalDetail/' + record.proposalId}>{record.proposalNo}</Link>),
        applicationDate: String(record.applicationDate || '').substring(0, 10),
        updateDate: String(record.updateDate || '').substring(0, 10),

        statuses: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../proposalStatus/' + record.proposalId)} className="bg-blue">参照</TableCellButton>
        ) : '',

        labels: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../labelRequestList/' + record.proposalNo)} className="bg-blue">参照</TableCellButton>
        ) : '',

        royalties: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../royaltyReportList/' + record.proposalNo)} className="bg-blue">参照</TableCellButton>
        ) : '',
      };
    });
  }, [sortedRecords, navigate]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(proposal.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  if (authRedirect != null) {
    return (
      <Navigate to={authRedirect} />
    );
  }

  return (
    <div className="wrap">
      <div className="mt30">
        <p className="btn bg-green" style={{ width: '160px' }}>
          { /* @ts-expect-error */ }
          <Link to="../proposalDetail">
            <i className="icn plus"></i>
            新規企画書登録
          </Link>
        </p>
      </div>
      <hr />
      <ProposalListSearchForm
        params={searchParams}
        setParams={setSearchParams}
        onSearch={onSearch} />
      <TableVisible
        isOpen={isOpen}
        headers={proposal}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(proposalList) && (
        <PagingTable
          headers={headers}
          records={viewRecords}
          pageSize={pageSize}
          current={current}
          sortParam={sortInfo}
          emptyMessage="検索条件と一致する企画情報がありません。"
          scrollable
          resizable
          onSortChange={setSortInfo}
          onPageChange={setCurrent} />
      )}

      {loading}

    </div>
  );
};

//#region typedef
/**
 * @typedef {import('./ProposalListSearchForm').SearchFormData} SearchFormData
 */
//#endregion typedef
