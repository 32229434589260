import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Constants } from "../../../Constants";
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty, lengthRange, maxLength } from "../../../lib/validator";
import { clearPropertyDetail } from "../../../slices/aniplex/propertiesSlice";
import { clearApiStatus, clearProposalDetail, fetchAll, fetchAllProposals, fetchProposalDetail, selectApiStatus, selectProposalDetail } from "../../../slices/licensee/proposalsSlice";
import { clearRoyaltyDetail } from "../../../slices/licensee/royaltiesSlice";
import { pushMessage } from "../../../slices/licensee/utilSlice";
import { ApiLoadingOverlay } from "../../common/ApiLoadingOverlay";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { MessagePopup } from "../../common/MessagePopup";
import { RoyaltyProductsForm } from "./RoyaltyProductsForm";
import { validate as ryAmountValidate } from './RoyaltyProductsForm';
import { RoyaltyProofForm } from "./RoyaltyProofForm";
import { SearchProposalPopup } from '../shared/SearchProposalPopUp';
import { RoyaltyStatusForm } from "./ReportStatusForm";
import { useApplyConfirmPopup } from "./useApplyConfirmPopup";
import { useAutoFillEmptyRyAmount } from "./hooks";
import { NumberInput } from "../../common/NumberInput";
import { selectMyself } from "../../../slices/licensee/usersSlice";
import { sleep } from "../../../lib/util";
import { MessageHistoryArea } from "./MessageHistoryArea";

/** 編集可能なロイヤリティ報告ステータス */
const EDITABLE_STATUS = [
  // ステータスが一時保存、差し戻し中の場合は編集可能
  Constants.Licensee.reportStatus.TemporarySaved,
  Constants.Licensee.reportStatus.Rejected,
];

/**
 * RY報告可能な企画申請ステータス
 * @type {string[]}
 */
const REPORTABLE_PROPOSAL_STATUS = [
  Constants.Licensee.ProposalStatus.Approved,
  Constants.Licensee.ProposalStatus.FinishNotReported,
  Constants.Licensee.ProposalStatus.Finished,
];

/**
 * フォーム項目の順番
 * @type {(keyof FormData)[]}
 */
const formPropOrder = [
  'proposalId',
  'ryStartDate',
  'ryEndDate',
  'billingZipCode',
  'billingPhone',
  'billingAddress',
  'billingDepartment',
  'billingName',
];

/** 商品ごとのフォーム項目 */
const ryAmountProps = [
  // 生産数
  'reportProduction',
  // 販売数
  'reportSales',
  // 確定上代
  'reportPrice',
  // 確定製造原価
  'reportCost',
  // ロイヤリティ対象金額
  'reportProceeds',
  // ロイヤリティ金額
  'reportRyAmount',
];

/**
 * ロイヤルティ報告画面のフォーム部分
 * @param {object} props
 * @param {boolean} props.isNew 新規作成モードフラグ
 * @param {RoyaltyDetail} props.loadedData 編集モード時にAPIから取得されたデータ
 * @param {string|null} props.proposalId パラメータで渡された企画内部コード
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {(royalty: PostRoyaltiesParam) => void} props.onSubmit 申請・一時保存ボタン押下時のコールバック
 * @param {(royalty: RoyaltyDetail) => void} props.onDelete 削除ボタン押下時のコールバック
 */
export const RoyaltyReportDetailForm = ({
  isNew,
  loadedData,
  proposalId,
  formLocked,
  onSubmit,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const proposalList = useSelector(fetchAllProposals)?.result?.data;
  const proposalDetail = useSelector(selectProposalDetail);
  const apiStatus = useSelector(selectApiStatus);
  const myself = useSelector(selectMyself);

  // 削除確認ポップアップ関連
  const [deleteConfirm, setDeleteConfirm] = useState({
    showFlg: false,
    onClose: null,
  });

  // 入力データ
  const [formData, setFormData] = useState(() => getInitialFormData());
  // ロイヤリティ金額情報
  const [ryAmountList, setRyAmountList] = useState(/** @type {RyAmount[]} */ ([]));
  // ロイヤリティ金額エラー
  const [ryAmountErrors, setRyAmountErrors] = useState({});
  // 強制バリデートフラグ
  const [forceValidateFlg, setForceValidateFlg] = useState(false);
  // 以前のisNewの値
  const [prevIsNew, setPrevIsNew] = useState(isNew);
  // 新規追加された確証ファイルリスト
  const [newRyProofList, setNewRyProofList] = useState([]);
  // アップロード済み確証ファイルリスト
  const [uploadedRyProofList, setUploadedRyProofList] = useState(/** @type {RyProof[]} */ ([]));
  // ファイルアップロードエラー
  const [hasUploadError, setHasUploadError] = useState(false);
  // ファイルアップロード進行中フラグ
  const [hasUploadProgress, setHasUploadProgress] = useState(false);
  // ファイル数超過エラー
  const [hasUploadExceeded, setHasUploadExceeded] = useState(false);
  // 編集が必要なロイヤリティ報告の情報
  const [needEditInfo, setNeedEditInfo] = useState({
    /** 警告メッセージポップアップ表示フラグ */
    showPopup: false,
    /** 編集が必要なRY報告のID */
    targetId: null,
    /** 移動前に参照していたRY報告のID */
    prevId: null,
  });
  // 編集が必要なロイヤリティ報告にリダイレクトしたときのフラグ
  const [redirectNeedEditFlg, setRedirectNeedEditFlg] = useState(false);

  /** 受領済みフラグ */
  const reportAccepted = useIsAcceptedReport(loadedData);

  // 編集可能フラグ
  const isEditable = useMemo(() => {
    return isNew || checkEditable(loadedData);
  }, [isNew, loadedData]);

  // フォーム非活性フラグ
  const formDisabled = useMemo(() => {
    return !isEditable || formLocked;
  }, [formLocked, isEditable]);

  // 削除可能フラグ
  const canDelete = useMemo(() => {
    return (loadedData?.revision ?? 0) === 0;
  }, [loadedData?.revision]);

  /** 報告期間自由入力フラグ */
  const canRyDateFreeInput = useMemo(() =>
    checkRyDateFreeInput(proposalDetail)
  , [proposalDetail]);

  /** 報告期間編集可能フラグ */
  const ryDateEditable = useMemo(() =>
    isEditable && canRyDateFreeInput
  , [canRyDateFreeInput, isEditable]);

  /** 報告開始日最小値 */
  const ryStartMinDate = useMemo(() => {
    if (proposalDetail?.contractStartDate == null) {
      return undefined;
    }
    return dayjs(proposalDetail.contractStartDate, 'YYYY/MM/DD').toDate();
  }, [proposalDetail?.contractStartDate]);

  /** 報告終了日最大値 */
  const ryEndMaxDate = useMemo(() => {
    if (proposalDetail?.contractEndDate == null) {
      return undefined;
    }
    return dayjs(proposalDetail.contractEndDate, 'YYYY/MM/DD').toDate();
  }, [proposalDetail?.contractEndDate]);

  /** 報告開始日 */
  const ryStartDate = useMemo(() => {
    if (isEmpty(formData.ryStartDate)) {
      return null;
    }
    return dayjs(formData.ryStartDate, 'YYYY/MM/DD').toDate();
  }, [formData.ryStartDate]);

  /** 報告終了日 */
  const ryEndDate = useMemo(() => {
    if (isEmpty(formData.ryEndDate)) {
      return null;
    }
    return dayjs(formData.ryEndDate, 'YYYY/MM/DD').toDate();
  }, [formData.ryEndDate]);

  // フォームエラー
  const [errors, setErrors] = useState(/** @type {ErrorMessages} */ ({
    proposalId: {},
    period: {},
  }));

  /** 選択された期 */
  const selectedPeriod = useMemo(() => {
    return proposalDetail?.periodList?.find(p => p.period === formData.period);
  }, [formData.period, proposalDetail?.periodList]);

  /** 設定された報告期間開始日 */
  const selectedRyStartDate = useMemo(() => {
    if (canRyDateFreeInput) {
      return loadedData?.ryStartDate;
    }
    return selectedPeriod?.ryStartDate;
  }, [canRyDateFreeInput, loadedData?.ryStartDate, selectedPeriod?.ryStartDate]);

  /** 設定された報告期間終了日 */
  const selectedRyEndDate = useMemo(() => {
    if (canRyDateFreeInput) {
      return loadedData?.ryEndDate;
    }
    return selectedPeriod?.ryEndDate;
  }, [canRyDateFreeInput, loadedData?.ryEndDate, selectedPeriod?.ryEndDate]);

  /** 空欄自動入力処理 */
  const {
    autoFillEmptyRyAmount,
    autoFillAlertPopup,
    autoFillDirtyFlg,
  } = useAutoFillEmptyRyAmount({ proposalDetail, setRyAmountList, targetPeriod: selectedPeriod?.period })

  // エラー項目にフォーカスする設定
  const formProps = useMemo(() => {
    return [
      ...formPropOrder,
      {
        name: 'ryAmountList',
        items: ryAmountList.map((r) => ({ name: r.productId, items: [...ryAmountProps] }))
      },
    ];
  }, [ryAmountList]);
  const [formRefs, focusError] = useFocusError(formProps);
  /**
   * フォーカスするエラーの種類
   */
  const [focusErrorType, setFocusErrorType] = useState(/** @type {'apply'|'tmp'|null} */ (null));

  // 強制バリデートアクション実行フラグ
  const [forceValidateActionFlg, setForceValidateActionFlg] = useState(false);

  /** 強制バリデートアクション実行コールバック */
  const onForceValidateAction = useCallback(() => {
    setForceValidateActionFlg(false);
  }, []);

  useEffect(() => {
    if (focusErrorType == null) {
      return;
    }

    for (const prop of formPropOrder) {
      if (errors[prop]?.[focusErrorType]?.length > 0) {
        if (focusError(prop)) {
          setFocusErrorType(null);
          return;
        }
      }
    }

    for (const r of ryAmountList) {
      const err = ryAmountErrors[r.productId];
      for (const prop of ryAmountProps) {
        if (err?.[prop]?.[focusErrorType]?.length > 0) {
          if (focusError('ryAmountList', r.productId, prop)) {
            setFocusErrorType(null);
            return;
          }
        }
      }
    }
  }, [errors, focusError, focusErrorType, ryAmountErrors, ryAmountList]);

  // エラーメッセージ
  const messages = useMemo(() => {
    return Object.fromEntries(
      Object.entries(errors).map(([k, v]) => ([k, v.apply ?? []]))
    );
  }, [errors]);

  // 報告対象の商品リスト
  const targetProductList = useMemo(() => {
    if (proposalDetail == null) {
      return [];
    }

    // 編集可能時は全商品が対象
    if (isEditable) {
      return proposalDetail.productList;
    }

    // 参照時は申請された商品のみが対象
    if (loadedData == null) {
      return [];
    }
    return proposalDetail.productList.filter(p => !!loadedData.ryAmountList.find(r => r.productId === p.productId));
  }, [isEditable, loadedData, proposalDetail]);

  useEffect(() => {
    // URL変更で編集モードから新規モードに変更された場合
    // フォーム内容をすべてリセットする
    if (prevIsNew !== isNew) {
      if (isNew) {
        setFormData(getInitialFormData());
        setRyAmountList([])
        setNewRyProofList([]);
        setUploadedRyProofList([]);
      }
      setPrevIsNew(isNew);
    }
  }, [isNew, prevIsNew]);

  /**
   * 値変更時のハンドラ
   * @type {(prop: keyof formData, value: *) => void}
   */
  const handleChange = useCallback((prop, value) => {
    const errors = validate(prop, value);
    setErrors(prev => ({
      ...prev,
      [prop]: errors,
    }));
    setFormData(prev => ({
      ...prev,
      [prop]: value,
    }))
  }, []);

  /**
   * 日付型の値変更時のハンドラ
   */
  const handleDateChange = useCallback(
    /**
     * @param {keyof FormData} prop 対象のプロパティ名
     * @param {Date|null} dateOrNull 値
     */
    (prop, dateOrNull) => {
      const value = isEmpty(dateOrNull) ? '' : dayjs(dateOrNull).format('YYYY/MM/DD');
      const errors = validate(prop, value, { formData, proposalDetail });
      setErrors(prev => ({
        ...prev,
        [prop]: errors,
      }));
      setFormData(prev => ({
        ...prev,
        [prop]: value,
      }));
      if (prop === 'ryStartDate') {
        // 終了日も相関チェックする
        const endErrors = validate('ryEndDate', formData.ryEndDate, {
          formData: {
            ...formData,
            ryStartDate: value,
          },
          proposalDetail,
        });
        setErrors(prev => ({
          ...prev,
          'ryEndDate': endErrors,
        }));
      }
    }
  , [formData, proposalDetail]);

  useEffect(() => {
    if (isNew) {
      // 新規作成時は請求先をデフォルト設定
      setFormData(prev => ({
        ...prev,
        billingZipCode: myself?.zipCode ?? '',
        billingPhone: myself?.phone ?? '',
        billingAddress: myself?.address ?? '',
        billingDepartment: myself?.department ?? '',
        billingName: myself?.username ?? '',
      }));
    }
  }, [isNew, myself?.address, myself?.department, myself?.phone, myself?.username, myself?.zipCode]);

  useEffect(() => {
    // 更新モードかつデータが読み込まれた場合はそれをフォームデータに反映
    if (!isNew && loadedData != null) {
      setFormData(prevData => ({
        ...prevData,
        ...loadedData,
      }));
      setRyAmountList([...(loadedData?.ryAmountList ?? [])]);
      setUploadedRyProofList([...(loadedData?.ryProofList ?? [])]);
    }
  }, [isNew, loadedData]);

  useEffect(() => {
    if (forceValidateFlg) {
      // 強制バリデートフラグが立っているときは全項目をバリデートする
      const newErrors = {};
      Object.entries(formData).forEach(([prop, value]) => {
        const errors = validate(/** @type {keyof typeof formData} */ (prop), value, { formData, proposalDetail });
        newErrors[prop] = errors;
      });
      setErrors(prev => ({
        ...prev,
        ...newErrors,
      }));
    }
  }, [forceValidateFlg, formData, proposalDetail]);

  /**
   * ロイヤリティ金額のバリデート実行イベントのハンドラ
   * @type {import('./RoyaltyProductsForm').OnValidated}
   */
  const onRyAmountValidated = useCallback((productId, prop, errors) => {
    setRyAmountErrors(prev => {
      const org = prev[productId] ?? {};
      return {
        ...prev,
        [productId]: {
          ...org,
          [prop]: errors,
        },
      };
    });
  }, []);

  /** 一時保存向けバリデートエラーフラグ */
  const hasTmpErrors = useMemo(() => {
    const hasFormError = Object.values(errors)
      .map(e => e.tmp ?? [])
      .flat()
      .length > 0
    const hasRyAmountError = Object.values(ryAmountErrors)
      .map(e => Object.values(e))
      .flat()
      .map(e => e.tmp ?? [])
      .flat()
      .length > 0;

    return hasFormError || hasRyAmountError;
  }, [errors, ryAmountErrors]);

  /**
   * ロイヤリティ金額情報変更時のハンドラ
   * @param {number} productId 商品内部コード
   * @param {keyof RyAmount} prop 変更されたプロパティ名
   * @param {*} value 変更後の値
   */
  const handleRyAmountChange = useCallback((productId, prop, value) => {
    setRyAmountList(prev => {
      return prev.map(r => {
        if (r.productId === productId) {
          return {
            ...r,
            [prop]: value,
          }
        }
        return { ...r };
      });
    })
  }, []);

  // 画面突入時に企画一覧を取得
  useEffect(() => {
    dispatch(fetchAll({
      proposalStatus: Constants.Licensee.ProposalStatus.ApprovedAndFinishNotReported,
    }));
    return () => {
      // 画面離脱時に各APIの状態をクリア
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(clearPropertyDetail());
      dispatch(clearProposalDetail());
    }
  }, [dispatch]);

  // 企画書一覧を取得出来たら企画のデフォルト選択のチェック
  useEffect(() => {
    // 新規モードの場合のみデフォルト選択を行う
    if (isNew && apiStatus.fetchAll === 'finish') {
      const found = proposalList?.find(p => p.proposalId === Number(proposalId));
      if (found) {
        // パラメータで渡された企画内部コードと同じものが見つかったらそれを初期選択
        handleChange('proposalId', Number(proposalId));
      }
      dispatch(clearApiStatus('fetchAll'));
    }
  }, [apiStatus.fetchAll, dispatch, handleChange, isNew, proposalId, proposalList]);

  // 編集モード時に対象の企画書がRY報告可能な状態でなければ一覧画面へ戻す
  useEffect(() => {
    if (isEditable && apiStatus.fetchProposalDetail === 'finish' && proposalDetail != null) {
      if (!REPORTABLE_PROPOSAL_STATUS.includes(proposalDetail.proposalStatus)) {
        dispatch(pushMessage('企画が更新中か中止されたため、このロイヤリティ報告は行えません。'));
        navigate('/licensee/royaltyReportList');
      }
      dispatch(clearApiStatus('fetchAll'));
    }
  }, [apiStatus.fetchProposalDetail, dispatch, isEditable, navigate, proposalDetail]);

  // 企画書一覧の取得エラー
  useEffect(() => {
    if (apiStatus.fetchAll === 'error') {
      dispatch(pushMessage('システムエラーが発生しました。'));
      dispatch(clearApiStatus('fetchAll'));
    }
  }, [apiStatus.fetchAll, dispatch]);

  // 企画書が選択されたら企画詳細を取得
  useEffect(() => {
    if (formData.proposalId) {
      dispatch(clearApiStatus('fetchProposalDetail'));
      dispatch(fetchProposalDetail(formData.proposalId));
    } else {
      dispatch(clearProposalDetail());
    }
  }, [dispatch, formData.proposalId]);

  /** 報告日 */
  const reportDate = useMemo(() => {
    if (isEmpty(formData.reportDatetime)) {
      return '';
    }
    return dayjs(formData.reportDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD');
  }, [formData.reportDatetime]);

  /** ステータス */
  const reportStatus = useMemo(() => {
    return Constants.Licensee.reportStatusName[formData.reportStatus] ?? '';
  }, [formData.reportStatus]);

  /** N期のリスト */
  const periodList = useMemo(() => {
    // ロイヤリティ未報告か対象のロイヤリティ報告のもののみに絞る
    return proposalDetail?.periodList?.filter(pr => {
      if (pr.ryReportId == null) {
        // RY報告が登録されていない期
        return true;
      }
      if (!isNew && loadedData?.period === pr.period) {
        return true
      }
      return false;
    }) ?? [];
  }, [isNew, loadedData?.period, proposalDetail?.periodList]);

  /** 未申請のロイヤリティ報告がないかチェック */
  useEffect(() => {
    if (!isEditable) {
      // 編集不可のデータの場合は処理しない
      return;
    }
    if (!isNew && !loadedData) {
      // 編集時はデータが読み込まれるまで処理しない
      return;
    }
    if (loadedData != null && loadedData?.ryReportId === needEditInfo.prevId) {
      // データ切り替え時に意図しないチェック処理が走ることの防止
      return;
    }

    for (let pr of proposalDetail?.periodList ?? []) {
      if (pr.period === loadedData?.period) {
        // 対象のロイヤリティ報告より先の期は判定対象にしない
        // ※古いデータで未申請の報告が複数存在する場合に無限ループすることの対策
        break;
      }
      if (EDITABLE_STATUS.includes(pr.reportStatus)) {
        setNeedEditInfo({
          showPopup: true,
          targetId: pr.ryReportId,
          prevId: loadedData?.ryReportId ?? null,
        });
        break;
      }
    }
  }, [isEditable, isNew, loadedData, needEditInfo.prevId, proposalDetail?.periodList]);

  /** 未申請のロイヤリティ報告の警告クリック時のハンドラ */
  const onNeedEditAlertClick = useCallback(() => {
    setNeedEditInfo(prev => ({
      ...prev,
      showPopup: false,
    }));
    setRedirectNeedEditFlg(true);
    // 取得済みのデータを一旦クリア
    dispatch(clearRoyaltyDetail());
    navigate(`/licensee/royaltyReportDetail/${needEditInfo.targetId}`);
  }, [dispatch, navigate, needEditInfo.targetId]);

  useEffect(() => {
    if (apiStatus.fetchProposalDetail === 'finish') {
      if (proposalDetail != null) {
        // 企画詳細取得完了時
        // 新規モードの場合は各種初期値を設定
        if (isNew) {
          // 最も古い期をデフォルト選択する
          const oldestPeriod = getOldestPeriod(periodList);
          handleChange('period', oldestPeriod?.period ?? null);
          // ロイヤリティ金額情報の初期値を設定
          setRyAmountList(convertProductToRyAmount(targetProductList));
          // APIステータスをクリア
          dispatch(clearApiStatus('fetchProposalDetail'));
          return;
        }
        // 企画が変更されたら対象の期を再計算
        if (loadedData != null && loadedData?.proposalId !== proposalDetail?.proposalId) {
          const oldestPeriod = getOldestPeriod(periodList);
          handleChange('period', oldestPeriod?.period ?? null);
          // APIステータスをクリア
          dispatch(clearApiStatus('fetchProposalDetail'));
          return;
        }
      }
    }
    if (apiStatus.fetchProposalDetail === 'error') {
      dispatch(pushMessage('システムエラーが発生しました。'));
      dispatch(clearApiStatus('fetchProposalDetail'));
      return;
    }
  }, [apiStatus.fetchProposalDetail, dispatch, handleChange, isNew, loadedData, periodList, proposalDetail, proposalDetail?.proposalId, targetProductList]);

  useEffect(() => {
    if (isNew) {
      return;
    }

    if ((apiStatus.fetchProposalDetail === 'finish' || redirectNeedEditFlg) && loadedData != null) {
      // 編集・参照モード時は既存の金額情報と追加された商品の金額情報の初期値をマージして設定
      const currentRyAmount = loadedData.ryAmountList;
      const ryAmountInit = convertProductToRyAmount(targetProductList);
      const ryAmountList = targetProductList.map(product => {
        const found = currentRyAmount.find(r => r.productId === product.productId);
        if (found) {
          // 既存の金額情報がある場合はそれを返す
          return found;
        }

        // 既存のデータが無い場合は初期値を返す
        return ryAmountInit.find(r => r.productId === product.productId);
      });
      setRyAmountList(ryAmountList);

      setRedirectNeedEditFlg(false);
      // APIステータスをクリア
      dispatch(clearApiStatus('fetchProposalDetail'));
      return;
    }
  }, [apiStatus.fetchProposalDetail, dispatch, isNew, loadedData, redirectNeedEditFlg, targetProductList]);

  /** 申請確認ポップアップでOKが押されたときの処理 */
  const onApplyConfirmed = useCallback(
    /**
     * @param {string} messageContent 申請コメント
     */
    (messageContent) => {
      /** @type {PostRoyaltiesParam} */
      const params = {
        ...formData,
        ryAmountList,
        ryProofList: [
          ...uploadedRyProofList,
          ...newRyProofList,
        ],
        reportStatus: Constants.Licensee.reportStatus.Requesting,
        messageContent,
      };

      onSubmit(params)
    }, [formData, newRyProofList, onSubmit, ryAmountList, uploadedRyProofList]);

  // 申請確認ポップアップ
  const {
    popup: applyConfirmPopup,
    showPopup: showApplyConfirmPopup,
  } = useApplyConfirmPopup({
    onOk: onApplyConfirmed,
    targetPeriod: formData.period,
    productList: proposalDetail?.productList,
    ryAmountList,
  });

  /** 申請ボタン押下時のハンドラ */
  const onApplyClick = useCallback(async () => {
    setForceValidateFlg(true);

    // バリデート結果が反映されていないことがあるので次のティックに回す
    await sleep(0);
    setForceValidateActionFlg(true);

    const errors = validateAll(formData, ryAmountList, proposalDetail?.productList ?? [], proposalDetail);
    if (errors.apply.length > 0) {
      setFocusErrorType('apply');
      // バリデートエラーがあるときはAPIは呼ばない
      return;
    }

    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    // ポップアップ表示
    showApplyConfirmPopup();
  }, [formData, formLocked, proposalDetail, ryAmountList, showApplyConfirmPopup]);

  /** 一時保存ボタン押下時のハンドラ */
  const onSaveClick = useCallback(async () => {
    setForceValidateFlg(true);

    // バリデート結果が反映されていないことがあるので次のティックに回す
    await sleep(0);
    setForceValidateActionFlg(true);

    const errors = validateAll(formData, ryAmountList, proposalDetail?.productList ?? [], proposalDetail);
    if (errors.tmp.length > 0) {
      setFocusErrorType('tmp');
      // バリデートエラーがあるときはAPIは呼ばない
      return;
    }

    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    /** @type {PostRoyaltiesParam} */
    const params = {
      ...formData,
      ryAmountList,
      ryProofList: [
        ...uploadedRyProofList,
        ...newRyProofList,
      ],
      reportStatus: Constants.Licensee.reportStatus.TemporarySaved,
    }

    onSubmit(params);
  }, [formData, formLocked, newRyProofList, onSubmit, proposalDetail, ryAmountList, uploadedRyProofList]);

  /** 削除ボタン押下時のハンドラ */
  const onDeleteClick = useCallback(() => {
    if (formLocked) {
      // 入力抑制中は処理中断
      return;
    }

    setDeleteConfirm({
      showFlg: true,
      onClose: (btn) => {
        if (btn === 'ok') {
          onDelete(formData);
        }
        setDeleteConfirm({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [formData, formLocked, onDelete]);

  /**
   * 企画書設定ポップアップ
   */
  const [searchProposalPopup, setSearchProposalPopup] = useState({
    showFlg: false,
    onClose: null,
  });

  /**
   * 企画書検索フォーム表示
   */
  const onSearchProposalClick = useCallback(() => {
    setSearchProposalPopup({
      showFlg: true,
      onClose: (btn, code) => {
        if (btn === 'ok') {
          handleChange('proposalId', code)
        }
        setSearchProposalPopup({
          showFlg: false,
          onClose: null
        })
      }
    })
  }, [handleChange]);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">ロイヤリティ報告No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text"
                name="ロイヤリティ報告No"
                title="ロイヤリティ報告Noは入力済みです"
                aria-label="ロイヤリティ報告No"
                disabled
                value={formData.ryReportNo ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告日</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text"
                name="報告日"
                disabled
                value={reportDate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告者</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text"
                name="報告者"
                disabled
                value={formData.reportUserName ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">ロイヤリティ報告ステータス</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text"
                name="ロイヤリティ報告ステータス"
                title="ロイヤリティ報告ステータスは入力済みです"
                aria-label="ロイヤリティ報告ステータス"
                disabled
                value={reportStatus} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required" style={{ width: '110px' }}>企画書</dt>
          <dd className="form-body">
            {
              isEditable ?
              <>
                <div className="input-form wdt1000" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input type="text"
                    title="企画書を必ず選択してください"
                    aria-label="企画書"
                    disabled
                    value={`${proposalDetail?.proposalNo ?? ''} ${proposalDetail?.proposalTitle ?? ''}`}
                  />
                  {
                    isNew && (
                      <p className="btn label c-pink wdt100 ml20" style={{ width: '80px' }}>
                        <button
                          ref={formRefs.current.proposalId}
                          disabled={formDisabled}
                          onClick={onSearchProposalClick}
                        >設定</button>
                      </p>
                    )
                  }
                </div>
                {
                  searchProposalPopup.showFlg &&
                    <SearchProposalPopup
                      selectProposalId={formData.proposalId}
                      onClose={searchProposalPopup.onClose}
                      targetProposalStatus={Constants.Licensee.ProposalStatus.ApprovedAndFinishNotReported} />
                }
              </> :
              <div className="input-form wdt1000">
                <input type="text"
                  disabled={true}
                  value={`${proposalDetail?.proposalNo ?? ''} ${proposalDetail?.proposalTitle ?? ''}`}/>
              </div>
            }
            <ErrorMessageList messages={messages.proposalId} />
          </dd>
        </dl>
      </div>

      {
        proposalDetail ? (
          <>
            {
              canRyDateFreeInput && (
                <p className="attention">ロイヤリティ報告の際は、対象期間の入力をお願い致します。</p>
              )
            }
            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name required">報告対象期間</dt>
                <dd className="form-body">
                  <div style={{ display: 'flex', alignItems: 'start' }}>
                    <div className="form-select wdt140">
                      <select name="報告対象期間"
                        disabled
                        value={formData.period ?? ''}
                        onChange={ev => handleChange('period', Number(ev.target.value))}
                      >
                        {
                          periodList.map(pr => (
                            <option key={pr.period} value={pr.period}>第{pr.period}期</option>
                          ))
                        }
                      </select>
                    </div>
                    <div>
                      <div className="input-form wdt140 ml10 mr10">
                        {
                          ryDateEditable ? (
                            <DatePicker
                              dateFormat='yyyy/MM/dd'
                              locale='ja'
                              ref={formRefs.current.ryStartDate}
                              minDate={ryStartMinDate}
                              selected={ryStartDate}
                              onChange={date => handleDateChange('ryStartDate', date)} />
                          ) : (
                            <input type="text"
                              name="報告対象期間開始"
                              disabled
                              value={selectedRyStartDate ?? ''} />
                          )
                        }
                      </div>
                      <ErrorMessageList messages={messages.ryStartDate} />
                    </div>
                    <p style={{ marginTop: '7px' }}>～</p>
                    <div>
                      <div className="input-form wdt140 ml10">
                        {
                          ryDateEditable ? (
                            <DatePicker
                              dateFormat='yyyy/MM/dd'
                              locale='ja'
                              ref={formRefs.current.ryEndDate}
                              maxDate={ryEndMaxDate}
                              selected={ryEndDate}
                              onChange={date => handleDateChange('ryEndDate', date)} />
                          ) : (
                            <input type="text"
                              name="報告対象期間終了"
                              disabled
                              value={selectedRyEndDate ?? ''} />
                          )
                        }
                      </div>
                      <ErrorMessageList messages={messages.ryEndDate} />
                    </div>
                  </div>
                  <ErrorMessageList messages={messages.period} />
                </dd>
              </dl>
            </div>

            <div className="l-form">
              <dl className="form-set">
                <dt className="form-name" style={{ width: '110px' }}>請求先</dt>
                <dd className="form-body">
                  <span className="attention" style={{ display: 'inline-block', paddingTop: '7px' }}>請求先に変更がある場合は、記載の変更をお願いします。</span>
                  <dl className="form-set" style={{ marginBottom: '20px' }}>
                    <dt className="form-name required" style={{ width: '90px' }}>郵便番号</dt>
                    <dd className="form-body">
                      <div className="input-form wdt250">
                        <NumberInput
                          type="text"
                          name="billingZipCode"
                          aria-label="請求先郵便番号"
                          inputRef={formRefs.current.billingZipCode}
                          value={formData.billingZipCode}
                          onChange={val => handleChange('billingZipCode', val)}
                          disabled={!isEditable} />
                        <ErrorMessageList messages={messages.billingZipCode} />
                      </div>
                    </dd>
                    <dt className="form-name required" style={{ marginLeft: '40px' }}>電話番号</dt>
                    <dd className="form-body">
                      <div className="input-form wdt500">
                        <NumberInput
                          type="text"
                          name="billingPhone"
                          aria-label="請求先電話番号"
                          inputRef={formRefs.current.billingPhone}
                          value={formData.billingPhone}
                          onChange={val => handleChange('billingPhone', val)}
                          disabled={!isEditable} />
                        <ErrorMessageList messages={messages.billingPhone} />
                      </div>
                    </dd>
                  </dl>
                  <dl className="form-set" style={{ marginBottom: '20px' }}>
                    <dt className="form-name required" style={{ width: '90px' }}>住所</dt>
                    <dd className="form-body">
                      <div className="input-form wdt1000">
                        <input
                          type="text"
                          name="billingAddress"
                          aria-label="請求先住所"
                          ref={formRefs.current.billingAddress}
                          value={formData.billingAddress}
                          onChange={ev => handleChange('billingAddress', ev.target.value)}
                          disabled={!isEditable} />
                        <ErrorMessageList messages={messages.billingAddress} />
                      </div>
                    </dd>
                  </dl>
                  <dl className="form-set" style={{ marginBottom: '25px' }}>
                    <dt className="form-name" style={{ width: '90px' }}>部署名</dt>
                    <dd className="form-body">
                      <div className="input-form wdt1000">
                        <input
                          type="text"
                          name="billingDepartment"
                          aria-label="請求先部署名"
                          ref={formRefs.current.billingDepartment}
                          value={formData.billingDepartment}
                          onChange={ev => handleChange('billingDepartment', ev.target.value)}
                          disabled={!isEditable} />
                        <ErrorMessageList messages={messages.billingDepartment} />
                      </div>
                    </dd>
                  </dl>
                  <dl className="form-set" style={{ marginBottom: '25px' }}>
                    <dt className="form-name" style={{ width: '90px' }}>担当者名</dt>
                    <dd className="form-body">
                      <div className="input-form wdt1000">
                        <input
                          type="text"
                          name="billingName"
                          aria-label="請求先担当者名"
                          ref={formRefs.current.billingName}
                          value={formData.billingName}
                          onChange={ev => handleChange('billingName', ev.target.value)}
                          disabled={!isEditable} />
                        <ErrorMessageList messages={messages.billingName} />
                      </div>
                    </dd>
                  </dl>
                </dd>
              </dl>
            </div>

            {
              formData?.rejectMessage && (
                <div className="l-form">
                  <dl className="form-set">
                    <dt className="form-name attention" style={{ width: '115px' }}>差し戻し理由</dt>
                    <dd className="form-body">
                      <div className="form-textarea wdt1200">
                        <textarea rows="4"
                          name="差し戻し理由"
                          aria-label="差し戻し理由"
                          disabled
                          value={formData?.rejectMessage} />
                      </div>
                    </dd>
                  </dl>
                </div>
              )
            }

            <MessageHistoryArea royaltyDetail={loadedData} />

            <RoyaltyProductsForm
              proposal={proposalDetail}
              targetPeriod={formData.period}
              ryAmountList={ryAmountList}
              onRyAmountChange={handleRyAmountChange}
              onValidated={onRyAmountValidated}
              validateAllFlg={forceValidateFlg}
              forceValidateActionFlg={forceValidateActionFlg}
              onForceValidateAction={onForceValidateAction}
              formLocked={formDisabled}
              formRefs={formRefs.current.ryAmountList}
              ryAmountDirtyFlg={autoFillDirtyFlg}
              tableTopElement={
                isEditable && (
                  <div className="l-buttons mt10">
                    <p className="btn c-pink">
                      <button
                        style={{ width: '150px' }}
                        onClick={autoFillEmptyRyAmount}
                      >空欄自動入力</button>
                    </p>
                  </div>
              )} />

            <RoyaltyProofForm
              newFileList={newRyProofList}
              setNewFileList={setNewRyProofList}
              uploadedFileList={uploadedRyProofList}
              setUploadedFileList={setUploadedRyProofList}
              onChangeHasError={setHasUploadError}
              onChangeHasProgress={setHasUploadProgress}
              onChangeHasExceeded={setHasUploadExceeded}
              formLocked={formLocked}
              isEditable={isEditable} />

            {
              reportAccepted && (
                <RoyaltyStatusForm
                  royalty={loadedData}
                  proposalDetail={proposalDetail} />
              )
            }

            {
              (hasUploadError || hasUploadProgress || hasUploadExceeded) ? (
                <ul className="error_list mt30">
                  {hasUploadError && (
                    <li className="error_list-item">証憑にエラーがあります。</li>
                  )}
                  {hasUploadProgress && (
                    <li className="error_list-item">アップロード中の証憑があります。</li>
                  )}
                  {hasUploadExceeded && (
                    <li className="error_list-item">証憑は5点まで登録できます。</li>
                  )}
                </ul>
              ) : <div className="mt30"></div>
            }

            {
              isEditable && (
                <div className="dif j-between">
                  <div className="l-buttons">
                    <p className="btn bg-green" style={{ width: '260px' }}>
                      <button
                        disabled={hasUploadError || hasUploadProgress || hasUploadExceeded || formLocked}
                        onClick={onApplyClick}
                      ><i className="icn check"></i>ロイヤリティ報告を申請する</button>
                    </p>
                    <p className="btn c-blue ml10" style={{ width: '160px' }}>
                      <button
                        disabled={hasTmpErrors || hasUploadError || hasUploadProgress || hasUploadExceeded || formLocked}
                        onClick={onSaveClick}
                      ><i className="icn save"></i>一時保存</button>
                    </p>
                  </div>
                  {
                    canDelete && (
                      <p className="btn c-pink" style={{ width: '89px' }}>
                        <button
                          disabled={formLocked}
                          onClick={onDeleteClick}
                        ><i className="icn cross"></i>削除</button>
                      </p>
                    )
                  }
                </div>
              )
            }
          </>
        ) : null
      }

      {autoFillAlertPopup}

      {applyConfirmPopup}

      {
        deleteConfirm.showFlg && (
          <MessagePopup
            message="ロイヤリティ報告を削除します。よろしいですか？"
            btn={{ ok: '削除', cancel: 'キャンセル' }}
            btnClass='bg-pink'
            onClose={deleteConfirm.onClose} />
        )
      }

      {
        needEditInfo.showPopup && (
          <MessagePopup
            message="対象の企画に受領されていないロイヤリティ報告申請が存在します。修正して申請してください。"
            btn={{ ok: 'OK' }}
            btnClass='bg-pink'
            onClose={onNeedEditAlertClick}/>
        )
      }

      <ApiLoadingOverlay
        apiStatus={[
          apiStatus.fetchProposalDetail,
        ]} />
    </>
  )
}

/**
 * ロイヤリティ報告受領済みフラグ
 * @param {?RoyaltyDetail} royalty ロイヤリティ報告詳細
 */
const useIsAcceptedReport = (royalty) => {
  /** @type {valueOf<Constants['Licensee']['reportStatus']>[]} */
  const statuses = [
    Constants.Licensee.reportStatus.Approved,
    Constants.Licensee.reportStatus.Exported,
  ];
  return royalty?.reportStatus != null && statuses.includes(royalty.reportStatus);
}

/**
 * 報告期間が自由入力可能かを判定する
 * @param {ProposalDetail|null} proposalDetail 企画詳細情報
 */
function checkRyDateFreeInput(proposalDetail) {
  return proposalDetail?.ryReportCategory === Constants.Licensee.RyReportCategory.Any
}

/**
 * フォームの全内容をバリデートする
 * @param {FormData} formData フォームの入力データ
 * @param {RyAmount[]} ryAmountList ロイヤリティ金額情報
 * @param {Product[]} productList 商品情報
 * @param {ProposalDetail|null} proposalDetail 企画詳細
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateAll(formData, ryAmountList, productList, proposalDetail) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  Object.entries(formData).forEach(([prop, value]) => {
    const error = validate(prop, value, { formData, proposalDetail });
    errors.tmp = [
      ...errors.tmp,
      ...error.tmp,
    ];
    errors.apply = [
      ...errors.apply,
      ...error.apply,
    ];
  });

  ryAmountList.forEach(r => {
    const product = productList.find(p => p.productId === r.productId);
    if (product) {
      Object.entries(r).forEach(([prop, value]) => {
        const error = ryAmountValidate(product.rypId, prop, value);
        errors.tmp = [
          ...errors.tmp,
          ...error.tmp,
        ];
        errors.apply = [
          ...errors.apply,
          ...error.apply,
        ];
      });
    }
  });

  return errors;
}

/**
 * @typedef {object} ValidateOptions バリデート処理のオプション
 * @property {FormData} [formData] フォーム全体のデータ
 * @property {ProposalDetail|null} [proposalDetail] 企画詳細
 */
/**
 * バリデート処理
 * @param {keyof FormData} prop 対象のプロパティ名
 * @param {*} value 入力された値
 * @param {ValidateOptions} [options={}]
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validate(prop, value, options={}) {
  switch (prop) {
    // 企画書
    case 'proposalId':
      return validateProposalId(value);
    // 対象期間
    case 'period':
      return validatePeriod(value);
    // 報告期間開始日
    case 'ryStartDate':
      return validateRyStartDate(value, options.proposalDetail);
    // 報告期間終了日
    case 'ryEndDate':
      return validateRyEndDate(value, options.formData, options.proposalDetail);
    // 請求先 郵便番号
    case 'billingZipCode':
      return validateBillingZipCode(value);
    // 請求先 電話番号
    case 'billingPhone':
      return validateBillingPhone(value);
    // 請求先 住所
    case 'billingAddress':
      return validateBillingAddress(value);
    // 請求先 部署名
    case 'billingDepartment':
      return validateBillingDepartment(value);
    // 請求先 担当者名
    case 'billingName':
      return validateBillingName(value);
    default:
      return { tmp: [], apply: [] };
  }
}

/**
 * 企画内部コードのバリデーション
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateProposalId(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  if (isEmpty(value)) {
    const error = getMessage('isNotEmpty');
    errors.tmp.push(error);
    errors.apply.push(error);
    return errors;
  }

  return errors;
}

/**
 * 対象期間のバリデーション
 * @param {*} value 入力された値
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validatePeriod(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  }

  if (isEmpty(value)) {
    const error = getMessage('isNotEmpty');
    errors.tmp.push(error);
    errors.apply.push(error);
    return errors;
  }

  return errors;
}

/**
 * 報告期間開始日のバリデーション
 * @param {*} value 入力された値
 * @param {ProposalDetail|null|undefined} proposalDetail 企画詳細
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateRyStartDate(value, proposalDetail) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  if (!checkRyDateFreeInput(proposalDetail ?? null)) {
    // 報告期間が入力不可能な企画の場合はバリデート対象外
    return errors;
  }

  if (isEmpty(value)) {
    const error = getMessage('isNotEmpty');
    errors.tmp.push(error);
    errors.apply.push(error);
    return errors;
  }

  return errors;
}

/**
 * 報告期間終了日のバリデーション
 * @param {*} value 入力された値
 * @param {FormData|undefined} formData フォーム全体の値
 * @param {ProposalDetail|null|undefined} proposalDetail 企画詳細
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function validateRyEndDate(value, formData, proposalDetail) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  if (!checkRyDateFreeInput(proposalDetail ?? null)) {
    // 報告期間が入力不可能な企画の場合はバリデート対象外
    return errors;
  }

  if (isEmpty(value)) {
    const error = getMessage('isNotEmpty');
    errors.tmp.push(error);
    errors.apply.push(error);
    return errors;
  }

  // 報告期間開始日との相関チェック
  const correlationErrors = checkRyDateCorrelation(formData?.ryStartDate, value);
  errors.tmp = [...errors.tmp, ...correlationErrors.tmp];
  errors.apply = [...errors.apply, ...correlationErrors.apply];

  return errors;
}

/**
 * 請求先郵便番号のバリデート
 * @param {*} value 入力された値
 */
function validateBillingZipCode(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  }

  /** @type {string[]} */
  const commonErrors = [];

  // 必須チェック
  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  if (!lengthRange(value, 8, 8)) {
    // 8文字
    commonErrors.push('半角ハイフンを含む8桁で入力してください');
  } else if (!value.match(/\d{3}-\d{4}/)) {
    // 郵便番号形式
    commonErrors.push('郵便番号形式で入力してください');
  }
  errors.tmp.push(...commonErrors);
  errors.apply.push(...commonErrors);

  return errors;
}

/**
 * 請求先電話番号のバリデート
 * @param {*} value 入力された値
 */
function validateBillingPhone(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  // 必須チェック
  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  // 13文字以内
  if (!maxLength(value, 13)) {
    const error = getMessage('maxLength', { max: 13 })
    errors.apply.push(error);
    errors.tmp.push(error);
  }

  return errors;
}

/**
 * 請求先住所のバリデート
 * @param {*} value 入力された値
 */
function validateBillingAddress(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  // 必須チェック
  if (isEmpty(value)) {
    errors.apply.push(getMessage('isNotEmpty'));
    return errors;
  }

  // 100文字以内
  if (!maxLength(value, 100)) {
    const error = getMessage('maxLength', { max: 100 });
    errors.apply.push(error);
    errors.tmp.push(error);
  }

  return errors;
}

/**
 * 請求先部署名のバリデート
 * @param {*} value 入力された値
 */
function validateBillingDepartment(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  // 100文字以内
  if (!maxLength(value, 100)) {
    const error = getMessage('maxLength', { max: 100 });
    errors.apply.push(error);
    errors.tmp.push(error);
  }

  return errors;
}

/**
 * 請求先担当者名のバリデート
 * @param {*} value 入力された値
 */
function validateBillingName(value) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  // 20文字以内
  if (!maxLength(value, 20)) {
    const error = getMessage('maxLength', { max: 20 });
    errors.apply.push(error);
    errors.tmp.push(error);
  }

  return errors;
}

/**
 * 報告対象期間の相関チェックを行う
 * @param {string|undefined} ryStartDate 報告期間開始日
 * @param {string|undefined} ryEndDate 報告期間終了日
 * @returns {{ tmp: string[], apply: string[] }} エラーメッセージ
 */
function checkRyDateCorrelation(ryStartDate, ryEndDate) {
  const errors = {
    /** @type {string[]} */
    tmp: [],
    /** @type {string[]} */
    apply: [],
  };

  if (isEmpty(ryStartDate) || isEmpty(ryEndDate)) {
    return errors;
  }

  const start = dayjs(ryStartDate, 'YYYY/MM/DD');
  const end = dayjs(ryEndDate, 'YYYY/MM/DD');

  // 日付の前後関係
  if (start.isAfter(end, 'date')) {
    const msg = '開始日以降の日付を設定してください';
    errors.tmp.push(msg);
    errors.apply.push(msg);
  }


  return errors;
}

/**
 * 対象のロイヤリティ報告が編集可能な状態か判定する
 * @param {RoyaltyDetail} royalty 判定対象のロイヤリティ報告
 * @returns {boolean} 編集可能な場合はtrue
 */
function checkEditable(royalty) {
  return EDITABLE_STATUS.includes(royalty?.reportStatus);
}

/**
 * フォームデータの初期値を取得する
 * @returns {FormData} フォームデータの初期値
 */
function getInitialFormData() {
  return {
    proposalId: null,
    period: null,
    billingZipCode: '',
    billingPhone: '',
    billingAddress: '',
    billingDepartment: '',
    billingName: '',
    ryStartDate: '',
    ryEndDate: '',
  };
}

/**
 * 最も古い期を取得する
 * @param {ProposalPeriod[]} periodList 第N期のリスト
 * @returns {?ProposalPeriod} 最も古い期
 */
function getOldestPeriod(periodList) {
  // const now = dayjs();
  const sorted = [...periodList].sort((a, b) => {
    const aDate = dayjs(a.ryStartDate, 'YYYY/MM/DD');
    const bDate = dayjs(b.ryStartDate, 'YYYY/MM/DD');
    return aDate.diff(bDate, 'days');
  });

  // 選択可能な期の中で最も古い期を選択する
  return sorted[0] ?? null;
}

/**
 * 企画情報の商品情報をロイヤリティ金額情報フォームの形式に変換する
 * @param {Product[]} productList 商品情報
 * @returns {RyAmount[]} ロイヤリティ金額情報のフォームデータ
 */
function convertProductToRyAmount(productList) {
  return productList.map(product => {
    if (product.productStatus === Constants.Licensee.productStatus.Approved) {
      // 承認済みの商品は全項目空で返す
      // (入力欄の初期値を空欄にするため)
      return {
        ryAmountNo: null,
        productId: product.productId,
        reportProduction: null,
        reportSales: null,
        reportPrice: null,
        reportCost: null,
        reportProceeds: null,
        reportRyAmount: null,
      };
    }

    // 未承認の商品はRY報告パターンに応じて値を埋める
    const result = {
        ryAmountNo: null,
        productId: product.productId,
        reportProduction: null,
        reportSales: null,
        reportPrice: null,
        reportCost: null,
        reportProceeds: null,
        reportRyAmount: null,
    };
    switch (product.rypId) {
      // 上代×ロイヤリティ料率×生産数
      case Constants.RoyaltyPattern.PriceRateProductNum:
        result.reportProduction = 0;
        result.reportSales = 0;
        result.reportPrice = product.planPrice;
        break;
      // 上代×ロイヤリティ料率×販売数
      case Constants.RoyaltyPattern.PriceRateSalesNum:
        result.reportProduction = 0;
        result.reportSales = 0;
        result.reportPrice = product.planPrice;
        break;
      // 製造原価×ロイヤリティ料率×生産数
      case Constants.RoyaltyPattern.CostRateProductNum:
        result.reportProduction = 0;
        result.reportSales = 0;
        result.reportCost = product.planCost;
        break;
      // 対象売上金額×ロイヤリティ料率
      case Constants.RoyaltyPattern.SalesPriceRate:
        result.reportProduction = 0;
        result.reportSales = 0;
        result.reportProceeds = 0;
        break;
      // 生産数×ロイヤリティ単価
      case Constants.RoyaltyPattern.ProductNumUniPrice:
        result.reportProduction = 0;
        result.reportSales = 0;
        break;
      // 販売数×ロイヤリティ単価
      case Constants.RoyaltyPattern.SalesNumUnitPrice:
        result.reportProduction = 0;
        result.reportSales = 0;
        break;
      // ロイヤリティ金額（直接入力）
      case Constants.RoyaltyPattern.FixedPrice:
        result.reportProduction = 0;
        result.reportSales = 0;
        result.reportRyAmount = 0;
        break;
      default:
        break;
    }

    return result;
  });
}

//#region typedef
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalPeriod} ProposalPeriod 第N期の情報
 */
/**
 * @typedef {import('../../../slices/licensee/proposalsSlice').ProposalProduct} Product 商品情報
 */
/**
 * @typedef {import('../../../lib/api/licensee').PostRoyaltiesParam} PostRoyaltiesParam
 */
/**
 * @typedef {Required<Pick<PostRoyaltiesParam, 'proposalId'|'period'|'billingZipCode'|'billingPhone'|'billingAddress'|'billingDepartment'|'billingName'|'ryStartDate'|'ryEndDate'>>} TargetProps フォームの入力内容
 */
/**
 * @typedef {PropNullable<TargetProps, 'proposalId'|'period'>} FormData フォームの入力内容
 */
/**
 * @typedef {Record<keyof FormData, { tmp: string[], apply: string[] }>} ErrorMessages エラーメッセージ
 */
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RoyaltyDetail} RoyaltyDetail ロイヤリティ報告情報
 */
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RyAmount} RyAmount ロイヤリティ金額情報
 */
/**
 * @typedef {import('../../../slices/licensee/royaltiesSlice').RyProof} RyProof ロイヤリティ報告確証ファイル情報
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
/**
 * @typedef {{[K in keyof T]: K extends U ? T[K]|null : T[K]}} PropNullable
 * @template T
 * @template {keyof T} U
 */
//#endregion typedef
