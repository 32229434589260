import { selectCategoryMst, selectRoyaltyPatternMst } from "../../../slices/aniplex/masterSlice";
import { useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { insertComma } from "../../../lib/number";
import { UploadImageShowOnly } from '../UploadImageShowOnly';
import { comma3, formatPeriodYM } from "../../../lib/util";
import { ValueChangeStatus } from "../common/ValueChangeStatus";

/** 販売方式の選択肢 */
const salesMethodOptions = Object.freeze({
  '1': '個別商品',
  '2': 'トレーディング商品',
});
/** 生産方式の選択肢 */
const productMethodOptions = Object.freeze({
  '1': '見込み生産',
  '2': '受注生産',
});

/**
 * 商品詳細ポップアップ
 * @param {object} args
 * @param {ProposalProduct} args.product 商品データ
 * @param {ProposalProduct|null} args.prevProduct 前のバージョンの商品データ
 * @param {ProposalPeriod[]} args.periodList 企画の第N期のリスト
 * @param {Function} args.onClose コールバック
 */
export const ProductDetail = ({
  product,
  prevProduct,
  periodList,
  onClose,
}) => {
  // スクロールの位置保存
  const [preY] = useState(window.scrollY);
  const categoryMst = useSelector(selectCategoryMst);
  const royaltyMst = useSelector(selectRoyaltyPatternMst);

  // UploadImage
  // アップロード済みの画像
  const [uploadedFileNoList, setUploadedFileNoList] = useState([]);
  // ポップアップを開いたときに商品データの画像リストを反映
  const uploadedFileNoList4Uploader = useMemo(() => {
    return uploadedFileNoList.map(i => i.renderingImageNo);
  }, [uploadedFileNoList]);
  // ポップアップを開いたときに商品データの画像リストを反映
  useEffect(() => {
    setUploadedFileNoList(product?.renderingImageList ?? []);
  }, [product?.renderingImageList]);
  /** 前バージョンの画像ファイルNoリスト */
  const prevFileNoList = useMemo(() =>
    prevProduct?.renderingImageList.map(i => i.renderingImageNo)
  , [prevProduct?.renderingImageList]);

  /** カテゴリ名 */
  const categoryName = useCategoryName(product.categoryNo);
  /** 前バージョンのカテゴリ名 */
  const prevCategoryName = useCategoryName(prevProduct?.categoryNo);
  /** カテゴリ詳細名 */
  const categoryDetailName = useCategoryDetailName(product.categoryNo, product.categoryDetailNo);
  /** 前バージョンのカテゴリ詳細名 */
  const prevCategoryDetailName = useCategoryDetailName(prevProduct?.categoryNo, prevProduct?.categoryDetailNo);
  /** RY報告パターン名 */
  const rypName = useRypName(product.rypId);
  /** 前バージョンのRY報告パターン名 */
  const prevRypName = useRypName(prevProduct?.rypId);
  /** 販売方式名称 */
  const salesMethodName = useSalesMethodName(product.salesMethod);
  /** 前バージョンの販売方式名称 */
  const prevSalesMethodName = useSalesMethodName(prevProduct?.salesMethod);
  /** 生産方式名称 */
  const productMethodName = useProductMethodName(product.productMethod);
  /** 前バージョンの生産方式名称 */
  const prevProductMethodName = useProductMethodName(prevProduct?.productMethod);

  // カテゴリの設定値
  const categoryOption = useMemo(()  => categoryMst.map((data) =>
    <option
    key={"category__" + data?.categoryNo}
    value={data?.categoryNo}>{data?.categoryName}</option>
  ), [categoryMst]);
  // RY報告パタンの設定値
  const royaltyOption = useMemo(() => royaltyMst.map((data) =>
    <option
    key={"ryp__" + data?.rypId}
    value={data?.rypId}>{data?.rypName}</option>
  ), [royaltyMst]);

  const [categoryDetailOption, setCategoryDetailOption] = useState([]);
    // カテゴリ
  useEffect(() => {
    // ページトップに遷移
    window.scrollTo(0, 0);
    const category = categoryMst.find(cat => cat.categoryNo === product.categoryNo);
    setCategoryDetailOption([
          category?.categoryDetailList.map((data) =>
          <option
          key={"catDetail__" + data?.categoryDetailNo}
          value={data?.categoryDetailNo}>{data?.categoryDetailName}</option>
        )
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[product])

  const periodDataList = [];
  const productionList = [];
  const salesList = [];
  const [sumPlanProduction, setSumPlanProduction] = useState(0);
  const [sumPlanSales, setSumPlanSales] = useState(0);
  for (let step = 1; step <= product.periodList.length; step++) {
    const periodData = product.periodList.find(data => data.period === step);
    const prevPeriodData = prevProduct?.periodList.find(data => data.period === step);
    const proposalPeriod = periodList.find(pr => pr.period === step);
    const periodYm = formatPeriodYM(proposalPeriod.ryStartDate, proposalPeriod.ryEndDate);
    // 予定生産数量
    periodDataList.push(<th key={"th__"+step} scope="col" style={{"height": "50px", "minWidth": "304px"}}>第{step}期<br />{periodYm}</th>);
    productionList.push(
      <td key={"production__"+step}>
        {
          prevProduct != null && (
            <ValueChangeStatus
              value={comma3(periodData?.planProduction ?? '')}
              prevValue={comma3(prevPeriodData?.planProduction ?? '')}
              inline />
          )
        }
        <div className="input-form wdt200 cost" style={{ display: 'inline-block' }}>
          <input type="text" name="planProduction" aria-label="第N期" required
          value={insertComma(periodData?.planProduction ?? '')}
          disabled />
        </div>
      </td>
    );
    // 予定販売数量
    salesList.push(
      <td key={"sales__"+step}>
        {
          prevProduct != null && (
            <ValueChangeStatus
              value={comma3(periodData?.planSales ?? '')}
              prevValue={comma3(prevPeriodData?.planSales ?? '')}
              inline />
          )
        }
        <div className="input-form wdt200 cost" style={{ display: 'inline-block' }}>
          <input type="text" name="planSales" aria-label="第N期" required
            value={insertComma(periodData?.planSales ?? '')}
            disabled />
        </div>
      </td>
    );
  }

  /** 前のバージョンの予定生産数量合計 */
  const prevSumPlanProduction = useMemo(() =>
    prevProduct?.periodList
      .map(p => p.planProduction)
      .reduce((prev, cur) => prev + cur, 0)
    , [prevProduct?.periodList]);
  /** 前のバージョンの予定販売数量合計 */
  const prevSumPlanSales = useMemo(() =>
    prevProduct?.periodList
      .map(p => p.planSales)
      .reduce((prev, cur) => prev + cur, 0)
    , [prevProduct?.periodList]);

  // 期の予定数量の合計列
  productionList.push(
    <td key="production__cost">
      <span style={{ display: 'flex' }}>
        {
          prevSumPlanProduction != null && (
            <ValueChangeStatus
              value={comma3(sumPlanProduction)}
              prevValue={comma3(prevSumPlanProduction)} />
          )
        }
        <span style={{ display: 'block', marginLeft: 'auto' }}>
          {insertComma(sumPlanProduction)}
        </span>
      </span>
    </td>
  );
  salesList.push(
    <td key="sales__cost">
      <span style={{ display: 'flex'}} >
        {
          prevSumPlanSales != null && (
            <ValueChangeStatus
              value={comma3(sumPlanSales)}
              prevValue={comma3(prevSumPlanSales)} />
          )
        }
        <span style={{ display: 'block', marginLeft: 'auto' }}>
          {insertComma(sumPlanSales)}
        </span>
      </span>
    </td>
  );

  // 予定生産数の集計
  useEffect(() => {
    const sumProductions = product.periodList.map(period => period.planProduction);
    const sumProduction = sumProductions.length > 0 ? sumProductions.reduce((prev, cur) => Number(prev) + Number(cur)) : 0;
    setSumPlanProduction(sumProduction);
    const sumSales = product.periodList.map(period => period.planSales);
    const sumSale = sumProductions.length > 0 ? sumSales.reduce((prev, cur) => Number(prev) + Number(cur)) : 0;
    setSumPlanSales(sumSale);
  }, [product]);

  return (
    <div className="l-popup active product-popup">
    <div className="popup-content">
      <div className="popup-body">
        <div className="wrap">
          <div className="title-border mb20">
            <h2 className="title">商品詳細</h2>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">
                商品名等
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.productName}
                      prevValue={prevProduct.productName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt300">
                  <input type="text" name="productName" required aria-label="商品名等"
                  value={product.productName ?? ''}
                  disabled  />
                <span className="attention">例：B2タペストリー、1/7フィギュア</span>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                カテゴリー
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={categoryName}
                      prevValue={prevCategoryName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-select wdt250">
                  <select name="categoryNo" required
                  defaultValue={product.categoryNo}
                  disabled >
                    {categoryOption}
                  </select>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                カテゴリー詳細
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={categoryDetailName}
                      prevValue={prevCategoryDetailName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-select wdt300">
                  <select name="categoryDetailNo" required
                  value={product.categoryDetailNo}
                  disabled >
                    {categoryDetailOption}
                  </select>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name">
                キャラクター
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.character}
                      prevValue={prevProduct.character} />
                  )
                }
              </dt>
              <dd className="form-body" style={{ display: 'block' }}>
                <div className="input-form wdt500">
                  <input type="text" name="character" aria-label="キャラクター"
                  value={product.character ?? ''}
                  disabled  />
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name">
                バージョン
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.version}
                      prevValue={prevProduct.version} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="version" aria-label="バージョン"
                  value={product.version ?? ''}
                  disabled  />
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">
                ロイヤリティ報告パターン
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={rypName}
                      prevValue={prevRypName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-select wdt300">
                  <select name="rypId" required
                  value={product.rypId}
                  disabled >
                    {royaltyOption}
                  </select>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                発売希望日
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.launchDate}
                      prevValue={prevProduct.launchDate} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt300">
                  <input type="text" name="launchDate" aria-label="発売希望日"
                    value={product.launchDate ?? ''}
                    disabled  />
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">
                上代（税抜き）
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={comma3(product.planPrice ?? '')}
                      prevValue={comma3(prevProduct.planPrice ?? '')} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form cost wdt100">
                  <input type="text" name="planPrice" aria-label="上代（税抜き）" required
                  value={insertComma(product.planPrice ?? '')}
                  disabled />
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                製造原価
                {
                  prevProduct && (
                    <ValueChangeStatus
                      value={comma3(product.planCost ?? '')}
                      prevValue={comma3(prevProduct.planCost ?? '')} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form cost wdt140">
                  <input type="text" name="planCost" aria-label="製造原価"
                  value={insertComma(product.planCost ?? '')}
                  disabled />
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                予定売上金額
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={comma3(product.planProceeds ?? '')}
                      prevValue={comma3(prevProduct.planProceeds ?? '')} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form cost wdt140">
                  <input type="text" name="planProceeds" aria-label="予定売上金額"
                  value={insertComma(product.planProceeds ?? '')}
                  disabled />
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "135px"}}>予定生産数量</dt>
              <dd className="form-body">
                <div className="l-table scroll" style={{ 'width': 1200 }}>
                  <table className="bb-none">
                    <thead>
                      <tr>
                        {periodDataList}
                        <th scope="col" style={{"height": "40px", "minWidth": "182px"}}>期間合計</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {productionList}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "135px"}}>予定販売数量</dt>
              <dd className="form-body">
                <div className="l-table scroll" style={{ 'width': 1200 }}>
                  <table className="bb-none">
                    <thead>
                      <tr>
                        {periodDataList}
                        <th scope="col" style={{"height": "40px", "minWidth": "182px"}}>期間合計</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {salesList}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{ minWidth: "135px"}}>
                ロイヤリティ
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.ryRate ?? ''}
                      prevValue={prevProduct.ryRate ?? ''} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="dif a-center">
                  <div className="input-form wdt50 mr10 cost">
                    <input type="text" pattern="\d{1,3}" name="ryRate" aria-label="ロイヤリティ" maxLength={3} required
                    value={product.ryRate ?? ''}
                    disabled />
                  </div>
                  <p>
                    ％ ／ 固定単価
                    {
                      prevProduct != null && (
                        <ValueChangeStatus
                          value={comma3(product.ryPrice ?? '')}
                          prevValue={comma3(prevProduct.ryPrice ?? '')}
                          inline />
                      )
                    }
                  </p>
                  <div className="input-form wdt140 ml5 mr5 cost">
                    <input type="text" name="ryPrice" aria-label="固定単価" required
                    value={insertComma(product.ryPrice ?? '')}
                    disabled />
                  </div>
                  <p>円</p>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                ロイヤリティ額
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={comma3(product.planRyAmount ?? '')}
                      prevValue={comma3(prevProduct.planRyAmount ?? '')} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="dif a-center">
                  <div className="input-form wdt140 mr5 cost">
                    <input type="text" name="planRyAmount" aria-label="ロイヤリティ額" required
                    value={insertComma(product.planRyAmount ?? '')}
                    disabled
                    />
                  </div>
                  <p>円</p>
                </div>
              </dd>
            </dl>

          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "135px"}}>
                販売方式
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={salesMethodName}
                      prevValue={prevSalesMethodName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-select wdt200">
                  <select name="salesMethod" required
                  defaultValue={product.salesMethod}
                  disabled >
                    {
                      Object.entries(salesMethodOptions).map(([val, label]) => (
                        <option key={val} value={val}>{label}</option>
                      ))
                    }
                  </select>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">
                生産方式
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={productMethodName}
                      prevValue={prevProductMethodName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-select wdt140">
                  <select name="productMethod" required
                    defaultValue={product.productMethod}
                    disabled
                  >
                    {
                      Object.entries(productMethodOptions).map(([val, label]) => (
                        <option key={val} value={val}>{label}</option>
                      ))
                    }
                  </select>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "135px"}}>
                素材他仕様
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.material}
                      prevValue={prevProduct.material} />
                  )
                }
              </dt>
              <dd className="form-body">
                <label className="form-textarea wdt1200">
                  <textarea rows={4} name="material" aria-label="素材他仕様"
                  defaultValue={product.material}
                  disabled >
                  </textarea>
                </label>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{ minWidth: '135px', lineHeight: '20px'}}>
                <span>キャラクター<br />ラインナップ</span>
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.characterLineup}
                      prevValue={prevProduct.characterLineup} />
                  )
                }
              </dt>
              <dd className="form-body">
                <label className="form-textarea wdt1200" style={{ marginBottom: '10px' }}>
                  <textarea name="characterLineup" rows={4}
                    aria-label="キャラクターラインナップ"
                    disabled
                    value={product.characterLineup} />
                </label>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "135px"}}>
                備考
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.productRemarks}
                      prevValue={prevProduct.productRemarks} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <textarea rows={4} name="productRemarks" aria-label="備考"
                  defaultValue={product.productRemarks}
                  disabled >
                  </textarea>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
            <dt className="form-name" style={{"width": "135px"}}>商品イメージ</dt>
            <dd className="form-body wdt1200" style={{"display":"block"}}>
              <UploadImageShowOnly
                fileNoList={uploadedFileNoList4Uploader}
                prevFileNoList={prevFileNoList} />
              <p className="mt10">
                ファイル形式： JPEG, PNG, GIF<br />
                ファイルサイズ：1MB以内<br />
                ファイル数：10個以内
              </p>
            </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{ minWidth: "135px"}}>
                自由記入欄1
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.productOption1}
                      prevValue={prevProduct.productOption1} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption1" aria-label="自由記入欄1"
                  value={product.productOption1 ?? ''}
                  disabled  />
                </div>
              </dd>
            </dl>
          </div>
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{ minWidth: "135px"}}>
                自由記入欄2
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.productOption2}
                      prevValue={prevProduct.productOption2} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption2" aria-label="自由記入欄2"
                  value={product.productOption2 ?? ''}
                  disabled  />
                </div>
              </dd>
            </dl>
          </div>
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{ minWidth: "135px"}}>
                自由記入欄3
                {
                  prevProduct != null && (
                    <ValueChangeStatus
                      value={product.productOption3}
                      prevValue={prevProduct.productOption3} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption3" aria-label="自由記入欄3"
                  value={product.productOption3 ?? ''}
                  disabled  />
                </div>
              </dd>
            </dl>
          </div>
        </div>

        <div className="btn label bg-pink mt15 mb20 mlrauto" style={{"width": "190px"}}>
        </div>
      </div>

      <p className="btn-close" onClick={() => {window.scrollTo(0, preY); onClose('close')}}>close</p>
    </div>
    <div className="overlay"></div>
    </div>
  );
}

/**
 * 商品カテゴリNoに対応するカテゴリ情報
 * @param {number|undefined} categoryNo 商品カテゴリNo
 */
const useCategoryInfo = (categoryNo) => {
  const categoryMst = useSelector(selectCategoryMst);

  return useMemo(() =>
    categoryMst.find(c => c.categoryNo === categoryNo)
  , [categoryMst, categoryNo]);
}

/**
 * 商品カテゴリNoに対応するカテゴリ名
 * @param {number|undefined} categoryNo 商品カテゴリNo
 */
const useCategoryName = (categoryNo) => {
  const category = useCategoryInfo(categoryNo);

  return category?.categoryName;
}

/**
 * 商品カテゴリNo、商品カテゴリ詳細Noに対応する商品カテゴリ詳細情報
 * @param {number|undefined} categoryNo 商品カテゴリNo
 * @param {number|undefined} categoryDetailNo 商品カテゴリ詳細No
 */
const useCategoryDetail = (categoryNo, categoryDetailNo) => {
  const category = useCategoryInfo(categoryNo);

  return useMemo(() =>
    category?.categoryDetailList.find(d => d.categoryDetailNo === categoryDetailNo)
  , [category?.categoryDetailList, categoryDetailNo]);
}

/**
 * 商品カテゴリNo、商品カテゴリ詳細Noに対応する商品カテゴリ詳細名
 * @param {number|undefined} categoryNo 商品カテゴリNo
 * @param {number|undefined} categoryDetailNo 商品カテゴリ詳細No
 */
const useCategoryDetailName = (categoryNo, categoryDetailNo) => {
  const categoryDetail = useCategoryDetail(categoryNo, categoryDetailNo);

  return categoryDetail?.categoryDetailName;
}

/**
 * パターンIDに応じたロイヤリティ報告パターン名
 * @param {string|undefined} rypId ロイヤリティ報告パターンID
 * @returns
 */
const useRypName = (rypId) => {
  const rypPtnMst = useSelector(selectRoyaltyPatternMst);

  return useMemo(() =>
    rypPtnMst.find(r => r.rypId === rypId)?.rypName
  , [rypId, rypPtnMst]);
}

/**
 * 販売方式のコード値に対応する表示名
 * @param {keyof typeof salesMethodOptions|undefined} code コード値
 */
const useSalesMethodName = (code) => {
  return useMemo(() => {
    if (code === undefined) {
      return undefined;
    }
    return salesMethodOptions[code];
  }, [code]);
}

/**
 * 生産方式のコード値に対応する表示名
 * @param {keyof typeof productMethodOptions|undefined} code コード値
 */
const useProductMethodName = (code) => {
  return useMemo(() => {
    if (code === undefined) {
      return undefined;
    }
    return productMethodOptions[code];
  }, [code])
}

//#region
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalProduct} ProposalProduct
 */
/**
 * @typedef {import("../../../slices/aniplex/proposalsSlice").ProposalPeriod} ProposalPeriod
 */
//#endregion
