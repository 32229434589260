//@ts-check
import React, { useMemo } from 'react';
import { ChangeStatusIcon } from './ChangeStatusIcon';

/** 値が空の場合の表示文字列 */
const emptyString = '（空欄）';

/**
 * 更新状態表示
 * @param {object} params
 * @param {CompareValue} params.value 現在の値
 * @param {CompareValue} params.prevValue 前回の値
 * @param {boolean} [params.inline=false] インライン表示フラグ
 * @param {boolean} [params.hideTooltip=false] ツールチップ非表示フラグ
 */
export const ValueChangeStatus = ({
  value,
  prevValue,
  inline = false,
  hideTooltip = false,
}) => {
  /** アイコン表示フラグ */
  const showIcon = useMemo(() => value != null && value !== prevValue,
    [prevValue, value]);

  /** ツールチップの内容 */
  const tooltipText = useMemo(() => {
    if (prevValue == null || prevValue === '') {
      return emptyString;
    }
    return String(prevValue);
  }, [prevValue]);

  return showIcon ? (
    <ChangeStatusIcon
      className={`value-change-status ${inline ? 'inline' : ''}`}
      iconClass="changed"
      tooltipText={tooltipText}
      hideTooltip={hideTooltip} />
  ) : null;
}

//#region typedef
/**
 * @typedef {string|number|boolean|null|undefined} CompareValue 比較対象の値
 */
//#endregion typedef
