/** アプリケーションのコンフィグ */
export const Config = Object.freeze({
  /** ANIPLEX向け画面か */
  ForAniplex: window.ServerConfig.ForAniplex,
  /** ANIPLEX向け画面用のAPIエンドポイント */
  AniplexApiUrl: window.ServerConfig.AniplexApiUrl,
  /** ANIPLEX向けidentity_provider文字列 */
  AniplexProvider: window.ServerConfig.AniplexProvider,
  /** ライセンシー画面のURL */
  LicenseeUrl: window.ServerConfig.LicenseeUrl,
  /** ライセンシー向け画面用のAPIエンドポイント */
  LicenseeApiUrl: window.ServerConfig.LicenseeApiUrl,
  /** 環境ごとのtitleの値 */
  SiteTitle: window.ServerConfig.SiteTitle,
  /** ライセンシー向けホーム画面用のお知らせHTMLのパス */
  LicenseeHomeNewsUrl: "./news_html.txt",
  /** ライセンシー向けプライバシーポリシー画面のHTMLパス */
  LicenseePrivacyPopupUrl:"./privacy_html.txt",
  /** ライセンシー向け利用規約画面用のHTMLパス */
  LicenseeTermsPopupUrl: "./terms_html.txt",
  /** ANIPLX画面用の認証エラーHTMLのパス */
  AniplexNoPermissionUrl: "./no_permission.html",
  /** 作品アイコン画像の基底パス */
  PropertyIconPath: '/icon/',
  /** 1商品あたりの商品イメージの最大件数 */
  ProductImageLimit: 10,
  /** 送信元メールアドレス */
  FromEmail: 'roys_info_no-reply@aniplex.jp',
  /** 画像取得関連 */
  GetImage: Object.freeze({
    /** エラー時再試行の回数 */
    RetryCount: 3,
    /** エラー時再試行の待ち時間 */
    RetryWaitMs: 2000,
  }),
  /** ファイルダウンロード関連 */
  DownloadFile: Object.freeze({
    /** エラー時再試行の回数 */
    RetryCount: 3,
    /** エラー時再試行の待ち時間 */
    RetryWaitMs: 2000,
  }),
  /** trueにするとAniplex側でcognitoのログイン画面に遷移させない */
  NoCognitoRedirect: false,
  /** ANIPLEXの取引先コード */
  AniplexLicenseeCode: '748070',
});
