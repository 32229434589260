import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../../Constants';
import { useFocusError, useFormData } from '../../../lib/hooks/common';
import { isEmpty } from '../../../lib/validator';
import { fetchProposalDetail, selectApiStatus, selectProposalDetail } from '../../../slices/aniplex/proposalsSlice';
import { selectMyself } from '../../../slices/aniplex/usersSlice';
import { ApiLoadingOverlay } from '../../common/ApiLoadingOverlay';
import { MessagePopup } from '../../common/MessagePopup';
import { AniplexMngForm, validate as anxMngValidate } from './AniplexMngForm';
import { RoyaltyProductsForm } from './RoyaltyProductsForm';
import { RyProofDownloader } from './RyProofDownloader';
import { CommonRejectPopup } from '../CommonRejectPopup';
import { RoyaltyStatusForm } from './RoyaltyStatusForm';
import { useNotAcceptedReportExists, useRyAmountTotal } from './hooks';
import { ValueChangeStatus } from '../common/ValueChangeStatus';
import { NotAcceptedAlert } from './NotAcceptedAlert';
import { MessageHistoryArea } from './MessageHistoryArea';

/** ANIPLEX管理部分のフォーム項目 */
const anxMngFormPropOrder = [
  // 処理内容
  'process',
  // 請求予定日(YYYY/MM/DD)
  'billingDate',
  // 売上日(YYYY/MM/DD)
  'salesDate',
  // 入金予定日(YYYY/MM/DD)
  'depositDate',
  // 税区分
  'taxType',
  // 消費税内外区分
  'taxInOut',
  // 消費税
  'consumptionTax',
  // 請求書発行有無
  'bill',
  // 請求項目
  'billRemarks',
];

/**
 * [A]ロイヤリティ報告詳細画面のフォーム部分
 * @param {object} props
 * @param {RoyaltyDetail|undefined} props.royalty ロイヤルティ報告詳細情報
 * @param {boolean} props.formLocked 入力抑制フラグ
 * @param {(params: ExportRoyaltyParams) => void} props.onAccept 受領ボタン押下時のコールバック
 * @param {() => void} props.onReject 差し戻しボタン押下時のコールバック
 * @returns
 */
export const RoyaltyReportDetailForm = ({
  royalty,
  formLocked,
  onAccept,
  onReject
}) => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const apiStatus = useSelector(selectApiStatus);
  const proposalDetail = useSelector(selectProposalDetail);

  const notAcceptedReportExists = useNotAcceptedReportExists(royalty);

  useEffect(() => {
    if (royalty?.proposalId != null) {
      // 企画情報を取得する
      dispatch(fetchProposalDetail(royalty.proposalId));
    }
  }, [dispatch, royalty?.proposalId]);

  // 受領確認ポップアップ
  const [acceptConfirm, setAcceptConfirm] = useState({
    showFlg: false,
    onClose: null,
  });
  // 差し戻し確認ポップアップ
  const [rejectPopup, setRejectPopup] = useState({
    showFlg: false,
    onClose: null
  });

  /** ロイヤリティ金額の合計 */
  const ryAmountTotal = useRyAmountTotal(royalty?.ryAmountList);

  /** 権限保持ユーザフラグ */
  const canModifyUser = useMemo(() => {
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
    ].includes(myself?.role);
  }, [myself?.role]);

  /** 0円報告フラグ */
  const isZeroReport = useMemo(() => ryAmountTotal === 0, [ryAmountTotal]);

  /** 受領可能フラグ */
  const canAccept = useMemo(() => {
    return royalty?.reportStatus === Constants.Aniplex.reportStatus.Requesting
      && !notAcceptedReportExists;
  }, [notAcceptedReportExists, royalty?.reportStatus]);

  /** 差し戻し可能フラグ */
  const canReject = useMemo(() => {
    return [
      Constants.Aniplex.reportStatus.Requesting,
      Constants.Aniplex.reportStatus.ReportedZero,
      Constants.Aniplex.reportStatus.Exported,
    ].includes(royalty?.reportStatus);
  }, [royalty?.reportStatus]);

  /**
   * @typedef {'accept'|null} Action アクション名
   */
  /**
   * 受領・差し戻しアクション
   * @type {[Action, (act: Action) => void]}
   */
  const [action, setAction] = useState(null)

  /** バリデート関数 */
  const validate = useCallback((prop, value, formData) => {
    return anxMngValidate(isZeroReport, prop, value, formData);
  }, [isZeroReport]);

  /** 強制バリデートフラグ */
  const [validateAllFlg, setValidateAllFlg] = useState(false);

  // ANIPLEX管理セクションのデータ
  const [aniplexData, handleAniplexDataChange, aniplexDataErrors] = useFormData(() => ({
    /** @type {number} ロイヤリティ報告書内部コード */
    ryReportId: null,
    /** @type {'0'|'1'} 処理内容 */
    process: null,
    /** @type {string} 請求予定日(YYYY/MM/DD) */
    billingDate: '',
    /** @type {string} 売上日(YYYY/MM/DD) */
    salesDate: '',
    /** @type {string} 入金予定日(YYYY/MM/DD) */
    depositDate: '',
    /** @type {'21'|'11'|'01'|'31'|'0'} 税区分 */
    taxType: '',
    /** @type {'0'|'1'|'2'} 消費税内外区分 */
    taxInOut: '',
    /** @type {number} 消費税 */
    consumptionTax: null,
    /** @type {'0'|'1'} 請求書発行有無 */
    bill: '',
    /** @type {string} 請求項目 */
    billRemarks: '',
    /** @type {string} 最終更新日時(YYYY/MM/DD HH:mm:ss) */
    updateDatetime: '',
  }), validate, validateAllFlg);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(anxMngFormPropOrder);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of anxMngFormPropOrder) {
      if (aniplexDataErrors[prop]?.length > 0) {
        if (focusError(prop)) {
          setNeedFocusError(false);
          return;
        }
      }
    }
  }, [aniplexDataErrors, focusError, needFocusError]);

  // ANIPLEX管理フォーム変更時のハンドラ
  const onAnxMngDataChange = useCallback((prop, value, suppressValidate) => {
    if (prop === 'process' && !suppressValidate) {
      // 入力不可の項目にエラーが残るのを避けるため
      // 処理内容が変更されたら全項目バリデートする
      setValidateAllFlg(true);
    }
    handleAniplexDataChange(prop, value, suppressValidate);
  }, [handleAniplexDataChange])

  /** エラーありフラグ */
  const hasError = useMemo(() => {
    return Object.values(aniplexDataErrors).flat().length > 0;
  }, [aniplexDataErrors]);

  useEffect(() => {
    if (royalty) {
      // 各種情報を反映する
      handleAniplexDataChange('ryReportId', royalty?.ryReportId, true);
      handleAniplexDataChange('updateDatetime', royalty?.updateDatetime, true);
    }
  }, [handleAniplexDataChange, royalty]);

  /** 企画書 */
  const proposalName = useMemo(() => {
    return (proposalDetail?.proposalNo ?? '') + ' '
      + (proposalDetail?.proposalTitle ?? '');
  }, [proposalDetail?.proposalNo, proposalDetail?.proposalTitle]);

  /** 取引先名称 */
  const licenseeName = useMemo(() => {
    return (royalty?.licenseeCode ?? '') + ' ' + (royalty?.licenseeNameKanji ?? '');
  }, [royalty?.licenseeCode, royalty?.licenseeNameKanji]);


  /** 対象期間 */
  const targetPeriod = useMemo(() => {
    return (royalty?.ryStartDate ?? '') + '～'
      + (royalty?.ryEndDate ?? '');
  }, [royalty?.ryEndDate, royalty?.ryStartDate]);

  /** 報告日 */
  const reportDate = useMemo(() => {
    if (isEmpty(royalty?.reportDatetime)) return '';
    return dayjs(royalty?.reportDatetime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD');
  }, [royalty?.reportDatetime]);

  /** 報告ステータス */
  const reportStatus = useMemo(() => {
    return Constants.Aniplex.reportStatusName[royalty?.reportStatus] ?? '';
  }, [royalty?.reportStatus]);

  /** 受領ボタン押下時のハンドラ */
  const onAcceptClick = useCallback(() => {
    // 強制バリデートをかけて受領アクションを設定
    setValidateAllFlg(true);
    setAction('accept');
  }, []);

  // アクションのハンドリング
  useEffect(() => {
    if (action === 'accept') {
      if (hasError) {
        // バリデートエラーがあるときはエラー項目にフォーカス
        setAction(null);
        setNeedFocusError(true);
        return;
      }

      setAcceptConfirm({
        showFlg: true,
        onClose: (btn) => {
          if (btn === 'ok') {
            // 売上送信API呼出し
            onAccept(aniplexData);
          }
          setAcceptConfirm({
            showFlg: false,
            onClose: null,
          });
        },
      });
    }
    setAction(null);
  }, [action, aniplexData, hasError, onAccept]);

  /** 差し戻しボタン押下時のハンドラ */
  const onRejectClick = useCallback(() => {
    setRejectPopup({
      showFlg: true,
      onClose: (btn, messageContent) => {
        if (btn === 'ok') {
          onReject(messageContent);
        }
        setRejectPopup({
          showFlg: false,
          onClose: null,
        });
      }
    });
  }, [onReject]);

  /** 印刷ボタン押下時のハンドラ */
  const onPrintClick = useCallback(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '82px' }}>企画書</dt>
          <dd className="form-body">
            <div className="input-form wdt1000">
              <input type="text" name="企画書"
                title="企画書を必ず入力してください"
                aria-label="企画書"
                disabled
                value={proposalName} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '82px' }}>対象期間</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="対象期間"
                title="対象期間は入力済みです"
                aria-label="対象期間"
                disabled
                value={targetPeriod} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ width: '115px' }}>取引先名称</dt>
          <dd className="form-body">
            <div className="input-form wdt300">
              <input type="text" name="取引先名称"
                aria-label="取引先名称"
                disabled
                value={licenseeName} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告日</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="報告日"
                title="報告日は入力済みです"
                aria-label="申請日"
                disabled
                value={reportDate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告者</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name='報告者'
                title='報告者は入力済みです'
                aria-label='報告者'
                disabled
                value={royalty?.reportUserName ?? ''} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name">ロイヤリティ報告No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="ロイヤリティ報告No"
                title="ロイヤリティ報告Noは入力済みです"
                aria-label="ロイヤリティ報告No"
                disabled
                value={royalty?.ryReportNo ?? ''} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">ロイヤリティ報告ステータス</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="ロイヤリティ報告ステータス"
                title="ロイヤリティ報告ステータスは入力済みです"
                aria-label="ロイヤリティ報告ステータス"
                disabled
                value={reportStatus} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '95px' }}>請求先</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <span className="attention" style={{ display: 'inline-block', paddingTop: '11px' }}>請求先に変更がある場合は、記載の変更をお願いします。</span>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: '110px' }}>
                郵便番号
                {
                  royalty?.previousVersion != null && (
                    <ValueChangeStatus
                      value={royalty.billingZipCode}
                      prevValue={royalty.previousVersion.billingZipCode} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt140">
                  <input
                    type="text"
                    name="billingZipCode"
                    aria-label="請求先郵便番号"
                    disabled
                    value={royalty?.billingZipCode ?? ''} />
                </div>
              </dd>
              <dt className="form-name required" style={{ marginLeft: '40px', width: '110px' }}>
                電話番号
                {
                  royalty?.previousVersion != null && (
                    <ValueChangeStatus
                      value={royalty.billingPhone}
                      prevValue={royalty.previousVersion.billingPhone} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input
                    type="text"
                    name="billingPhone"
                    aria-label="請求先電話番号"
                    disabled
                    value={royalty?.billingPhone ?? ''} />
                </div>
              </dd>
            </dl>
            <dl className="form-set">
              <dt className="form-name required" style={{ width: '110px' }}>
                住所
                {
                  royalty?.previousVersion != null && (
                    <ValueChangeStatus
                      value={royalty.billingAddress}
                      prevValue={royalty.previousVersion.billingAddress} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt1000">
                  <input
                    type="text"
                    name="billingAddress"
                    aria-label="請求先住所"
                    disabled
                    value={royalty?.billingAddress ?? ''} />
                </div>
              </dd>
            </dl>
            <dl className="form-set" style={{ marginBottom: '25px' }}>
              <dt className="form-name" style={{ width: '110px' }}>
                部署名
                {
                  royalty?.previousVersion != null && (
                    <ValueChangeStatus
                      value={royalty.billingDepartment}
                      prevValue={royalty.previousVersion.billingDepartment} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt1000">
                  <input
                    type="text"
                    name="billingDepartment"
                    aria-label="請求先部署名"
                    disabled
                    value={royalty?.billingDepartment ?? ''} />
                </div>
              </dd>
            </dl>
            <dl className="form-set" style={{ marginBottom: '25px' }}>
              <dt className="form-name" style={{ width: '110px' }}>
                担当者名
                {
                  royalty?.previousVersion != null && (
                    <ValueChangeStatus
                      value={royalty.billingName}
                      prevValue={royalty.previousVersion.billingName} />
                  )
                }
              </dt>
              <dd className="form-body">
                <div className="input-form wdt1000">
                  <input
                    type="text"
                    name="billingName"
                    aria-label="請求先担当者名"
                    disabled
                    value={royalty?.billingName ?? ''} />
                </div>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>

      <MessageHistoryArea
        royaltyDetail={royalty} />

      <RoyaltyProductsForm
        royalty={royalty}
        proposal={proposalDetail} />

      {
        royalty?.ryProofList?.length > 0 && (
          <RyProofDownloader
            royalty={royalty} />
        )
      }

      <RoyaltyStatusForm
        royalty={royalty}
        proposalDetail={proposalDetail} />

      <section className="mt40">
        {
          royalty != null && (
            <AniplexMngForm
              royalty={royalty}
              formData={aniplexData}
              formRefs={formRefs}
              handleChange={onAnxMngDataChange}
              errors={aniplexDataErrors}
              formLocked={formLocked}
              hasAuthority={canModifyUser}
              ryAmountPrice={ryAmountTotal}
              isZeroReport={isZeroReport} />
          )
        }

        <div className="mt30 pt30 dif j-between bt-solid-gray">
          <div className="l-buttons">
          {
            canModifyUser && (
              <>
                <p className="btn bg-yellow" style={{ width: '100px' }}>
                  <button
                    disabled={!canAccept || formLocked}
                    onClick={onAcceptClick}
                  >受領</button>
                </p>
                <p className="btn c-aniplex ml10" style={{ width: '100px' }}>
                  <button
                    disabled={!canReject || formLocked}
                    onClick={onRejectClick}
                  >差し戻し</button>
                </p>
              </>
            )
          }
          </div>
          <p className="btn c-aniplex" style={{ width: '100px' }}>
            <button
              disabled={formLocked || !royalty}
              onClick={onPrintClick}
            >印刷</button>
          </p>
        </div>
      </section>

      {
        acceptConfirm.showFlg && (
          <MessagePopup
            message='受領を行います。よろしいですか？'
            btn={{ ok: '受領', cancel: 'キャンセル' }}
            btnClass='bg-yellow'
            onClose={acceptConfirm.onClose} />
        )
      }

      {
        rejectPopup.showFlg ?
          <CommonRejectPopup onClose={rejectPopup.onClose} /> :
          null
      }

      <NotAcceptedAlert
        royalty={royalty}
        proposal={proposalDetail} />

      <ApiLoadingOverlay
        apiStatus={[
          apiStatus.fetchProposalDetail,
        ]} />
    </>
  );
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/royaltiesSlice').RoyaltyDetail} RoyaltyDetail ロイヤルティ報告詳細情報
 */
/**
 * @typedef {import('../../../slices/aniplex/proposalsSlice').ProposalDetail} ProposalDetail 企画詳細情報
 */
/**
 * @typedef {import ('../../../lib/api/aniplex').ExportRoyaltyParams} ExportRoyaltyParams 売上連携APIのパラメータ
 */
//#endregion typedef
