//@ts-check
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { statusListAniplex } from '../../common/headerList';
import { selectPropertyMst, selectAniplexUserMst } from "../../../slices/aniplex/masterSlice";
import { SearchableListBox } from "../../common/SearchableListBox";
import { Constants } from '../../../Constants';
import { useSaveFormData } from '../../../lib/hooks/common';

/**
 * 検索パラメーターの初期値
 * @type {SearchFormData}
 */
export const defaultParams = {
  propertySummaryCode: '',
  proposalStatus: '',
  applicantUserName: '',
  proposalNo: '',
  proposalTitle: '',
  applicationDateFrom: undefined,
  applicationDateTo: undefined,
  kikakuEmployeeNo: '',
  licenseeCode: '',
  licenseeNameKanji: '',
  sDecision: '',
  sTitle: '',
  startDateFrom: undefined,
  startDateTo: undefined,
  endDateFrom: undefined,
  endDateTo: undefined,
  ryRemindFlag: '',
  filterCondition: 'ALL', // クリア時はALL
};

/**
 * クリア時の検索パラメータの値
 * @type {SearchFormData}
 */
const clearedParams = {
  ...defaultParams,
  filterCondition: 'PSO',
}

/**
 * 日付型のパラメータ
 * @satisfies {(keyof SearchFormData)[]}
 */
export const dateParams = [
  'applicationDateFrom',
  'applicationDateTo',
  'startDateFrom',
  'startDateTo',
  'endDateFrom',
  'endDateTo',
];

/**
 * 企画書一覧の検索フォーム(アニプレックス側)
 * @param {object} props
 * @param {(param: SearchFormData) => void} props.onSearch 検索実行時のコールバック
 * @param {SearchFormData} props.params 検索フォームデータ
 * @param {React.Dispatch<React.SetStateAction<SearchFormData>>} props.setParams 検索フォームデータ設定処理
 */
export const ProposalListSearchForm = ({
  onSearch,
  params,
  setParams
}) => {

  // 検索パラメータ保存機能
  const { saveData } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.ProposalList,
    dataType: params,
    dateParams,
  });

  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertyMst);

  /** 作品マスタ(申請可能) */
  const propertyMstShown = useMemo(() => {
    if (!propertyMst) {
      return propertyMst;
    }
    return propertyMst.filter(property => {
      return property.proposalFlag;
    });
  }, [propertyMst]);

  /** ANIPLEXユーザマスタ */
  const userMst = useSelector(selectAniplexUserMst);

  /** ANIPLEXユーザマスタから氏名ありのものを抽出 */
  const userMstFiltered = useMemo(() => {
    if (!userMst) {
      return userMst;
    }
    return userMst.filter(user => user.employeeName);
  }, [userMst]);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, [setParams]);

  /** 作品・ユーザーの変更時 */
  const onPropertyChange = useCallback((name, value) => {
    setParams(params => ({ ...params, [name]: value }));
  }, [setParams]);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, [setParams]);

  /** 選択された作品 */
  const selectedProperty = useMemo(() => {
    const found = propertyMstShown.find(i => i.propertySummaryCode === params.propertySummaryCode);
    if (!found) {
      return null;
    }
    return {
      label: found.propertySummaryName,
      value: found.propertySummaryCode,
    };
  }, [propertyMstShown, params]);

  /** 選択されたユーザー */
  const selectedUser = useMemo(() => {
    const found = userMstFiltered.find(i => i.employeeNo === params.kikakuEmployeeNo);
    if (!found) {
      return null;
    }
    return {
      label: found.employeeName,
      value: found.employeeNo,
    };
  }, [userMstFiltered, params]);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    // 現在のパラメータを保存
    saveData(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefined・日付はフォーマットする)
    onSearch(params);
  }, [saveData, params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    const params = {...clearedParams};
    setParams(params);
  }, [setParams]);

  return (
    <>
      <div className="l-form mt30">

        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px' }}>作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt650">
              {/* @ts-expect-error */}
              <SearchableListBox
                //@ts-expect-error
                isDisabled={false}
                value={selectedProperty}
                data={propertyMstShown}
                onChange={item => onPropertyChange('propertySummaryCode', item?.value)}
                labelKey='propertySummaryName'
                valueKey='propertySummaryCode'
                katakanaKey='propertySummaryNameKana'
                hankakuKanaKey='propertySummaryNameHankakuKana'
                hiraganaKey='propertySummaryNameHiragana' />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画申請ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt160">
              <select name="proposalStatus" title="企画申請ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.proposalStatus}>
                <option value=""></option>
                {Object.keys(statusListAniplex).map(i => (
                  <option value={i} key={i}>{statusListAniplex[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">申請者</dt>
            <dd className="form-body">
              <div className="input-form wdt140">
                <input type="text" name="applicantUserName" title="申請者を入力してください" aria-label="申請者"
                  value={params.applicantUserName}
                  onInput={onParamUpdate} />
              </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px' }}>企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="proposalNo" title="企画書Noを入力してください" aria-label="企画書No"
                value={params.proposalNo}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="proposalTitle" title="企画件名を入力してください" aria-label="企画件名"
                value={params.proposalTitle}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>
      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px'}}>申請日</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateFrom"
                title="申請開始日を入力してください"
                aria-label="申請開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateFrom}
                onChange={date => onDateChange('applicationDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="applicationDateTo"
                title="申請終了日を入力してください"
                aria-label="申請終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.applicationDateTo}
                onChange={date => onDateChange('applicationDateTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画担当者</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              {/* @ts-expect-error */}
              <SearchableListBox
                //@ts-expect-error
                isDisabled={false}
                value={selectedUser}
                data={userMstFiltered || []}
                onChange={item => onPropertyChange('kikakuEmployeeNo', item?.value)}
                labelKey='employeeName'
                valueKey='employeeNo' />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px' }}>S決裁No</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="sDecision" title="S決裁Noを入力してください" aria-label="S決裁No"
                value={params.sDecision}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">S決裁件名</dt>
          <dd className="form-body">
            <div className="input-form wdt300">
              <input type="text" name="sTitle" title="S決裁件名を入力してください" aria-label="S決裁件名"
                value={params.sTitle}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px' }}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="licenseeCode" title="取引コードを入力してください" aria-label="取引先コード"
                value={params.licenseeCode}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt300">
              <input type="text" name="licenseeNameKanji" title="取引先名を入力してください" aria-label="取引先名"
                value={params.licenseeNameKanji}
                onChange={onParamUpdate}
              />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: '140px' }}>許諾開始日</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div className="input-form input-calendar">
              <DatePicker
                name="startDateFrom"
                title="許諾開始日スタートを入力してください"
                aria-label="許諾開始日スタート"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.startDateFrom}
                onChange={date => onDateChange('startDateFrom', date)} />
            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="startDateTo"
                title="許諾開始日ストップを入力してください"
                aria-label="許諾開始日ストップ"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.startDateTo}
                onChange={date => onDateChange('startDateTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">許諾終了日</dt>
          <dd className="form-body" style={{ display: 'flex' }}>
            <div className="input-form input-calendar">

              <DatePicker
                name="endDateFrom"
                title="許諾終了日スタートを入力してください"
                aria-label="許諾終了日スタート"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.endDateFrom}
                onChange={date => onDateChange('endDateFrom', date)} />

            </div>
            <p className="calendar-period">〜</p>
            <div className="input-form input-calendar">
              <DatePicker
                name="endDateTo"
                title="許諾終了日ストップを入力してください"
                aria-label="許諾終了日ストップ"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.endDateTo}
                onChange={date => onDateChange('endDateTo', date)} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name" style={{ width: "140px" }}>ロイヤリティ督促</dt>
          <dd className="form-body">
            <div className="form-select wdt200">
              <select name="ryRemindFlag" title="ロイヤリティ督促を入力してください"
                value={params.ryRemindFlag}
                onChange={onParamUpdate}>
                <option value=""></option>
                <option value="true">督促中</option>
              </select>
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: "140px" }}>絞り込み</dt>
          <dd className="form-body">
            <ul className="list-form df">
              <li>
                <input
                  id="filterConditionALL"
                  type="radio"
                  name="filterCondition"
                  aria-label="すべて"
                  value="ALL"
                  checked={params.filterCondition === 'ALL'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionALL" className="form-radio">すべて</label>
              </li>
              <li>
                <input
                  id="filterConditionPSO"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当作品のみ表示"
                  value="PSO"
                  checked={params.filterCondition === 'PSO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPSO" className="form-radio">自分の担当作品のみ表示</label>
              </li>
              <li>
                <input
                  id="filterConditionPP0"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当企画のみ表示"
                  value="PPO"
                  checked={params.filterCondition === 'PPO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPP0" className="form-radio">自分の担当企画のみ表示</label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt15">
        <p className="btn bg-yellow" style={{ width: '160px' }}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn c-aniplex" style={{ width: '160px' }}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームデータ
 * @property {string} propertySummaryCode 作品コード
 * @property {Exclude<valueOf<Constants['Aniplex']['ProposalStatus']>, 'DEL'>|''} proposalStatus 企画申請ステータス（ANIPLEX表示）
 * TMP: 修正中
 * CAN: 取り下げ
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 承認済み
 * PER: 更新許可
 * SUS: 中止
 * FNR: 契約終了（未報告）
 * FIN: 契約終了
 * @property {string} applicantUserName 申請者氏名※部分一致検索
 * @property {string} proposalNo 企画No※部分一致検索
 * @property {string} proposalTitle 企画件名※部分一致検索
 * @property {Date|undefined|null} applicationDateFrom 申請日From
 * @property {Date|undefined|null} applicationDateTo 申請日To
 * @property {string} kikakuEmployeeNo 企画担当者社員番号
 * @property {string} licenseeCode 取引先コード※部分一致検索
 * @property {string} licenseeNameKanji 取引先名（漢字）※部分一致検索
 * @property {string} sDecision S決裁No※部分一致検索
 * @property {string} sTitle S決裁件名※部分一致検索
 * @property {Date|undefined|null} startDateFrom 許諾開始日From
 * @property {Date|undefined|null} startDateTo 許諾開始日To
 * @property {Date|undefined|null} endDateFrom 許諾終了日From
 * @property {Date|undefined|null} endDateTo 許諾終了日To
 * @property {''|'true'} ryRemindFlag ロイヤリティ報告督促フラグ
 * @property {'ALL'|'PSO'|'PPO'} filterCondition 絞り込み条件
 * ALL: すべて
 * PSO: 自分の担当作品のみ表示
 * PPO: 自分の担当企画のみ表示
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
