//@ts-check
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProposalsAll, getProposal, patchProposal } from "../../lib/api/aniplex";
import { ApiErrorMessage } from "../../lib/message";
import { handleAniplexApiError } from "../../lib/util";

/** 初期値 */
const initialState = {
  /** @type {{result?: { data?: Proposal[] }}|null} */
  fetchAll: null,
  /** @type {ProposalDetail|null} 取得した企画詳細情報 */
  proposalDetail: null,
  /**
   * API通信ステータス
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** 企画一覧 */
    fetchAll: null,
    /** 企画詳細取得処理 */
    fetchProposalDetail: null,
    /** 企画担当者更新処理 */
    updateKikakuEmployeeNo: null,
    /** S決裁No更新処理 */
    updateSDecisionNo: null,
    /** 契約期間変更処理 */
    updateContractPeriod: null,
    /** 自動契約更新制御フラグ設定処理 */
    setAutoUpdateContractFlag: null,
    /** 企画ステータス更新処理 */
    updateProposalStatus: null,
    /** 輸入許諾書URL更新処理 */
    updateImportLicenseUrl: null,
    /** ロイヤリティ報告督促要否更新処理 */
    updateRyRemindReq: null,
  }
};

/**
 * 企画情報一覧取得
 */
export const fetchAll = createAsyncThunk(
  'aniplex/proposals/fetchAll',
  /** @param {*} params */
  async (params, { dispatch }) => {
    try {
      const data = await getProposalsAll(params);
      return data;
    } catch (err) {
      // エラーが発生したらキャッシュ済みの結果をクリアする
      dispatch(clearFetchAll());
      handleAniplexApiError(err, dispatch, {
        '10101': ApiErrorMessage.TooManyResult,
      });
    }
  }
)

/**
 * 企画詳細取得処理
 */
export const fetchProposalDetail = createAsyncThunk(
  'aniplex/proposals/fetchProposalDetail',
  /**
   * 企画詳細取得処理
   * @param {number} proposalId 企画内部コード
   * @returns
   */
  async (proposalId, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearProposalDetail());

    try {
      const data = await getProposal(proposalId);
      return data;
    } catch (err) {
      handleAniplexApiError(err, thunkApi.dispatch, {
        // データ不正
        '10201': ApiErrorMessage.DataNotExists,
      });
    }
  }
)

/**
 * 企画担当者更新処理
 */
export const updateKikakuEmployeeNo = createAsyncThunk(
  'aniplex/proposals/updateKikakuEmployeeNo',
  /**
   * 企画担当者更新処理
   * @param {object} args
   * @param {number} args.proposalId 企画内部コード
   * @param {string} args.updateDatetime 企画の最終更新日時
   * @param {string} args.employeeNo 社員番号
   */
  async ({proposalId, updateDatetime, employeeNo}, {dispatch}) => {
    /** @type {import('../../lib/api/aniplex').PatchProposalParams} */
    const params = {
      kikakuEmployeeNo: employeeNo,
      updateDatetime,
    };

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * S決裁No更新処理
 */
export const updateSDecisionNo = createAsyncThunk(
  'aniplex/proposals/updateSDecisionNo',
  /**
   * S決裁No更新処理
   * @param {object} args
   * @param {number} args.proposalId 企画内部コード
   * @param {string} args.updateDatetime 企画の最終更新日時
   * @param {string} args.sDecisionNo S決裁No
   */
  async ({ proposalId, updateDatetime, sDecisionNo }, { dispatch }) => {
    /** @type {import('../../lib/api/aniplex').PatchProposalParams} */
    const params = {
      sDecisionNo,
      updateDatetime,
    };

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * 契約期間変更処理
 */
export const updateContractPeriod = createAsyncThunk(
  'aniplex/proposals/updateContractPeriod',
  /**
   * 契約期間変更処理
   * @param {object} args
   * @param {number} args.proposalId 企画内部コード
   * @param {UpdateContractPeriodParam} args.params 更新データ
   */
  async ({proposalId, params}, { dispatch }) => {
    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * 自動契約更新制御フラグ設定処理
 */
export const setAutoUpdateContractFlag = createAsyncThunk(
  'aniplex/proposals/setAutoUpdateContractFlag',
  /**
   * 自動契約更新制御フラグ設定処理
   * @param {object} args
   * @param {number} args.proposalId 企画内部コード
   * @param {string} args.updateDatetime 最終更新日時
   * @param {boolean} args.automaticContractUpdateFlag 自動契約更新制御フラグ
   */
  async ({ proposalId, updateDatetime, automaticContractUpdateFlag }, { dispatch }) => {
    const params = {
      automaticContractUpdateFlag,
      updateDatetime,
    };

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
);

/**
 * 企画ステータス更新処理
 */
export const updateProposalStatus = createAsyncThunk(
  'aniplex/proposals/updateProposalStatus',
  /**
   * 企画ステータス更新処理
   * @param {object} params
   * @param {number} params.proposalId 対象の企画内部コード
   * @param {'REJ'|'APP'|'SUS'|'FIN'|'DEL'} params.proposalStatus 申請ステータス
   * @param {string} params.updateDatetime 最終更新日時
   * @param {string} [params.messageContent] メッセージ内容
   *
   */
  async ({ proposalId, proposalStatus, updateDatetime, messageContent }, { dispatch }) => {
    /** @type {import('../../lib/api/aniplex').PatchProposalParams} */
    const params = {
      proposalStatus,
      updateDatetime,
      messageContent,
    };

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * 輸入許諾書URL更新処理
 */
export const updateImportLicenseUrl = createAsyncThunk(
  'aniplex/proposals/updateImportLicenseUrl',
  /**
   * 輸入許諾書URL更新
   * @param {object} params
   * @param {number} params.proposalId 企画内部コード
   * @param {string} params.updateDatetime 最終更新日時
   * @param {string} params.importLicenseUrl 輸入許諾書URL
   * @returns
   */
  async({proposalId, updateDatetime, importLicenseUrl}, { dispatch }) => {
    const params = {
      importLicenseUrl: importLicenseUrl,
      updateDatetime: updateDatetime
    }

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * ロイヤリティ報告督促要否更新処理
 */
export const updateRyRemindReq = createAsyncThunk(
  'aniplex/proposals/updateRyRemindReq',
  /**
   * ロイヤリティ報告督促要否更新
   * @param {object} params
   * @param {number} params.proposalId 企画内部コード
   * @param {string} params.updateDatetime 最終更新日時
   * @param {Boolean} params.ryRemindReq ロイヤリティ報告督促要否
   * @returns
   */
  async({proposalId, updateDatetime, ryRemindReq}, { dispatch }) => {
    const params = {
      ryRemindReq: Boolean(ryRemindReq),
      updateDatetime: updateDatetime
    }

    try {
      const result = await patchProposal(proposalId, params);
      return result;
    } catch (err) {
      handlePatchProposalError(err, dispatch);
    }
  }
)

/**
 * ANIPLEX向け画面の企画スライス
 */
export const proposalsSlice = createSlice({
  name: 'aniplex/proposals',
  initialState,
  reducers: {
    /**
     * 取得済みの企画一覧情報をクリアする
     * @param {typeof initialState} state
     */
    clearFetchAll: (state) => {
      state.fetchAll = null;
    },
    /**
     * 取得済みの企画詳細情報をクリアする
     * @param {typeof initialState} state
     */
    clearProposalDetail: (state) => {
      state.proposalDetail = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // 企画情報一覧取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // 企画詳細取得処理
        case 'fetchProposalDetail':
          state.apiStatus.fetchProposalDetail = null;
          break;
        // 企画担当者更新処理
        case 'updateKikakuEmployeeNo':
          state.apiStatus.updateKikakuEmployeeNo = null;
          break;
        // S決裁No更新処理
        case 'updateSDecisionNo':
          state.apiStatus.updateSDecisionNo = null;
          break;
        // 契約期間変更処理
        case 'updateContractPeriod':
          state.apiStatus.updateContractPeriod = null;
          break;
        // 自動契約更新制御フラグ設定処理
        case 'setAutoUpdateContractFlag':
          state.apiStatus.setAutoUpdateContractFlag = null;
          break;
        // 企画ステータス更新処理
        case 'updateProposalStatus':
          state.apiStatus.updateProposalStatus = null;
          break;
        // 輸入許諾書URL更新処理
        case 'updateImportLicenseUrl':
          state.apiStatus.updateImportLicenseUrl = null;
          break;
        // ロイヤリティ報告督促要否更新処理
        case 'updateRyRemindReq':
          state.apiStatus.updateRyRemindReq = null;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // 企画情報一覧取得処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // 企画詳細取得処理
      .addCase(fetchProposalDetail.pending, (state) => {
        state.apiStatus.fetchProposalDetail = 'loading';
      })
      .addCase(fetchProposalDetail.fulfilled, (state, action) => {
        state.apiStatus.fetchProposalDetail = 'finish';
        state.proposalDetail = action.payload;
      })
      .addCase(fetchProposalDetail.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchProposalDetail = null;
      })
      // 企画担当者更新処理
      .addCase(updateKikakuEmployeeNo.pending, (state) => {
        state.apiStatus.updateKikakuEmployeeNo = 'loading';
      })
      .addCase(updateKikakuEmployeeNo.fulfilled, (state) => {
        state.apiStatus.updateKikakuEmployeeNo = 'finish';
      })
      .addCase(updateKikakuEmployeeNo.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateKikakuEmployeeNo = null;
      })
      // S決裁No更新処理
      .addCase(updateSDecisionNo.pending, (state) => {
        state.apiStatus.updateSDecisionNo = 'loading';
      })
      .addCase(updateSDecisionNo.fulfilled, (state, action) => {
        state.apiStatus.updateSDecisionNo = 'finish';
      })
      .addCase(updateSDecisionNo.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateSDecisionNo = null;
      })
      // 契約期間変更処理
      .addCase(updateContractPeriod.pending, (state) => {
        state.apiStatus.updateContractPeriod = 'loading';
      })
      .addCase(updateContractPeriod.fulfilled, (state) => {
        state.apiStatus.updateContractPeriod = 'finish';
      })
      .addCase(updateContractPeriod.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateContractPeriod = null;
      })
      // 自動契約更新制御フラグ設定処理
      .addCase(setAutoUpdateContractFlag.pending, (state) => {
        state.apiStatus.setAutoUpdateContractFlag = 'loading';
      })
      .addCase(setAutoUpdateContractFlag.fulfilled, (state) => {
        state.apiStatus.setAutoUpdateContractFlag = 'finish';
      })
      .addCase(setAutoUpdateContractFlag.rejected, (state) => {
        state.apiStatus.setAutoUpdateContractFlag = null;
      })
      // 企画ステータス更新処理
      .addCase(updateProposalStatus.pending, (state) => {
        state.apiStatus.updateProposalStatus = 'loading';
      })
      .addCase(updateProposalStatus.fulfilled, (state) => {
        state.apiStatus.updateProposalStatus = 'finish';
      })
      .addCase(updateProposalStatus.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateProposalStatus = null;
      })
      // 輸入許諾書URL更新処理
      .addCase(updateImportLicenseUrl.pending, (state) => {
        state.apiStatus.updateImportLicenseUrl = 'loading';
      })
      .addCase(updateImportLicenseUrl.fulfilled, (state) => {
        state.apiStatus.updateImportLicenseUrl = 'finish';
      })
      .addCase(updateImportLicenseUrl.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateImportLicenseUrl = null;
      })
      // ロイヤリティ報告督促要否更新処理
      .addCase(updateRyRemindReq.pending, (state) => {
        state.apiStatus.updateRyRemindReq = 'loading';
      })
      .addCase(updateRyRemindReq.fulfilled, (state) => {
        state.apiStatus.updateRyRemindReq = 'finish';
      })
      .addCase(updateRyRemindReq.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.updateRyRemindReq = null;
      });

  }
});

export const {
  clearFetchAll,
  clearProposalDetail,
  clearApiStatus,
} = proposalsSlice.actions;

export default proposalsSlice.reducer;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.proposals.apiStatus;

/**
 * 企画一覧を取得する
 * @param {*} state
 * @returns {{result?: { data?: Proposal[] }}|null}
 */
export const fetchAllProposals = (state) => state.aniplex.proposals.fetchAll;

/**
 * stateから企画詳細情報を取得する
 * @param {*} state
 * @returns {ProposalDetail|null} 企画詳細情報
 */
export const selectProposalDetail = (state) => state.aniplex.proposals.proposalDetail;

/**
 * 企画更新APIで発生したエラーのハンドリング
 * @param {*} err 処理対象のエラー
 * @param {import("@reduxjs/toolkit").ThunkDispatch} dispatch redux-thunkのディスパッチャ
 * @returns {never}
 */
function handlePatchProposalError(err, dispatch) {
  handleAniplexApiError(err, dispatch, {
    // データ不正
    '10301': ApiErrorMessage.UpdateStateMismatch,
    // 更新状態不一致
    '10302': ApiErrorMessage.UpdateStateMismatch,
    // 契約終了日不正
    '10303': ApiErrorMessage.InvalidContractEndDate,
    // 履歴登録失敗
    '10304': ApiErrorMessage.SystemError,
    // 企画書削除失敗
    '10305': '承認済みの証紙申請、ロイヤリティ報告が存在するため、企画を削除できません。',
    // メッセージ登録失敗
    '10306': ApiErrorMessage.MessageRegisterFailed,
  });
}

//#region typedef
/**
 * @typedef {'fetchAll'|'fetchProposalDetail'|'updateKikakuEmployeeNo'|'updateSDecisionNo'|'updateProposalStatus'|
 * 'updateImportLicenseUrl'|'updateRyRemindReq'|'updateProposalStatus'|'updateContractPeriod'|'setAutoUpdateContractFlag'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {object} Proposal 企画情報
 * @property {number} proposalId 企画内部コード
 * @property {string} iconFilename アイコンファイル名
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {Exclude<valueOf<Constants['Aniplex']['ProposalStatus']>, 'DEL'>} proposalStatus 企画申請ステータス（ANIPLEX表示）
 * TMP: 修正中
 * CAN: 取り下げ
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 承認済み
 * PER: 更新許可
 * SUS: 中止
 * FNR: 契約終了（未報告）
 * FIN: 契約終了
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {string} kikakuEmployeeNo 企画担当者社員番号
 * @property {string} kikakuEmployeeName 企画担当者氏名
 * @property {string} sDecisionNo S決裁No
 * @property {string} sTitle 件名
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} startDate 許諾期間開始(YYYY/MM/DD)
 * @property {string} endDate 許諾期間終了(YYYY/MM/DD)
 * @property {boolean} ryRemindFlag ロイヤリティ報告督促フラグ
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ProposalDetail 企画詳細
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {number} revision 版数
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {'CAN'|'MOD'|'REQ'|'REJ'|'APP'|'PER'|'SUS'|'FIN'} proposalStatus 申請ステータス
 * @property {string} propertySummaryCode 作品コード
 * @property {string} proposalTitle 企画件名
 * @property {string} proposalLaunchDate 発売開始希望日(YYYY/MM/DD)
 * @property {string} proposalDetail 企画説明
 * @property {string} proposalRemarks 備考
 * @property {string} contractStartDate 契約開始日(YYYY/MM/DD)
 * @property {string} contractEndDate 契約終了日(YYYY/MM/DD)
 * @property {boolean} ryReq1 ロイヤリティ報告締め月_1月
 * @property {boolean} ryReq2 ロイヤリティ報告締め月_2月
 * @property {boolean} ryReq3 ロイヤリティ報告締め月_3月
 * @property {boolean} ryReq4 ロイヤリティ報告締め月_4月
 * @property {boolean} ryReq5 ロイヤリティ報告締め月_5月
 * @property {boolean} ryReq6 ロイヤリティ報告締め月_6月
 * @property {boolean} ryReq7 ロイヤリティ報告締め月_7月
 * @property {boolean} ryReq8 ロイヤリティ報告締め月_8月
 * @property {boolean} ryReq9 ロイヤリティ報告締め月_9月
 * @property {boolean} ryReq10 ロイヤリティ報告締め月_10月
 * @property {boolean} ryReq11 ロイヤリティ報告締め月_11月
 * @property {boolean} ryReq12 ロイヤリティ報告締め月_12月
 * @property {number} shareWeb WEB通販比率
 * @property {number} shareWholesale 卸売比率
 * @property {boolean} shareOwnEcFlag 自社EC有無
 * @property {number} shareOwnEc Web通販比率_自社EC
 * @property {number} shareOtherEc Web通販比率_他社EC
 * @property {number} shareAnimation 卸売比率_アニメ専門店
 * @property {number} shareKaden 卸売比率_家電量販店
 * @property {number} shareCvs 卸売比率_CVS
 * @property {number} shareGms 卸売比率_GMS
 * @property {number} shareSm 卸売比率_スーパーマーケット
 * @property {number} shareDrug 卸売比率_ドラッグストア
 * @property {number} shareHobby 卸売比率_ホビー
 * @property {number} shareOnline 卸売比率_オンライン（くじ、プライズ、電子書籍）
 * @property {number} shareOther 卸売比率_その他
 * @property {string} shareWholesaleRemarks 卸売備考（流通先名、その他内訳）
 * @property {string} target メイン・ターゲット
 * @property {string} promotionChannel 対流通施策
 * @property {string} promotionUser 対ユーザー施策
 * @property {string} promotionWeb 対WEB施策
 * @property {string} promotionTwitter Twitter情報
 * @property {string} promotionDisplay 展示会・カタログ情報
 * @property {ProposalPeriod[]} periodList 第N期のリスト
 * @property {ProposalProduct[]} productList 許諾商品のリスト
 * @property {ProposalAttachment[]} proposalAttachmentList 企画添付ファイル（提案資料等）のリスト
 * @property {ProposalSDecision} [sDecision] S決裁情報
 * @property {string} kikakuEmployeeNo 企画担当者社員番号
 * @property {string} kikakuEmployeeName 企画担当者氏名
 * @property {string} importLicenseUrl 輸入許諾書URL
 * @property {string} applicationDatetime 申請日時(YYYY/MM/DD)
 * @property {string} approvalDatetime 承認日時(YYYY/MM/DD)
 * @property {string} updateDatetime 最終更新日時
 * @property {string} [rejectMessage] 差し戻し理由
 * @property {string} [permissionMessage] 更新許可理由
 * @property {string} [cancelMessage] 取り下げ理由
 * @property {string} [suspendMessage] 中止理由
 * @property {boolean} ryRemindFlag ロイヤリティ報告督促フラグ
 * @property {boolean} ryRemindReq ロイヤリティ報告督促要否
 * @property {boolean} automaticContractUpdateReq 自動契約更新リクエスト
 * @property {boolean} automaticContractUpdateFlag 自動契約更新制御フラグ
 * @property {valueOf<Constants['Aniplex']['RyReportCategory']>} ryReportCategory ロイヤリティ報告区分 MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 * @property {MessageHistory[]} messageHistoryList 連絡事項のリスト
 * @property {ProposalPreviousVersion} [previousVersion] 前バージョンの企画書情報
 */
/**
 * @typedef {object} ProposalPeriod 企画情報内の第N期の情報
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {number} [ryReportId] ロイヤリティ報告書内部コード
 * @property {valueOf<Constants['Aniplex']['reportStatus']>} [reportStatus] ロイヤリティ報告ステータス
 * TMP: 一時保存
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 申請完了
 * EXP: 申請完了
 */
/**
 * @typedef {object} ProposalProduct 企画情報内の許諾商品情報
 * @property {number} productId 許諾商品内部コード
 * @property {string} productNo 品番
 * @property {string} productName 商品名
 * @property {'REG'|'APP'|'TMP'|'OK'|'SUS'} productStatus 許諾商品ステータス
 * @property {number} categoryNo 商品カテゴリーNo
 * @property {number} categoryDetailNo 商品カテゴリー詳細No
 * @property {string} character キャラクター
 * @property {string} version バージョン
 * @property {string} launchDate 発売希望日
 * @property {string} rypId ロイヤリティ報告パターンID
 * @property {number} planPrice 予定上代
 * @property {number} planCost 予定製造原価
 * @property {number} planProceeds 予定売上金額
 * @property {number} planRyAmount 予定ロイヤリティ金額
 * @property {number} ryRate ロイヤリティ料率
 * @property {number} ryPrice ロイヤリティ単価
 * @property {number} calPlanProceeds 予定売上金額
 * @property {number} calPlanRyTarget 予定ロイヤリティ対象金額
 * @property {number} calPlanRyAmount 予定ロイヤリティ金額
 * @property {number} latestPrice 最新上代
 * @property {number} latestCost 最新製造原価
 * @property {number} calResultProceeds 予定販売金額
 * @property {number} calResultRyTarget 予定ロイヤリティ対象金額
 * @property {number} calResultRyAmount 予定ロイヤリティ金額
 * @property {'1'|'2'} salesMethod 販売方式
 * @property {'1'|'2'} productMethod 生産方式
 * @property {string} material 素材他仕様
 * @property {string} characterLineup キャラクターラインナップ
 * @property {string} productRemarks 備考
 * @property {string} productStatusUpdateDatetime 許諾商品ステータス変更日時
 * @property {string} productOption1 自由記入欄1
 * @property {string} productOption2 自由記入欄2
 * @property {string} productOption3 自由記入欄3
 * @property {ProposalProductPeriod[]} periodList 第N期のリスト
 * @property {object[]} renderingImageList 商品イメージのリスト
 * @property {string} renderingImageList.renderingImageNo 商品イメージNo
 * @property {string} sampleDate サンプル送付日(YYYY/MM/DD)
 * @property {string} sampleRegDatetime サンプル送付登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} productImageUrl 完成イメージURL
 * @property {string} productImageRegDatetime 完成イメージ登録日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} ProposalProductPeriod 企画情報内の商品情報内の第N期の情報
 * @property {number} period 第N期
 * @property {number} planProduction 予定生産数
 * @property {number} planSales 予定販売数
 * @property {number} resultLabel 証紙発行数
 * @property {number} resultProduction 実績生産数
 * @property {number} resultSales 実績販売数
 */
/**
 * @typedef {object} ProposalAttachment 添付ファイル
 * @property {number} proposalAttachmentNo 企画添付ファイルNo
 * @property {string} proposalAttachmentFilepath 企画添付ファイルファイルパス
 */
/**
 * @typedef {object} ProposalSDecision 企画情報内のS決裁情報
 * @property {string} sDecisionNo S決裁No
 * @property {string} sTitle 件名
 * @property {string} startDate 許諾期間開始(YYYY/MM/DD)
 * @property {string} endDate 許諾期間終了(YYYY/MM/DD)
 * @property {string} sLaunchDate 発売予定日
 * @property {string} kyodakukin 許諾金
 * @property {number} mg MG
 * @property {number} mgZan MG残高
 * @property {number} ad アドバンス
 * @property {number} adZan アドバンス残高
 * @property {string} kyodakuseizobutsu 商品（許諾製造物（商品））
 * @property {string} keizaijoken 対価（経済条件）
 * @property {string} selloff セルオフ
 * @property {string} category 商品カテゴリ名
 * @property {string} sTantousya S決裁担当者名
 * @property {string} keiyakuDate 契約締結日(YYYY/MM/DD)
 * @property {string} propertyCode 作品コード
 * @property {string} propertyName 作品名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeName 許諾先名
 * @property {number} proposalCount S決済情報に紐づく企画書の件数
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'CAN'|'PER'|'REJ'|'SUS'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * CAN: 取り下げ
 * PER: 更新
 * REJ: 差し戻し
 * SUS: 中止
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {'ANX'|'LCS'} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
/**
 * @typedef {object} ProposalPreviousVersion 前バージョンの企画書情報
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} applicantUserId 申請者ユーザーID
 * @property {string} applicantUserName 申請者氏名
 * @property {string} propertySummaryCode 作品コード
 * @property {string} proposalTitle 企画件名
 * @property {string} proposalLaunchDate 発売開始希望日
 * @property {string} proposalDetail 企画説明
 * @property {string} proposalRemarks 備考
 * @property {string} contractStartDate 契約開始日
 * @property {string} contractEndDate 契約終了日
 * @property {boolean} ryReq1 ロイヤリティ報告締め月_1月
 * @property {boolean} ryReq2 ロイヤリティ報告締め月_2月
 * @property {boolean} ryReq3 ロイヤリティ報告締め月_3月
 * @property {boolean} ryReq4 ロイヤリティ報告締め月_4月
 * @property {boolean} ryReq5 ロイヤリティ報告締め月_5月
 * @property {boolean} ryReq6 ロイヤリティ報告締め月_6月
 * @property {boolean} ryReq7 ロイヤリティ報告締め月_7月
 * @property {boolean} ryReq8 ロイヤリティ報告締め月_8月
 * @property {boolean} ryReq9 ロイヤリティ報告締め月_9月
 * @property {boolean} ryReq10 ロイヤリティ報告締め月_10月
 * @property {boolean} ryReq11 ロイヤリティ報告締め月_11月
 * @property {boolean} ryReq12 ロイヤリティ報告締め月_12月
 * @property {number} shareWeb WEB通販比率
 * @property {number} shareWholesale 卸売比率
 * @property {boolean} shareOwnEcFlag 自社EC有無
 * @property {number} shareOwnEc Web通販比率_自社EC
 * @property {number} shareOtherEc Web通販比率_他社EC
 * @property {number} shareAnimation 卸売比率_アニメ専門店
 * @property {number} shareKaden 卸売比率_家電量販店
 * @property {number} shareCvs 卸売比率_CVS
 * @property {number} shareGms 卸売比率_GMS
 * @property {number} shareSm 卸売比率_スーパーマーケット
 * @property {number} shareDrug 卸売比率_ドラッグストア
 * @property {number} shareHobby 卸売比率_ホビー
 * @property {number} shareOnline 卸売比率_オンライン（くじ、プライズ、電子書籍）
 * @property {number} shareOther 卸売比率_その他
 * @property {string} shareWholesaleRemarks 卸売備考（流通先名、その他内訳）
 * @property {string} target メイン・ターゲット
 * @property {string} promotionChannel 対流通施策
 * @property {string} promotionUser 対ユーザー施策
 * @property {string} promotionWeb WEB対策
 * @property {string} promotionTwitter Twitter情報
 * @property {string} promotionDisplay 展示会・カタログ情報
 * @property {'MON'|'SPE'|'ANY'} ryReportCategory ロイヤリティ報告区分.MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 * @property {valueOf<Constants['Aniplex']['RyReportCategory']>} ryReportCategory ロイヤリティ報告区分 MON: 毎月報告, SPE: 指定月報告, ANY: 任意
 * @property {ProposalPeriod[]} periodList 第N期のリスト
 * @property {ProposalProduct[]} productList 許諾商品のリスト
 * @property {ProposalAttachment[]} proposalAttachmentList 企画添付ファイル（提案資料等）のリスト
 * @property {boolean} automaticContractUpdateReq 自動契約更新リクエスト
 */
/**
 * @typedef {import('../../lib/api/aniplex').PatchProposalParams} PatchProposalParams
 */
/**
 * @typedef {Required<Pick<PatchProposalParams, 'contractEndDate'|'ryReportCategory'|'updateDatetime'|'ryReq1'|'ryReq2'|'ryReq3'|'ryReq4'|'ryReq5'|'ryReq6'|'ryReq7'|'ryReq8'|'ryReq9'|'ryReq10'|'ryReq11'|'ryReq12'>>} UpdateContractPeriodParam 契約期間変更処理のパラメータ
 */
/**
 * @typedef {object} updateProductStatusParam 許諾商品ステータス更新パラメータ
 * @property {number} proposalId 企画内部コード
 * @property {updateProductStatusParamItem[]} productList 更新する商品のリスト
 */
/**
 * @typedef {object} updateProductStatusParamItem 許諾商品ステータス更新商品リスト
 * @property {number} productId 許諾商品内部コード
 * @property {'TMP'|'OK'|'SUS'} 許諾商品ステータス
 * @property {string} updateDatetime 最終更新日時（※許諾商品ステータス変更日時）
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
