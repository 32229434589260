/** 文言リスト */
const Messages = Object.freeze({
  // validate.jsに対応する文言だけとりあえず定義
  'isNotEmpty': '必須項目です',
  // {min}{max}部分は数値で置換
  'maxLength': '{max}文字以内で入力してください',
  'minLength': '{min}文字以上で入力してください',
  'lengthRange': '{min}文字から{max}文字で入力してください',
  'minValue': '{min}以上の数値を入力してください',
  'maxValue': '{max}以下の数値を入力してください',
  'valueRange': '{min}から{max}までの数値を入力してください',
  'isNumber': '半角数値で入力してください',
  'isValidPassword': '半角英大文字、半角英小文字、半角数字、半角記号から最低3種類を組み合わせて入力してください',
  'isValidEmail': 'メールアドレス形式で入力してください',
  // {digit}部分は数値で置換
  'isFixedDigitNumber': '{digit}桁の半角数字で入力してください',
  'isFixedAlphanumeric': '{digit}桁の半角英数字で入力してください',
  'isNotMatchConf': '{param}と値が一致しません',
  'isDecimalValueWithin': '整数{int}桁、小数{dec}桁以内で入力してください',
  'isNotMonth': '年月（YYYY/MM）の形式で入力してください',
  'isNotAlphanumericSymbol': '半角英数記号のみ入力してください',
});

/**
 * メッセージIDをもとにメッセージを取得する
 * @param {keyof Messages} value メッセージID
 * @param {Record<string, string|number>} params メッセージ中のパラメータ置換設定
 * @returns {string} メッセージIDに対応するメッセージ.メッセージID不正の場合は空文字
 */
export function getMessage(value, params={}) {
  let message = Messages[value] ?? '';
  // パラメータを展開する
  Object.entries(params ?? {}).forEach(([key, val]) => {
    message = message.replace(`{${key}}`, val);
  });
  return message;
}

/** APIのエラーメッセージ */
export const ApiErrorMessage = Object.freeze({
  // TODO: 先方確認後に資料と一致しているか見直す
  /** 不正なログイン情報 */
  InvalidCredential: 'ログインに失敗しました。入力したメールアドレスまたはパスワードが間違っています。',
  /** 不正な認証コード */
  InvalidAuthCode: 'ログインに失敗しました。認証コードが間違っています。',
  /** 不正なパスワードリセット認証コード */
  InvalidPassResetCode: 'パスワード再設定に失敗しました。認証コードが間違っています。',
  /** アカウントロック状態 */
  AccountLock: '指定したユーザーはログインできません。',
  /** 存在しないデータ */
  DataNotExists: '存在しない情報が指定されています。',
  /** 更新状態不一致 */
  UpdateStateMismatch: '他の人が情報を更新しています。画面をリロードしてから再度更新をお願いします。',
  /** 登録失敗 */
  RegisterFailed: '登録に失敗しました。入力内容を確認してもう一度実行してください。',
  /** ユーザー重複 */
  DuplicatedUser: '登録済みのユーザー情報です。入力内容を確認してください。',
  /** 画像取得失敗 */
  GetImageFailed: '画像取得に失敗しました。',
  /** バリデートエラー */
  ValidateError: '入力内容に不正な値が含まれています。入力内容を確認してもう一度実行してください。',
  /** システムエラー */
  SystemError: 'システムエラーが発生しました。',
  /** 削除状態不一致 */
  DeleteStateMismatch: '他の人が情報を更新しています。画面をリロードしてから再度削除をお願いします。',
  /** ユーザー登録失敗 */
  UserRegisterFailed: 'ご指定のユーザーは既に登録が完了しているか、削除されています。',
  /** ユーザー一覧取得失敗 */
  UserListFailed: 'ユーザー一覧の取得に失敗しました。',
  /** 招待トークンの認証期限切れ */
  InvitationTokenExpired: 'ユーザー登録URLの有効期限が切れています。ユーザー登録は行えません。',
  /** お知らせ情報取得失敗 */
  GetAnnouncementFailed: 'お知らせ情報の取得に失敗しました。',
  /** 契約終了日不正 */
  InvalidContractEndDate: '指定された契約終了日に変更できません。',
  /** メッセージ登録失敗 */
  MessageRegisterFailed: 'システムエラーが発生しました。再実行してもエラーになる場合はお問い合わせください。',
  /** 履歴登録失敗 */
  HistoryRegisterFailed: 'システムエラーが発生しました。再実行してもエラーになる場合はお問い合わせください。',
  /** ユーザ情報なし */
  UserNotFound: 'システムエラーが発生しました。再実行してもエラーになる場合はお問い合わせください。',
  /** データ数上限 */
  TooManyResult: '条件に該当する件数が多すぎるため検索を中断しました。条件を変更して再度検索してください。 ',
});
