/** 定数定義 */
export const Constants = Object.freeze({
  /** ロイヤリティ報告パターン */
  RoyaltyPattern: Object.freeze({
    /** 上代×ロイヤリティ料率×生産数 */
    PriceRateProductNum: '1',
    /** 上代×ロイヤリティ料率×販売数 */
    PriceRateSalesNum: '2',
    /** 製造原価×ロイヤリティ料率×生産数 */
    CostRateProductNum: '3',
    /** 対象売上金額×ロイヤリティ料率 */
    SalesPriceRate: '4',
    /** 生産数×ロイヤリティ単価 */
    ProductNumUniPrice: '5',
    /** 販売数×ロイヤリティ単価 */
    SalesNumUnitPrice: '6',
    /** ロイヤリティ金額（直接入力） */
    FixedPrice: '7',
  }),
  /** 証紙タイプ */
  labelType: Object.freeze({
    S: 'シール',
    P: '印刷'
  }),
  /** ライセンシー向け */
  Licensee: Object.freeze({
    /** 企画申請ステータス */
    ProposalStatus: Object.freeze({
      /** 一時保存 */
      TemporarySaved: 'TMP',
      /** 取り下げ */
      Canceled: 'CAN',
      /** 申請中 */
      Requesting: 'REQ',
      /** 差し戻し中 */
      Rejected: 'REJ',
      /** 承認済み */
      Approved: 'APP',
      /** 更新中 */
      Permitted: 'PER',
      /** 中止 */
      Suspended: 'SUS',
      /** 契約終了（未報告） */
      FinishNotReported: 'FNR',
      /** 契約終了 */
      Finished: 'FIN',
      /** 削除 */
      Deleted: 'DEL',
      /** 承認済み+契約終了（未報告） (※検索パラメータ専用) */
      ApprovedAndFinishNotReported: 'APP_FNR',
    }),
    /** 企画申請ステータス名(ステータスからの名称取得用) */
    ProposalStatusName: Object.freeze({
      TMP: '一時保存',
      CAN: '取り下げ',
      REQ: '申請中',
      REJ: '差し戻し中',
      APP: '承認済み',
      PER: '更新中',
      SUS: '中止',
      FNR: '契約終了（未報告）',
      FIN: '契約終了',
      DEL: '削除',
    }),
    /** 企画申請ステータス名(連絡履歴用) */
    ProposalStatusNameForHistory: Object.freeze({
      REQ: '申請',
      CAN: '取り下げ',
      PER: '更新',
      REJ: '差し戻し',
      SUS: '中止',
    }),

    /** 企画申請ステータス・強調対象 */
    ProposalStatusEmphasis: Object.freeze([
      'REJ',
    ]),
    /** ロイヤリティ報告区分 */
    RyReportCategory: Object.freeze({
      /** 毎月報告 */
      Monthly: 'MON',
      /** 指定月報告 */
      Specified: 'SPE',
      /** 任意 */
      Any: 'ANY',
    }),
    /** 許諾商品ステータス */
    productStatus: Object.freeze({
      /** 登録済み */
      Registered: 'REG',
      /** 承認済み */
      Approved: 'APP',
    }),
    /** 証紙申請ステータス */
    labelStatus: Object.freeze({
      TemporarySaved: 'TMP',
      Requesting: 'REQ',
      Rejected: 'REJ',
      Approved: 'APP'
    }),
    /** 証紙申請ステータス名 */
    labelStatusName: Object.freeze({
      TMP: '一時保存',
      REQ: '申請中',
      REJ: '差し戻し中',
      APP: '承認済み'
    }),
    /** 証紙申請ステータス名(連絡履歴用) */
    LabelStatusNameForHistory: Object.freeze({
      REQ: '申請',
      REJ: '差し戻し',
    }),
    /** 証紙申請ステータス・強調対象 */
    labelStatusEmphasis: Object.freeze([
      'REJ',
    ]),
    /** ロイヤリティ報告ステータス */
    reportStatus: Object.freeze({
      /** 一時保存 */
      TemporarySaved: 'TMP',
      /** 申請中 */
      Requesting: 'REQ',
      /** 差し戻し中 */
      Rejected: 'REJ',
      /** 申請完了(0円報告) */
      Approved: 'APP',
      /** 申請完了(売上送信済み) */
      Exported: 'EXP',
      /** 削除済み */
      Deleted: 'DEL',
    }),
    /** ロイヤリティ報告ステータス名 */
    reportStatusName: Object.freeze({
      TMP: '一時保存',
      REQ: '申請中',
      REJ: '差し戻し中',
      APP: '申請完了',
      EXP: '申請完了',
    }),
    /** ロイヤリティ報告ステータス名(連絡履歴用) */
    ReportStatusNameForHistory: Object.freeze({
      REQ: '申請',
      REJ: '差し戻し',
    }),
    /** ロイヤリティ報告・強調対象 */
    reportStatusEmphasis: Object.freeze([
      'REJ',
    ]),
    /** 検索フォーム保存キー */
    SearchFormSaveKey: Object.freeze({
      /** [L]企画一覧 */
      ProposalList: 'FormData_L_ProposalList',
      /** [L]証紙申請一覧 */
      LabelRequestList: 'FormData_L_LabelRequestList',
      /** [L]ロイヤリティ報告一覧 */
      RoyaltyReportList: 'FormData_L_RoyaltyReportList',
    })
  }),
  /** ANIPLEX向け */
  Aniplex: Object.freeze({
    /** 企画申請ステータス */
    ProposalStatus: Object.freeze({
      /** 取り下げ */
      Canceled: 'CAN',
      /** 修正中(一時保存) */
      Temporary: 'TMP',
      /** 申請中 */
      Requesting: 'REQ',
      /** 差し戻し中 */
      Rejected: 'REJ',
      /** 承認済み */
      Approved: 'APP',
      /** 更新許可 */
      Permitted: 'PER',
      /** 中止 */
      Suspended: 'SUS',
      /** 契約終了（未報告） */
      FinishNotReported: 'FNR',
      /** 契約終了 */
      Finished: 'FIN',
      /** 削除済み */
      Deleted: 'DEL',
    }),
    /** 企画申請ステータス名(ステータスからの名称取得用) */
    ProposalStatusName: Object.freeze({
      CAN: '取り下げ',
      TMP: '修正中',
      REQ: '申請中',
      REJ: '差し戻し中',
      APP: '承認済み',
      PER: '更新中',
      SUS: '中止',
      FNR: '契約終了（未報告）',
      FIN: '契約終了',
    }),
    /** 企画申請ステータス名(連絡履歴用) */
    ProposalStatusNameForHistory: Object.freeze({
      REQ: '申請',
      CAN: '取り下げ',
      PER: '更新',
      REJ: '差し戻し',
      SUS: '中止',
    }),
    /** 企画申請ステータス 強調対象 */
    ProposalStatusEmphasis: Object.freeze([
      'REQ',
    ]),
    /** ロイヤリティ報告区分 */
    RyReportCategory: Object.freeze({
      /** 毎月報告 */
      Monthly: 'MON',
      /** 指定月報告 */
      Specified: 'SPE',
      /** 任意 */
      Any: 'ANY',
    }),
    /** 督促要否 */
    ProposalRyRemindReq: Object.freeze({
      '0': '翌月15日までに報告がない場合、督促しない',
      '1': '翌月15日までに報告がない場合、督促する'
    }),
    /** 許諾商品ステータス */
    ProductStatus: Object.freeze({
      /** 未承認 */
      Registered: 'REG',
      /** 承認済み */
      Approved: 'APP',
    }),
    /** 証紙申請ステータス */
    labelStatus: Object.freeze({
      Requesting: 'REQ',
      Temporary: 'TMP',
      Rejected: 'REJ',
      Approved: 'APP'
    }),
    /** 証紙申請ステータス名 */
    labelStatusName: Object.freeze({
      REQ: '申請中',
      TMP: '修正中',
      REJ: '差し戻し中',
      APP: '承認済み'
    }),
    /** 証紙申請ステータス名(連絡履歴用) */
    LabelStatusNameForHistory: Object.freeze({
      REQ: '申請',
      REJ: '差し戻し',
    }),
    /** 証紙申請ステータス 強調対象 */
    labelStatusEmphasis: Object.freeze([
      'REQ',
    ]),
    /** ロイヤリティ報告ステータス */
    reportStatus: Object.freeze({
      /** 修正中 */
      Temporary: 'TMP',
      /** 申請中 */
      Requesting: 'REQ',
      /** 差し戻し中 */
      Rejected: 'REJ',
      /** 0円報告済み */
      ReportedZero: 'APP',
      /** 売上送信済み */
      Exported: 'EXP',
    }),
    /** ロイヤリティ報告ステータス名 */
    reportStatusName: Object.freeze({
      TMP: '修正中',
      REQ: '申請中',
      REJ: '差し戻し中',
      APP: '0円報告済み',
      EXP: '売上送信済み',
    }),
    /** ロイヤリティ報告ステータス名(連絡履歴用) */
    ReportStatusNameForHistory: Object.freeze({
      REQ: '申請',
      REJ: '差し戻し',
    }),
    /** ロイヤリティ報告ステータス 強調対象 */
    reportStatusEmphasis: Object.freeze([
      'REQ',
    ]),
    /** ロイヤリティ報告系 */
    Royalty: Object.freeze({
      /** 処理内容 */
      Process: Object.freeze({
        /** 売上入力 */
        Sales: '0',
        /** 分配対象金入力 */
        Distribution: '1',
      }),
      /** 税区分 */
      TaxType: Object.freeze({
        /** (10%)課税 */
        Tax10Percent: '21',
        /** (8%)課税売上 */
        Tax8Percent: '11',
        /** (5%)課税売上 */
        Tax5Percent: '01',
        /** (0%)免税売上 */
        DutyFree: '31',
        /** 対象外または非課税仕入 */
        NotTaxable: '0',
      }),
      /** 消費税内外区分 */
      TaxInOut: Object.freeze({
        /** 外税 */
        Out: '0',
        /** 内税 */
        In: '1',
        /** 非課税 */
        NotTaxable: '2',
      }),
      /** 請求書発行有無 */
      Bill: Object.freeze({
        /** 不要 */
        NotRequired: '0',
        /** 必要 */
        Required: '1',
      }),
    }),
    /** 取引先評価 */
    licenseeAssessmentName: Object.freeze({
      A: '○',
      B: '△',
      C: '×',
    }),
    /** 取引先基本契約フラグ */
    kihonkeiyakuFlagName: Object.freeze({
      'true': '締結済み',
      'false': '未締結',
    }),
    /** ユーザーのロール */
    UserRole: Object.freeze({
      /** 管理者 */
      Manager: 'M',
      /** 担当者 */
      Tanto: 'T',
      /** アシスタント */
      Assistant: 'A',
      /** システム運用者 */
      SystemOperator: 'S',
    }),
    /** ユーザーのロール名称 */
    UserRoleName: Object.freeze({
      M: '管理者',
      T: '担当者',
      A: 'アシスタント',
      S: 'システム運用者',
    }),
    /** フォーム保存キー */
    SearchFormSaveKey: Object.freeze({
      /** [A]企画一覧 */
      ProposalList: 'FormData_A_ProposalList',
      /** [A]証紙申請一覧 */
      LabelRequestList: 'FormData_A_LabelRequestList',
      /** [A]RY報告一覧 */
      RoyaltyReportList: 'FormData_A_RoyaltyReportList',
    }),
  }),
});
