//@ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRoyalty, getRoyaltiesAll, exportRoyalty, patchRoyalty, patchReceiptRoyalties } from '../../lib/api/aniplex';
import { ApiErrorMessage } from '../../lib/message';
import { handleAniplexApiError } from '../../lib/util';

/** 初期値 */
const initialState = {
  /** @type {{ result?: { data?: Royalty[] }}|null} */
  fetchAll: null,
  /** @type {RoyaltyDetail|null} 取得したロイヤリティ報告詳細情報 */
  royaltyDetail: null,
  /** @type {?BulkAcceptResult} 0円報告一括受領の処理結果 */
  bulkAcceptResult: null,
  /**
   * API通信状況
   * @type {Record<ApiProcessName, ApiStatus>}
   */
  apiStatus: {
    /** ロイヤリティ報告一覧 */
    fetchAll: null,
    /** ロイヤリティ報告詳細取得処理 */
    fetchRoyalty: null,
    /** 売上連携処理 */
    doExport: null,
    /** ロイヤリティ報告差し戻し処理 */
    rejectRoyalty: null,
    /** 0円報告一括受領 */
    bulkAccept: null,
  }
}

export const fetchAll = createAsyncThunk(
  'royalties/labels/fetchAll',
  /**
   * ロイヤリティ報告一覧取得処理
   * @param {*} params
   */
  async (params, { dispatch }) => {
    try {
      const data = await getRoyaltiesAll(params);
      return data;
    } catch (err) {
      // エラーが発生したらキャッシュ済みの結果をクリアする
      dispatch(clearFetchAll());
      handleAniplexApiError(err, dispatch, {
        '11101': ApiErrorMessage.TooManyResult,
      });
    }
  }
)

/**
 * ロイヤリティ報告詳細取得処理
 */
export const fetchRoyalty = createAsyncThunk(
  'aniplex/royalties/getRoyalty',
  /**
   * ロイヤリティ報告詳細取得処理
   * @param {number} ryReportId ロイヤリティ報告書内部コード
   */
  async (ryReportId, thunkApi) => {
    // 取得済みのデータをクリア
    thunkApi.dispatch(clearRoyaltyDetail());
    try {
      const data = await getRoyalty(ryReportId);
      return data;
    } catch (err) {
      handleAniplexApiError(err, thunkApi.dispatch, {
        // データ不正
        '11201': ApiErrorMessage.DataNotExists,
      });
    }
  }
);

/**
 * 売上連携処理
 */
export const doExport = createAsyncThunk(
  'aniplex/royalties/doExport',
  /**
   * 売上連携処理
   * @param {import('../../lib/api/aniplex').ExportRoyaltyParams} data APIに送信するデータ
   */
  async (data, { dispatch }) => {
    try {
      const result = await exportRoyalty(data);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '11401': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '11402': ApiErrorMessage.UpdateStateMismatch,
        // 出力失敗
        '11403': ApiErrorMessage.SystemError,
        // 報告受領前データあり
        '11404': 'これより以前にロイヤリティ報告がされていない期間が存在するため、受領できません。',
      });
    }
  }
)

/**
 * ロイヤリティ報告差し戻し処理
 */
export const rejectRoyalty = createAsyncThunk(
  'aniplex/royalty/rejectRoyalty',
  /**
   * ロイヤリティ報告差し戻し処理
   * @param {object} args
   * @param {number} args.ryReportId ロイヤリティ報告書内部コード
   * @param {string} args.messageContent 差し戻し理由
   * @param {string} args.updateDatetime 最終更新日時
   */
  async ({ ryReportId, messageContent, updateDatetime }, { dispatch }) => {
    /** @type {import('../../lib/api/aniplex').PatchRoyaltyParams} */
    const params = {
      reportStatus: 'REJ',
      messageContent,
      updateDatetime,
    };

    try {
      const result = await patchRoyalty(ryReportId, params);
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '11301': ApiErrorMessage.UpdateStateMismatch,
        // 更新状態不一致
        '11302': ApiErrorMessage.UpdateStateMismatch,
        // 履歴登録失敗
        '11303': ApiErrorMessage.SystemError,
        // メッセージ登録失敗
        '11304': ApiErrorMessage.MessageRegisterFailed,
      });
    }
  }
)

/**
 * 0円報告一括受領処理
 */
export const bulkAccept = createAsyncThunk(
  'aniplex/royalty/bulkAccept',
  /**
   * @param {BulkAcceptRyReport[]} ryReportList 一括受領対象のRY報告
   */
  async (ryReportList, { dispatch }) => {
    try {
      const result = await patchReceiptRoyalties({ ryReportList });
      return result;
    } catch (err) {
      handleAniplexApiError(err, dispatch, {
        // データ不正
        '13901': '存在しない情報が指定されています。選択中のロイヤリティ報告を再確認してください。',
      });
    }
  }
)

/**
 * ANIPLEX向けロイヤリティ報告周りのスライス
 */
export const royaltiesSlice = createSlice({
  name: 'aniplex/royalties',
  initialState,
  reducers: {
    /**
     * ロイヤルティ報告一覧情報をクリアする
     * @param {typeof initialState} action
     */
    clearFetchAll: (action) => {
      action.fetchAll = null;
    },
    /**
     * 取得済みのロイヤルティ報告詳細情報をクリアする
     * @param {typeof initialState} action
     */
    clearRoyaltyDetail: (action) => {
      action.royaltyDetail = null;
    },
    /**
     * 0円報告一括受領の処理結果をクリアする
     * @param {typeof initialState} state
     */
    clearBulkAcceptResult: (state) => {
      state.bulkAcceptResult = null;
    },
    /**
     * API通信ステータスをクリアする
     * @param {typeof initialState} state
     * @param {object} action
     * @param {ApiProcessName} action.payload クリア対象のAPI
     */
    clearApiStatus: (state, action) => {
      switch (action.payload) {
        // ロイヤリティ報告取得処理
        case 'fetchAll':
          state.apiStatus.fetchAll = null;
          break;
        // ロイヤリティ報告詳細取得処理
        case 'fetchRoyalty':
          state.apiStatus.fetchRoyalty = null;
          break;
        // 売上連携処理
        case 'doExport':
          state.apiStatus.doExport = null;
          break;
        // ロイヤリティ報告差し戻し処理
        case 'rejectRoyalty':
          state.apiStatus.rejectRoyalty = null;
          break;
        // 0円報告一括受領処理
        case 'bulkAccept':
          state.apiStatus.bulkAccept = null;
          break;
        default:
          /** @type {never} */
          // eslint-disable-next-line no-unused-vars
          const _check = action.payload
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // ロイヤリティ報告一覧処理
      .addCase(fetchAll.pending, (state) => {
        state.apiStatus.fetchAll = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.apiStatus.fetchAll = 'finish';
        state.fetchAll = action.payload;
      })
      .addCase(fetchAll.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchAll = null;
      })
      // ロイヤリティ報告詳細取得処理
      .addCase(fetchRoyalty.pending, (state) => {
        state.apiStatus.fetchRoyalty = 'loading';
      })
      .addCase(fetchRoyalty.fulfilled, (state, action) => {
        state.apiStatus.fetchRoyalty = 'finish';
        state.royaltyDetail = action.payload;
      })
      .addCase(fetchRoyalty.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.fetchRoyalty = null;
      })
      // 売上連携処理
      .addCase(doExport.pending, (state) => {
        state.apiStatus.doExport = 'loading';
      })
      .addCase(doExport.fulfilled, (state) => {
        state.apiStatus.doExport = 'finish';
      })
      .addCase(doExport.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.doExport = null;
      })
      // ロイヤリティ報告差し戻し処理
      .addCase(rejectRoyalty.pending, (state) => {
        state.apiStatus.rejectRoyalty = 'loading';
      })
      .addCase(rejectRoyalty.fulfilled, (state) => {
        state.apiStatus.rejectRoyalty = 'finish';
      })
      .addCase(rejectRoyalty.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.rejectRoyalty = null;
      })
      // 0円報告一括受領処理
      .addCase(bulkAccept.pending, (state) => {
        state.apiStatus.bulkAccept = 'loading';
      })
      .addCase(bulkAccept.fulfilled, (state, action) => {
        state.apiStatus.bulkAccept = 'finish';
        state.bulkAcceptResult = action.payload;
      })
      .addCase(bulkAccept.rejected, (state) => {
        // thunk内でエラー処理済みなのでステータスをクリア
        state.apiStatus.bulkAccept = null;
      });
  }
});

export default royaltiesSlice.reducer;

export const {
  clearFetchAll,
  clearRoyaltyDetail,
  clearBulkAcceptResult,
  clearApiStatus,
} = royaltiesSlice.actions;

/**
 * stateからAPI通信状況を取得する
 * @param {*} state
 * @returns {Record<ApiProcessName, ApiStatus>} API通信状況
 */
export const selectApiStatus = (state) => state.aniplex.royalties.apiStatus;

/**
 * stateからロイヤルティ報告詳細情報を取得する
 * @param {*} state
 * @returns {RoyaltyDetail} ロイヤルティ報告詳細情報
 */
export const selectRoyaltyDetail = (state) => state.aniplex.royalties.royaltyDetail;

/**
 * stateからロイヤリティ報告情報を取得する
 * @param {*} state
 * @returns {{result?: { data?: Royalty[] }}|null}
 */
export const fetchAllRoyalties = (state) => state.aniplex.royalties.fetchAll;

/**
 * stateから0円報告一括受領の処理結果を取得する
 * @param {State} state
 * @returns {?BulkAcceptResult}
 */
export const selectBulkAcceptResult = (state) => state.aniplex.royalties.bulkAcceptResult;

//#region typedef
/**
 * @typedef {'fetchAll'|'fetchRoyalty'|'doExport'|'rejectRoyalty'|'bulkAccept'} ApiProcessName API呼出し処理名
 */
/**
 * @typedef {'loading'|'finish'|'error'|null} ApiStatus API通信状況
 */
/**
 * @typedef {import('../../store').State} State
 */
/**
 * @typedef {import('../../lib/api/aniplex').GetRoyaltiesParam} GetRoyaltiesParam
 */
/**
 * @typedef {object} Royalty ロイヤリティ報告情報
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {Exclude<valueOf<Constants['Aniplex']['reportStatus']>, 'MOD'>} reportStatus ロイヤリティ報告ステータス
 * TMP: 修正中
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 0円報告済み
 * EXP: 売上送信済み
 * @property {string} ryReportNo ロイヤリティ報告書No
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日(YYYY/MM/DD)
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {string} propertySummaryCode 作品コード
 * @property {string} propertySummaryName 作品名称
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {string} proposalTitle 企画件名
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} reportUserId 報告者ユーザーID
 * @property {string} reportUserName 報告者氏名
 * @property {string} reportDatetime 報告日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {object} RoyaltyDetail ロイヤリティ報告詳細情報
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} ryReportNo ロイヤリティ報告書No
 * @property {string} licenseeCode 取引先コード
 * @property {number} revision 版数
 * @property {number} period 第N期
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日
 * @property {string} reportDatetime 報告日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} reportUserId 報告者ユーザーID
 * @property {string} reportUserName 報告者氏名
 * @property {valueOf<Constants['Aniplex']['reportStatus']>} reportStatus 報告ステータス
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {RyAmount[]} ryAmountList ロイヤリティ金額情報
 * @property {RyProof[]} ryProofList ロイヤリティ報告確証ファイル情報
 * @property {object[]} ryReportHistory 過去のロイヤリティ報告状況
 * @property {number} ryReportHistory[].ryReportId ロイヤリティ報告書内部コード
 * @property {number} ryReportHistory[].period 第N期
 * @property {number} ryReportHistory[].ryAmount ロイヤリティ合計金額
 * @property {number} ryReportHistory[].calOverRoyalty オーバーロイヤリティ金額(API計算)
 * @property {object} sDecision S決裁情報
 * @property {string} sDecision.sDecisionNo S決裁No
 * @property {number} sDecision.mg MG
 * @property {number} sDecision.mgZan MG残高
 * @property {number} sDecision.ad アドバンス
 * @property {number} sDecision.adZan アドバンス残高
 * @property {'0'|'1'} process 処理内容
 * @property {string} billingDate 請求予定日(YYYY/MM/DD)
 * @property {string} salesDate 売上日(YYYY/MM/DD)
 * @property {string} depositDate 入金予定日(YYYY/MM/DD)
 * @property {'21'|'11'|'01'|'31'|'0'} taxType 税区分
 * @property {'0'|'1'|'2'} taxInOut 消費税内外区分
 * @property {number} consumptionTax 消費税
 * @property {'0'|'1'} bill 請求書発行有無
 * @property {string} billRemarks 請求項目
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} billingZipCode 請求先郵便番号
 * @property {string} billingAddress 請求先住所
 * @property {string} billingDepartment 請求先部署名
 * @property {string} billingName 請求先担当者氏名
 * @property {string} billingPhone 請求先電話番号
 * @property {number} [calOverRoyalty] オーバーロイヤリティ金額(API計算)
 * @property {number} [calFullPeriodOverRoyalty] 通期オーバーロイヤリティ金額(API計算)
 * @property {number} [calFullPeriodRoyaltyTotal] 通期ロイヤリティ金額合計(API計算)
 * @property {MessageHistory[]} [messageHistoryList] 前バージョンのロイヤリティ報告書情報
 * @property {RoyaltyPreviousVersion} [previousVersion] 前バージョンのロイヤリティ報告詳細情報
 */
/**
 * @typedef {object} RyAmount ロイヤリティ金額情報
 * @property {string} productNo 品番
 * @property {string} productName 商品名
 * @property {number} reportProduction 生産数
 * @property {number} reportSales 販売数
 * @property {number} reportPrice 確定上代
 * @property {number} reportCost 確定製造原価
 * @property {number} reportProceeds 確定売上金額
 * @property {number} reportRyAmount ロイヤリティ金額
 * @property {number} calReportProceeds 販売金額(API計算)
 * @property {number} calReportRyTarget ロイヤリティ対象金額(API計算)
 * @property {number} calReportRyAmount ロイヤリティ金額(API計算)
 * @property {number} preTotalResultLabel 証紙発行数合計（RY報告受領時点の記録）
 * @property {number} preTotalResultProduction 実績生産数合計（RY報告受領時点の記録）
 */
/**
 * @typedef {object} RyProof ロイヤリティ報告確証ファイル情報
 * @property {number} ryProofNo ロイヤリティ報告確証No
 * @property {string} ryProofFilepath 確証ファイルパス
 */
/**
 * @typedef {object} MessageHistory 連絡事項
 * @property {number} messageId メッセージ内部コード
 * @property {string} messageContent メッセージ内容
 * @property {'REQ'|'REJ'} messageRegStatus メッセージ登録ステータス
 * REQ: 申請
 * REJ: 差し戻し
 * @property {string} messageRegDatetime メッセージ登録日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} messageRegUserName メッセージ登録者氏名
 * @property {string} messageRegUserType メッセージ登録者種別
 * ANX: ANIPLEXユーザー
 * LCS: ライセンシーユーザー
 */
/**
 * @typedef {object} RoyaltyPreviousVersion 前バージョンのロイヤリティ報告詳細情報
 * @property {string} reportDatetime 報告日時(YYYY/MM/DD HH:mm:ss)
 * @property {string} licenseeCode 取引先コード
 * @property {string} licenseeNameKanji 取引先名（漢字）
 * @property {string} reportUserId 報告者ユーザーID
 * @property {string} reportUserName 報告者氏名
 * @property {number} proposalId 企画内部コード
 * @property {string} proposalNo 企画No
 * @property {number} period 第N期
 * @property {number} ryAmount ロイヤリティ金額合計
 * @property {string} ryStartDate ロイヤリティ報告開始日(YYYY/MM/DD)
 * @property {string} ryEndDate ロイヤリティ報告終了日
 * @property {string} billingZipCode 請求先郵便番号
 * @property {string} billingAddress 請求先住所
 * @property {string} billingDepartment 請求先部署名
 * @property {string} billingName 請求先担当者氏名
 * @property {string} billingPhone 請求先電話番号
 * @property {RyAmount[]} ryAmountList ロイヤリティ金額情報
 * @property {RyProof[]} ryProofList ロイヤリティ報告確証ファイル情報
 */
/**
 * @typedef {object} BulkAcceptResult 0円報告一括受領の処理結果
 * @property {object[]} [successReportList] 処理成功したRY報告のリスト
 * @property {number} successReportList.ryReportId ロイヤリティ報告書内部コード
 * @property {string} successReportList.ryReportNo ロイヤリティ報告書No
 * @property {object[]} [failedReportList] 処理失敗したRY報告のリスト
 * @property {number} failedReportList.ryReportId ロイヤリティ報告書内部コード
 * @property {string} failedReportList.ryReportNo ロイヤリティ報告書No
 */
/**
 * @typedef {object} BulkAcceptRyReport 0円報告一括受領用のRY報告情報
 * @property {number} ryReportId ロイヤリティ報告書内部コード
 * @property {string} updateDatetime 最終更新日時(YYYY/MM/DD HH:mm:ss)
 */
/**
 * @typedef {typeof import('../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
