import { useCallback, useEffect, useMemo, useState } from "react"
import { useFocusError } from "../../../lib/hooks/common";
import { getMessage } from "../../../lib/message";
import { isEmpty } from "../../../lib/validator";
import { ErrorMessageList } from "../../common/ErrorMessageList";
import { FlexiblePopup } from "../../common/FlexiblePopup"
import { SearchableListBox } from "../../common/SearchableListBox"

/**
 * 取引先設定ポップアップ
 * @param {object} props
 * @param {(btn: 'ok'|'cancel', licenseeCode?: string) => void} props.onClose 閉じたときのコールバック
 * @param {Licensee[]} props.licenseeList 取引先一覧
 * @param {string} props.licenseeCode 取引先コードの初期値
 * @returns
 */
export const SelectLicenseePopup = ({ onClose, licenseeList, licenseeCode }) => {
  // 取引先コード
  const [innerLicenseeCode, setInnerLicenseeCode] = useState(licenseeCode);

  /** 選択された値 */
  const selected = useMemo(() => {
    const found = licenseeList.find(l => l.licenseeCode === innerLicenseeCode);
    if (!found) {
      return null;
    }
    return {
      label: `${found.licenseeCode}  ${found.licenseeNameKanji}`,
      value: found.licenseeCode,
    }
  }, [innerLicenseeCode, licenseeList]);

  /** 値変更時のハンドラ */
  const handleChange = useCallback((value) => {
    setMessages(prev => ({
      ...prev,
      licenseeCode: validateLicenseeCode(value),
    }));
    setInnerLicenseeCode(value);
  }, []);

  // 強制バリデートフラグ
  const [validateAllFlg, setValidateAllFlg] = useState(false);
  // エラーメッセージ
  const [messages, setMessages] = useState({});
  /** エラーフラグ */
  const hasError = useMemo(() => Object.values(messages).flat().length > 0, [messages]);

  useEffect(() => {
    if (validateAllFlg) {
      // 全項目バリデート
      const newMessages = {};
      newMessages.licenseeCode = validateLicenseeCode(innerLicenseeCode);
      setMessages(prev => ({
        ...prev,
        ...newMessages,
      }));
    }
  }, [innerLicenseeCode, validateAllFlg]);

  // エラー項目にフォーカスする設定
  const [formRefs, focusError] = useFocusError(['licenseeCode']);
  const [needFocusError, setNeedFocusError] = useState(false);

  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    if (messages.licenseeCode?.length > 0) {
      if (focusError('licenseeCode')) {
        setNeedFocusError(false);
      }
    }
  }, [focusError, messages.licenseeCode?.length, needFocusError]);

  /** 設定ボタン押下時のハンドラ */
  const onSettingClick = useCallback(() => {
    setValidateAllFlg(true);

    const errors = validateLicenseeCode(innerLicenseeCode);
    if (errors.length > 0) {
      setNeedFocusError(true);
      return;
    }

    onClose('ok', innerLicenseeCode);
  }, [innerLicenseeCode, onClose]);

  return (
    <FlexiblePopup onClose={() => onClose('cancel')}>
      <h4 className="popup-title">取引先選択</h4>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name required">取引先</dt>
          <dd className="form-body" style={{ display: 'block' }}>
            <div className="input-form wdt300">
              <SearchableListBox
                selectRef={formRefs.current.licenseeCode}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                data={licenseeList}
                labelKey={['licenseeCode', 'licenseeNameKanji']}
                valueKey="licenseeCode"
                value={selected}
                onChange={item => handleChange(item?.value)} />
            </div>
            <ErrorMessageList messages={messages.licenseeCode ?? []} />
          </dd>
        </dl>
      </div>

      <div className="btn-wrapper">
        <div className="btn label mt15 bg-yellow">
          <button
            disabled={hasError}
            onClick={onSettingClick}
          >設定</button>
        </div>
      </div>
    </FlexiblePopup>
  )
}

/**
 * 取引先コードのバリデート
 * @param {*} value 入力された値
 * @returns {string[]} エラーメッセージ
 */
function validateLicenseeCode(value) {
  const errors = [];

  if (isEmpty(value)) {
    errors.push(getMessage('isNotEmpty'));
    return errors;
  }

  return errors;
}

//#region typedef
/**
 * @typedef {import('./LicenseeUserDetailForm').Licensee} Licensee 取引先情報
 */
//#endregion typedef
