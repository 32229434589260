import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearApiStatus as clearLicenseeApiStatus,
  clearFetchAll as clearLicenseesFetchAll,
  fetchAll as licenseesFetchAll,
  fetchAllLicensees,
  selectApiStatus as selectLicenseeApiStatus,
} from "../../slices/aniplex/licenseesSlice";

/**
 * 取引先一覧マスタを取得して利用する
 * @returns {[Licensee[], boolean]} [取引先一覧マスタ, 通信中フラグ]
 */
export const useLicenseesMst = () => {
  const dispatch = useDispatch();
  const licenseesResult = useSelector(fetchAllLicensees);
  const apiStatus = useSelector(selectLicenseeApiStatus).fetchAll;

  /**
   * 取得したデータ
   * @type {Licensee[]}
   */
  const licenseeList = useMemo(() => {
    return licenseesResult?.result?.data ?? [];
  }, [licenseesResult?.result?.data]);

  // 通信中フラグ
  const loading = useMemo(() => apiStatus === 'loading', [apiStatus]);

  // 離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearLicenseeApiStatus('fetchAll'));
      dispatch(clearLicenseesFetchAll());
    }
  }, [dispatch]);

  // APIから取引先一覧を取得
  useEffect(() => {
    dispatch(licenseesFetchAll())
  }, [dispatch]);

  return [licenseeList, loading];
}

//#region typedef
/**
 * @typedef {import('../../slices/aniplex/licenseesSlice').Licensee} Licensee 取引先情報
 */
//#endregion typedef
