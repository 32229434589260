import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants } from "../../../Constants";
import { useLicenseesMst } from "../../../lib/hooks/aniplex";
import { clearApiStatus, clearLicenseeUser, deleteLicenseeUser, fetchLicenseeUser, selectApiStatus, selectLicenseeUser, updateLicenseeUser } from "../../../slices/aniplex/licenseeUsersSlice";
import { selectMyself } from "../../../slices/aniplex/usersSlice";
import { pushMessage } from "../../../slices/aniplex/utilSlice";
import { LoadingOverlay } from "../../common/LoadingOverlay";
import { LicenseeUserDetailForm } from "./LicenseeUserDetailForm";

/**
 * [A]ライセンシーユーザー詳細画面
 */
export const LicenseeUserUpdatePage = () => {
  const dispatch = useDispatch();
  const myself = useSelector(selectMyself);
  const navigate = useNavigate();
  const apiStatus = useSelector(selectApiStatus);
  const routerParams = useParams();

  // 取引先リスト
  const [licenseeMst, mstLoading] = useLicenseesMst();
  // ライセンシーユーザ
  const [licenseeUser, userLoading, requestUpdateUser] = useLicenseeUser(routerParams.userId);

  /** 通信中フラグ */
  const apiLoading = useMemo(() => {
    return mstLoading
      || userLoading
      || apiStatus.updateLicenseeUser === 'loading'
      || apiStatus.deleteLicenseeUser === 'loading';
  }, [apiStatus.deleteLicenseeUser, apiStatus.updateLicenseeUser, mstLoading, userLoading]);

  /** 更新ボタン表示フラグ */
  const showUpdate = useMemo(() => {
    return [
      Constants.Aniplex.UserRole.Manager,
      Constants.Aniplex.UserRole.Tanto,
      Constants.Aniplex.UserRole.Assistant,
    ].includes(myself?.role);
  }, [myself?.role]);

  // 画面離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('updateLicenseeUser'));
      dispatch(clearApiStatus('deleteLicenseeUser'));
    }
  }, [dispatch]);

  /**
   * 更新ボタン押下時のコールバック
   */
  const onUpdate = useCallback(
    /**
     * @param {import('./LicenseeUserDetailForm').FormData} formData 入力内容
     */
    (formData) => {
    /** @type {import('../../../lib/api/aniplex').PatchLicenseeUserParam} */
    const params = {
      username: formData.username,
      mailaddress: formData.mailaddress,
      licenseeCode: formData.licenseeCode,
      department: formData.department,
      updateDatetime: licenseeUser?.updateDatetime,
    };
    // setUpdateLoading(true);
    dispatch(updateLicenseeUser({
      userId: licenseeUser?.userId,
      params,
    }));
  }, [dispatch, licenseeUser?.updateDatetime, licenseeUser?.userId]);

  useEffect(() => {
    if (apiStatus.updateLicenseeUser === 'finish') {
      dispatch(pushMessage('ユーザー情報を更新しました。'));
      requestUpdateUser();
      dispatch(clearApiStatus('updateLicenseeUser'));
    }
  }, [apiStatus.updateLicenseeUser, dispatch, requestUpdateUser])

  /**
   * 削除ボタン押下時のコールバック
   */
  const onDelete = useCallback(() => {
    dispatch(deleteLicenseeUser({
      userId: licenseeUser?.userId,
      updateDatetime: licenseeUser?.updateDatetime,
    }));
  }, [dispatch, licenseeUser?.updateDatetime, licenseeUser?.userId]);

  useEffect(() => {
    if (apiStatus.deleteLicenseeUser === 'finish') {
      dispatch(pushMessage('ユーザー情報を削除しました。'));
      // 削除されたら一覧画面へ遷移
      navigate('/aniplex/licenseeUserList');
    }
  }, [apiStatus.deleteLicenseeUser, dispatch, navigate]);

  return (
    <div className="wrap">
      <div className="title-border mb20">
        <h1 className="title">ライセンシーユーザー詳細</h1>
        <p className="link-pageback deco-line c-aniplex">
          <Link to="/aniplex/licenseeUserList" >
            <i className="icn pageback"></i>ライセンシーユーザー一覧へ戻る
          </Link>
        </p>
      </div>

      <LicenseeUserDetailForm
        licenseeList={licenseeMst}
        loadedData={licenseeUser}
        submitBtn="ユーザー更新"
        showSubmit={showUpdate}
        showDelete={showUpdate}
        onSubmit={onUpdate}
        onDelete={onDelete}
        formLocked={apiLoading || !showUpdate || !licenseeUser} />

      {
        apiLoading && <LoadingOverlay />
      }
    </div>
  );
}

/**
 * ライセンシーユーザ情報を取得して利用する
 * @param {string} userId ユーザーID
 * @returns {[LicenseeUser|null, boolean, Function]} [ライセンシーユーザ情報, 通信中フラグ, 更新要求コールバック]
 */
const useLicenseeUser = (userId) => {
  const dispatch = useDispatch();
  const licenseeUser = useSelector(selectLicenseeUser);
  const apiStatus = useSelector(selectApiStatus).fetchLicenseeUser;

  // 更新要求フラグ
  const [requireUpdate, setRequireUpdate] = useState(true);

  /** 通信中フラグ */
  const loading = useMemo(() => apiStatus === 'loading', [apiStatus]);

  // 離脱時にAPI通信状況をクリア
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchLicenseeUser'));
      dispatch(clearLicenseeUser());
    }
  }, [dispatch]);

  // ユーザIDが変更されたら更新を要求する
  useEffect(() => {
    setRequireUpdate(true);
  }, [userId]);

  // APIから対象ユーザ情報を取得
  useEffect(() => {
    if (!requireUpdate) return;
    setRequireUpdate(false);

    dispatch(fetchLicenseeUser(userId));
  }, [dispatch, requireUpdate, userId]);

  /** 更新リクエストハンドラ */
  const requestUpdate = useCallback(() => {
    setRequireUpdate(true);
  }, []);

  return [licenseeUser, loading, requestUpdate];
}

//#region typedef
/**
 * @typedef {import('../../../slices/aniplex/licenseeUsersSlice').LicenseeUser} LicenseeUser ライセンシーユーザ
 */
//#endregion typedef
