// TODO: ラインセンシーとアニプレックスとで分けた方がよさそう

/** [L]企画一覧 */
export const proposal = [
    {
      id:'propertyIcon',
      sortable:false,
      minWidth:90,
      label:'作品アイコン',
    },
    {
      id:'propertyName',
      sortable:false,
      minWidth:122,
      label:'作品名称',
    },
    {
      id:'proposalStatus',
      sortable:true,
      minWidth:165,
      label:'企画申請ステータス',
    },
    {
      id:'applicantUserName',
      sortable:true,
      minWidth:100,
      label:'申請者',
    },
    {
      id:'proposalNo',
      sortable:true,
      minWidth: 110,
      label:'企画書No',
    },
    {
      id:'proposalTitle',
      sortable:false,
      minWidth:123,
      label:'企画件名',
    },
    {
      id:'applicationDate',
      sortable:true,
      minWidth:105,
      label:'申請日',
    },
    {
      id:'startDate',
      sortable:true,
      minWidth:105,
      label:'許諾開始日',
    },
    {
      id:'endDate',
      sortable:true,
      minWidth:105,
      label:'許諾終了日',
    },
    {
      id:'updateDate',
      sortable:true,
      minWidth:105,
      label:'最終更新日',
    },
    {
      id:'statuses',
      sortable:false,
      minWidth:80,
      label:'企画状況',
      tdStyle: {
        overflow:'hidden',
      },
    },
    {
      id:'labels',
      sortable:false,
      minWidth:80,
      label:'証紙',
      tdStyle: {
        overflow:'hidden',
      },
    },
    {
      id:'royalties',
      sortable:false,
      minWidth:110,
      label:'ロイヤリティ',
      tdStyle: {
        overflow: 'hidden',
      },
    },
  ];

/** [A]企画一覧 */
export const proposalAniplex = [
    {
      id:'propertyIcon',
      sortable:false,
      minWidth:90,
      label:'作品アイコン',
    },
    {
      id:'propertyName',
      sortable:false,
      minWidth:222,
      label:'作品名称',
    },
    {
      id:'proposalStatus',
      sortable:true,
      minWidth:160,
      label:'企画申請ステータス',
    },
    {
      id:'proposalNo',
      sortable:true,
      minWidth: 110,
      label:'企画書No',
    },
    {
      id:'proposalTitle',
      sortable:false,
      minWidth:222,
      label:'企画件名',
    },
    {
      id:'statuses',
      sortable:false,
      minWidth:80,
      label:'企画状況',
      tdStyle: {
        overflow:'hidden',
      },
    },
    {
      id:'labels',
      sortable:false,
      minWidth:80,
      label:'証紙',
      tdStyle: {
        overflow:'hidden',
      },
    },
    {
      id:'royalties',
      sortable:false,
      minWidth:90,
      label:'ロイヤリティ',
      tdStyle: {
        overflow:'hidden',
      },
    },
    {
      id:'licenseeCode',
      sortable:true,
      minWidth:115,
      label:'取引先コード',
    },
    {
      id:'licenseeNameKanji',
      sortable:false,
      minWidth:222,
      label:'取引先名',
    },
    {
      id:'applicantUserName',
      sortable:false,
      minWidth:150,
      label:'申請者',
    },
    {
      id:'applicationDate',
      sortable:true,
      minWidth:105,
      label:'申請日',
    },
    {
      id:'kikakuEmployeeNo',
      sortable:false,
      minWidth:150,
      label:'企画担当者',
    },
    {
      id:'sDecision',
      sortable:false,
      minWidth:130,
      label:'S決済No',
    },
    {
      id:'sTitle',
      sortable:false,
      minWidth:124,
      label:'S決済件名',
    },
    {
      id:'startDate',
      sortable:true,
      minWidth:105,
      label:'許諾開始日',
    },
    {
      id:'endDate',
      sortable:true,
      minWidth:105,
      label:'許諾終了日',
    },
    {
      id:'updateDate',
      sortable:true,
      minWidth:110,
      label:'最終更新日',
    },
    {
      id:'ryRemindFlag',
      sortable:false,
      minWidth:120,
      label:'ロイヤリティ\n督促',
    },
  ];

/** [L]証紙申請一覧 */
export const labelRequest = [
    {
      id:'labelStatus',
      sortable:true,
      minWidth:165,
      label:'証紙申請ステータス',
    },
    {
      id:'labelApplicationNo',
      sortable:true,
      minWidth:110,
      label:'証紙申請No',
    },
    {
      id:'propertySummaryName',
      sortable:false,
      minWidth:170,
      label:'作品名称',
    },
    {
      id:'proposalNo',
      sortable:false,
      minWidth:110,
      label:'企画書No',
    },
    {
      id:'proposalTitle',
      sortable:false,
      minWidth:170,
      label:'企画件名',
    },
    {
      id:'applicantUserName',
      sortable:false,
      minWidth:130,
      label:'申請者',
    },
    {
      id:'applicationDate',
      sortable:true,
      minWidth:110,
      label:'申請日',
    },
    {
      id:'preferredDate',
      sortable:true,
      minWidth:155,
      label:'証紙受け取り希望日',
    },
    {
      id:'dispatchDate',
      sortable:true,
      minWidth:110,
      label:'発送日',
    },
    {
      id:'message',
      sortable:false,
      minWidth:170,
      label:'連絡事項',
    },
  ];

/** [A]証紙申請一覧 */
export const labelRequestAniplex = [
    {
      id:'labelStatus',
      sortable:true,
      minWidth:160,
      label:'証紙申請ステータス',
    },
    {
      id:'labelApplicationNo',
      sortable:true,
      minWidth:110,
      label:'証紙申請No',
    },
    {
      id:'propertySummaryName',
      sortable:false,
      minWidth:270,
      label:'作品名称',
    },
    {
      id:'proposalNo',
      sortable:false,
      minWidth:110,
      label:'企画書No',
    },
    {
      id:'proposalTitle',
      sortable:false,
      minWidth:260,
      label:'企画件名',
    },
    {
      id:'licenseeCode',
      sortable:false,
      minWidth:110,
      label:'取引先コード',
    },
    {
      id:'licenseeNameKanji',
      sortable:false,
      minWidth:305,
      label:'取引先名',
    },
    {
      id:'applicantUserName',
      sortable:false,
      minWidth:150,
      label:'申請者',
    },
    {
      id:'applicationDate',
      sortable:true,
      minWidth:105,
      label:'申請日',
    },
    {
      id:'preferredDate',
      sortable:true,
      minWidth:155,
      label:'証紙受け取り希望日',
    },
    {
      id:'dispatchDate',
      sortable:true,
      minWidth:165,
      label:'発送日',
    },
    {
      id:'message',
      sortable:false,
      minWidth:288,
      label:'連絡事項',
    },
  ];

/** [L]ロイヤリティ報告一覧 */
export const royaltyReport = [
    {
      id: 'reportStatus',
      sortable:true,
      minWidth:220,
      label:'ロイヤリティ報告ステータス',
    },
    {
      id: 'ryReportNo',
      sortable:true,
      minWidth:165,
      label:'ロイヤリティ報告No',
    },
    {
      id: 'propertySummaryName',
      sortable:false,
      minWidth:298,
      label:'作品名称',
    },
    {
      id: 'proposalNo',
      sortable:false,
      minWidth:110,
      label:'企画書No',
    },
    {
      id: 'proposalTitle',
      sortable:false,
      minWidth:298,
      label:'企画件名',
    },
    {
      id: 'reportUserName',
      sortable:true,
      minWidth:199,
      label:'報告者',
    },
    {
      id: 'reportDate',
      sortable:true,
      minWidth:110,
      label:'報告日',
    },
  ];

/** [A]ロイヤリティ報告一覧 */
export const royaltyReportAniplex = [
    {
      id: 'reportStatus',
      sortable:true,
      minWidth:215,
      label:'ロイヤリティ報告ステータス',
    },
    {
      id: 'ryReportNo',
      sortable:true,
      minWidth:117,
      label:'ロイヤリティ\n報告No',
    },
    {
      id: 'ryDate',
      sortable:false,
      minWidth:270,
      label:'報告対象期間',
      align: 'center',
    },
    {
      id: 'ryAmount',
      sortable:false,
      minWidth:150,
      label:'ロイヤリティ金額',
      align: 'right',
    },
    {
      id: 'propertySummaryName',
      sortable:false,
      minWidth:364,
      label:'作品名称',
    },
    {
      id: 'proposalNo',
      sortable:false,
      minWidth:110,
      label:'企画書No',
      align: 'center',
    },
    {
      id: 'proposalTitle',
      sortable:false,
      minWidth:295,
      label:'企画件名',
    },
    {
      id: 'licenseeCode',
      sortable:false,
      minWidth:110,
      label:'取引先コード',
    },
    {
      id: 'licenseeNameKanji',
      sortable:false,
      minWidth:286,
      label:'取引先名',
    },
    {
      id: 'reportDate',
      sortable:true,
      minWidth:124,
      label:'報告日',
      align: 'center',
    },
  ];

/** [A]取引先一覧 */
export const licenseeAniplex = [
    {
      id:'licenseeCode',
      sortable:true,
      minWidth:120,
      label:'取引先コード',
    },
    {
      id:'licenseeNameKanji',
      sortable:false,
      minWidth:286,
      label:'取引先名',
    },
    {
      id:'address',
      sortable:false,
      minWidth:539,
      label:'住所',
    },
    {
      id:'phone',
      sortable:false,
      minWidth:125,
      label:'電話番号',
    },
    {
      id:'kihonkeiyakuFlag',
      sortable:false,
      minWidth:125,
      label:'基本契約締結',
      align: 'center',
    },
    {
      id:'kihonkeiyakuDate',
      sortable:false,
      minWidth:125,
      label:'締結日',
      align: 'center',
    },
    {
      id:'assessment',
      sortable:false,
      minWidth:80,
      label:'評価',
      align: 'center',
    },
];

/** [A]ライセンシーユーザー一覧 */
export const licenseeUserAniplex = [
    {
      id:'userId',
      sortable:true,
      minWidth:105,
      label:'ユーザーID',
    },
    {
      id:'username',
      sortable:false,
      minWidth:165,
      label:'氏名',
    },
    {
      id:'mailaddress',
      sortable:false,
      minWidth:300,
      label:'メールアドレス',
    },
    {
      id:'licenseeCode',
      sortable:false,
      minWidth:100,
      label:'取引先コード',
    },
    {
      id:'licenseeNameKanji',
      sortable:false,
      minWidth:255,
      label:'取引先名',
    },
    {
      id:'department',
      sortable:false,
      minWidth:165,
      label:'部署名',
    },
    {
      id:'regDatetime',
      sortable:false,
      minWidth:105,
      label:'登録日時',
    },
    {
      id:'loginDatetime',
      sortable:false,
      minWidth:105,
      label:'最終ログイン',
    },
    {
      id:'userStatus',
      sortable:false,
      minWidth:100,
      label:'ステータス',
    },
];

/** [L]ライセンシーユーザー一覧 */
export const licenseeUser = [
  {
    id:'username',
    sortable:false,
    minWidth:220,
    label:'氏名',
  },
  {
    id:'mailaddress',
    sortable:false,
    minWidth:490,
    label:'メールアドレス',
  },
  {
    id:'department',
    sortable:false,
    minWidth:250,
    label:'部署名',
  },
  {
    id:'userStatus',
    sortable:false,
    minWidth:105,
    label:'ステータス',
  },
  {
    id:'loginDatetime',
    sortable:false,
    minWidth:180,
    label:'最終ログイン日時',
  },
  {
    id:'delete',
    sortable:false,
    minWidth:150,
    label:'削除',
    tdStyle: {
      overflow:'hidden',
    },
  },
];

/** [A]ANIPLEXユーザー一覧画面 */
export const aniplexUser = [
  {
    id: 'employeeNo',
    sortable: true,
    minWidth: 110,
    label: '社員番号',
  },
  {
    id: 'employeeName',
    sortable: false,
    minWidth: 344,
    label: '氏名',
  },
  {
    id: 'mailaddress',
    sortable: false,
    minWidth: 555,
    label: 'メールアドレス',
  },
  {
    id: 'role',
    sortable: false,
    minWidth: 181,
    label: 'ロール',
  },
  {
    id: 'regDatetime',
    sortable: false,
    minWidth: 105,
    label: '登録日時'
  },
  {
    id: 'loginDatetime',
    sortable: false,
    minWidth: 105,
    label: '最終ログイン'
  }
]

/** [A]作品一覧 */
export const propertyAniplex = [
  {
    id: 'propertySummaryCode',
    sortable: true,
    minWidth: 110,
    label: '作品コード',
  },
  {
    id: 'propertySummaryName',
    sortable: false,
    minWidth: 547,
    label: '作品名称',
  },
  {
    id: 'propertySummaryNameKana',
    sortable: false,
    minWidth: 548,
    label: '作品名称カナ',
  },
  {
    id: 'icon',
    sortable: false,
    minWidth: 90,
    label: 'アイコン',
  },
  {
    id: 'proposalFlag',
    sortable: false,
    minWidth: 105,
    label: '企画申請可否',
  },
];

/** [A]お知らせ一覧 */
export const announcementAniplex = [
  {
    id:'announcementNo',
    sortable:true,
    minWidth:120,
    label:'お知らせNo',
  },
  {
    id:'announcementTitle',
    sortable:false,
    minWidth:440,
    label:'お知らせタイトル',
  },
  {
    id:'announcementStartDatetime',
    sortable:true,
    minWidth:120,
    label:'公開開始日',
  },
  {
    id:'announcementEndDatetime',
    sortable:true,
    minWidth:120,
    label:'公開終了日',
  },
  {
    id:'announcementStatus',
    sortable:false,
    minWidth:120,
    label:'ステータス',
  },
  {
    id:'announcementRegDatetime',
    sortable:true,
    minWidth:120,
    label:'登録日',
  },
  {
    id:'announcementRegEmployeeName',
    sortable:false,
    minWidth:120,
    label:'登録者',
  },
  {
    id:'announcementEditDatetime',
    sortable:true,
    minWidth:120,
    label:'更新日',
  },
  {
    id:'announcementUpdateEmployeeName',
    sortable:false,
    minWidth:120,
    label:'更新者',
  },
];

// TODO:Constants.jsにマージしたい

// [L]ステータス名称・順番
export const statusList = {
  "TMP": {index: 1, text: "一時保存"},
  "REQ": {index: 2, text: "申請中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "承認済み"},
  "CAN": {index: 5, text: "取り下げ"},
  "PER": {index: 6, text: "更新中"},
  "SUS": {index: 7, text: "中止"},
  "FNR": {index: 8, text: "契約終了（未報告）"},
  "FIN": {index: 9, text: "契約終了"},
}

// [L]ステータス名称・順番(検索のみ)
export const statusListSearch = {
  "TMP": {index: 1, text: "一時保存"},
  "REQ": {index: 2, text: "申請中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "承認済み"},
  "CAN": {index: 5, text: "取り下げ"},
  "PER": {index: 6, text: "更新中"},
  "SUS": {index: 7, text: "中止"},
  "FNR": {index: 8, text: "契約終了（未報告）"},
  "FIN": {index: 9, text: "契約終了"},
}

// [A]ステータス名称・順番
export const statusListAniplex = {
  "REQ": {index: 1, text: "申請中"},
  "CAN": {index: 2, text: "取り下げ"},
  "TMP": {index: 3, text: "修正中"},
  "APP": {index: 4, text: "承認済み"},
  "REJ": {index: 5, text: "差し戻し中"},
  "PER": {index: 6, text: "更新中"},
  "SUS": {index: 7, text: "中止"},
  "FNR": {index: 8, text: '契約終了（未報告）'},
  "FIN": {index: 9, text: "契約終了"},
}

// [L]ステータス名称・順番(証紙申請)
export const statusListLabel = {
  "TMP": {index: 1, text: "一時保存"},
  "REQ": {index: 2, text: "申請中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "承認済み"},
}

// [A]ステータス名称・順番(証紙申請)
export const statusListLabelAniplex = {
  "REQ": {index: 1, text: "申請中"},
  "TMP": {index: 2, text: "修正中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "承認済み"},
}

// [L]ステータス名称・順番(ロイヤリティ)
export const statusListRoyalty = {
  "TMP": {index: 1, text: "一時保存"},
  "REQ": {index: 2, text: "申請中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "申請完了"},
  "EXP": {index: 4, text: "申請完了"},
}

// [L]ステータス名称・順番(検索のみ)
export const statusListRoyaltySearch = {
  "TMP": {index: 1, text: "一時保存"},
  "REQ": {index: 2, text: "申請中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP_EXP": {index: 4, text: "申請完了"},
}

// [A]ステータス名称・順番(ロイヤリティ)
export const statusListRoyaltyAniplex = {
  "REQ": {index: 1, text: "申請中"},
  "TMP": {index: 2, text: "修正中"},
  "REJ": {index: 3, text: "差し戻し中"},
  "APP": {index: 4, text: "0円報告済み"},
  "EXP": {index: 5, text: "売上送信済み"},
}

// [A]ステータス名称・順番(ライセンシーユーザ)
export const statusListLicenseeUser = {
  "INV": {index: 1, text: "招待中"},
  "REG": {index: 2, text: "登録済"},
}

// [A]ステータス名称・順番(お知らせ)
export const statusListAnnouncement = {
  "STT": {index: 1, text: "公開前"},
  "OPN": {index: 2, text: "公開中"},
  "END": {index: 3, text: "公開終了"},
}
