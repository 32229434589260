import { UploadImage } from '../licensee/UploadImage';
import { selectCategoryMst, selectRoyaltyPatternMst, selectRypInputRequire } from "../../slices/licensee/masterSlice"
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState, useMemo } from "react";
import { isEmpty, maxLength, maxValueComma, isMonth, isDecimalWithin } from "../../lib/validator";
import { getMessage } from "../../lib/message";
import { ErrorMessageList } from "../common/ErrorMessageList";
import { insertComma, deleteComma } from "../../lib/number";
import { Constants } from '../../Constants';
import BigNumber from 'bignumber.js';
import { UploadImageShowOnly } from '../licensee/UploadImageShowOnly';
import { Config } from '../../config';
import { NumberInput } from './NumberInput';
import { useFocusError } from '../../lib/hooks/common';
import { formatPeriodYM } from '../../lib/util';
import { HelpMessage } from "../common/HelpMessage"
import { SafeCharTextArea } from './SafeCharTextArea';

/** キャラクターのヘルプメッセージ */
const characterHelpMessage =
`キャラクターの入力例を説明します。

①特定のキャラクターを利用した個別商品の場合
本商品のキャラクター名を入力してください。

②特定のキャラクターを利用しない個別商品の場合（集合絵を使用）
入力不要です。

③トレーディング商品
入力不要です。トレーディング商品の場合は、キャラクターラインナップへ記載してください。`;

/** キャラクターラインナップのヘルプメッセージ */
const characterLineupHelpMessage =
`キャラクターラインナップの入力例を説明します。

①特定のキャラクターを利用した個別商品の場合
本商品を含め、他にどのようなキャラクターで展開予定か入力ください。

②特定のキャラクターを利用しない個別商品の場合（集合絵を使用）
集合絵の登場人物を記載してください。

③トレーディング商品
商品に使用するキャラクターをすべて入力してください。`;

/** キャラクターラインナップのプレースホルダー */
const characterLineupPlaceholder =
`全5種
・キャラクターA
・キャラクターB
・キャラクターC
・キャラクターD
・キャラクターA、キャラクターB、キャラクターC、キャラクターD　組み合わせ`;


/** フォームの項目 */
const formPropOrder = [
  'productName',
  'categoryNo',
  'categoryDetailNo',
  'character',
  'version',
  'launchDate',
  'planPrice',
  'planCost',
  'planProceeds',
  'planProduction',
  'planSales',
  'ryRate',
  'ryPrice',
  'planRyAmount',
  'salesMethod',
  'productMethod',
  'material',
  'characterLineup',
  'productRemarks',
  'productOption1',
  'productOption2',
  'productOption3',
];

/** 期数によって入力項目数が変化する項目 */
const periodicalProps = [
  'planProduction',
  'planSales',
];

/**
 * 商品詳細ポップアップ
 * @param {object} params
 * @param {Period[]} params.periodList 期リスト
 * @param {Product|undefined} params.product 商品データ
 * @param {Function} params.onClose コールバック[
 * @param {boolean} params.isEditable false:編集不可表示
 */
export const ProductDetail = ({periodList, product, onClose, isEditable}) => {
  // スクロールの位置保存
  const [preY] = useState(window.scrollY);
  // マスタデータ
  const categoryMst = useSelector(selectCategoryMst);
  const royaltyMst = useSelector(selectRoyaltyPatternMst);
  const reqRypFunc = useSelector(selectRypInputRequire);
  // 商品データあったら利用
  const [productForm, setProductForm] = useState(product ?? {
    productNo: null,
    productName : null,
    categoryNo : null,
    categoryDetailNo: null,
    character: null,
    version: null,
    launchDate: null,
    rypId: Constants.RoyaltyPattern.PriceRateProductNum,
    planPrice: null,
    planCost: null,
    planProceeds: null,
    planRyAmount: null,
    salesMethod: "1",
    productMethod: "1",
    ryRate: null,
    ryPrice: null,
    material: null,
    characterLineup: null,
    productRemarks: null,
    periodList: [],
    renderingImageList: [],
    productOption1: null,
    productOption2: null,
    productOption3: null,
  });
  // 3桁区切りに対応する項目
  const commaItems = useMemo(() => [
    'planPrice', 'planCost', 'planProceeds', 'ryPrice', 'planRyAmount'
  ], []);
  // キャラクターラインナップのプレースホルダー表示フラグ
  const [showCcharacterLineupPlaceHolder, setShowCcharacterLineupPlaceHolder] = useState(false);

  // UploadImage
  // 新規にアップロードした画像の一覧
  const [renderingImageList, setRenderingImageList] = useState([]);
  // アップロード済みの画像(一時保存など)
  const [uploadedFileNoList, setUploadedFileNoList] = useState([]);
  // エラー有無
  const [hasError, setHasError] = useState(false);
  // アップロード中画像の有無
  const [hasProgress, setHasProgress] = useState(false);
  // アップロード画像件数上限超過の有無
  const [hasExceeded, setHasExceeded] = useState(false);
  // アップロードコンポーネントに引き渡すように変換
  const uploadedFileNoList4Uploader = useMemo(() => {
    return uploadedFileNoList.map(i => i.renderingImageNo);
  }, [uploadedFileNoList]);
  // 画像アップロード時のコールバック
  const onChangeFileNoList = useCallback((noList) => {
    setRenderingImageList(
      noList.map(no => ({ renderingImageNo: no }))
    );
  }, []);
  // アップロード済み画像変更時のコールバック
  const onChangeUploaded = useCallback((noList) => {
    setUploadedFileNoList(
      noList.map(no => ({ renderingImageNo : no }))
    );
  }, []);
  // ポップアップを開いたときに商品データの画像リストを反映
  useEffect(() => {
    // ページトップに遷移
    window.scrollTo(0, 0);
    setUploadedFileNoList(product?.renderingImageList ?? []);
  }, [product?.renderingImageList]);

  // カテゴリの設定値
  const categoryOption = useMemo(()  => categoryMst.map((data) =>
    <option
    key={"category__" + data?.categoryNo}
    value={data?.categoryNo}>{data?.categoryName}</option>
  ), [categoryMst]);
  // RY報告パタンの設定値
  const royaltyOption = useMemo(() => royaltyMst.map((data) =>
    <option
    key={"ryp__" + data?.rypId}
    value={data?.rypId}>{data?.rypName}</option>
  ), [royaltyMst]);

  // エラーメッセージ
  const [ messages, setMessages ] = useState({});

  // エラー項目へフォーカスする設定
  const formProps = useMemo(() => formPropOrder.map(prop => {
    if (periodicalProps.includes(prop)) {
      // 期数によって変動する項目はネストさせる
      return {
        name: prop,
        items: (new Array(periodList.length)).fill(null).map((_, idx) => idx),
      };
    }
    return prop;
  }), [periodList.length]);
  const [formRefs, focusError] = useFocusError(formProps);
  const [needFocusError, setNeedFocusError] = useState(false);
  /** 予定生産数量用のref */
  const planProductionRefs = formRefs.current.planProduction;
  /** 予定販売数量用のref */
  const planSalesRefs = formRefs.current.planSales;

  // エラー項目へのフォーカス処理
  useEffect(() => {
    if (!needFocusError) {
      return;
    }

    for (const prop of formPropOrder) {
      if (periodicalProps.includes(prop)) {
        for (let i = 1; i <= periodList.length; i++) {
          if (messages[prop]?.[i]?.length > 0 && focusError(prop, i - 1)) {
            setNeedFocusError(false);
            return;
          }
        }
      } else {
        if (messages[prop]?.length > 0 && focusError(prop)) {
          setNeedFocusError(false);
          return;
        }
      }
    }
  }, [focusError, messages, needFocusError, periodList.length]);

  const updatePrice = (name, value) => {
    setProductForm({
      ...productForm,
      [name]: value
    });
  }
  const handleChangeCategory = (name, value) => {
    handleChange(name, value);
    // カテゴリ詳細を空にする
    setMessages({
      ...messages,
      ...validate('categoryDetailNo', '', reqRypFunc(productForm.rypId))
    });
    setProductForm({
      ...productForm,
      categoryNo: value,
      categoryDetailNo: ''
    });
  }
  // フォーム設定とバリデート
  const handleChange = (name, value) => {
    setMessages({
      ...messages,
      ...validate(name, value, reqRypFunc(productForm.rypId))
    });
    if (name !== 'planRyAmount') calcAmount(productForm.rypId, name, value);
    setProductForm({
      ...productForm,
      [name]: value
    });
  }

  // 期ごとの値変更
  const [planProductionValues, setPlanProductionValues] = useState({});
  const [planSalesValues, setPlanSalesValues] = useState({});
  const updatePricePeriod = (name, period, value) => {
    if (name === 'planProduction' && value !== null) {
      setPlanProductionValues({
        ...planProductionValues,
        [period]: value
      });
    }
    if (name === 'planSales' && value !== null){
      setPlanSalesValues({
        ...planSalesValues,
        [period]: value
      });
    }
  };
  const handleChangePeriod = (name, period, value) => {
    updatePricePeriod(name, period, value);
    let error = {};
    const target = (name === 'planSales') ? planSalesValues : planProductionValues;
    if (target) {
      const periodError = {}
      for (let step = 1; step <= periodList.length; step++) {
        const val = (period === step) ? value : target[step];
        const err = validate(name, val, reqRypFunc(productForm.rypId), step);
        if (err ?? err[name] ?? err[name][step] ?? err[name][step].length) {
          periodError[step] = err[name][step];
        }
      }
      error = { [name] : periodError };
    }
    setMessages({...messages,...error});
  }

  const [categoryDetailOption, setCategoryDetailOption] = useState([]);
  const changeCategoryDetail = useCallback((value) => {
    setCategoryDetailOption([
        categoryMst[value-1]?.categoryDetailList.map((data, index) =>
          <option
          key={"catDetail__" + data?.categoryDetailNo}
          value={data?.categoryDetailNo}>{data?.categoryDetailName}</option>
        )
    ]);
  }, [categoryMst]);

  useEffect(() => {
    // 期ごとの予定数量取得
    if (Object.keys(productForm.periodList).length) {
      const planProductions = {};
      const planSales = {};
      // 数量の値があれば3桁区切り挿入して表示
      for (const[, param] of Object.entries(productForm.periodList)) {
        planProductions[param?.period] = insertComma(param?.planProduction) ?? null;
        planSales[param?.period] = insertComma(param?.planSales) ?? null;
      }
      setPlanProductionValues(planProductions)
      setPlanSalesValues(planSales)
    }

    setRyp(royaltyMst.find(ry => ry.rypId === productForm.rypId));
    // カンマ区切り挿入
    let tmpProduct = {};
    commaItems.forEach((value) => {
      tmpProduct[value] = insertComma(productForm[value]);
    });
    setProductForm({
      ...productForm,
      ...tmpProduct
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForm.periodList]);

  // カテゴリ詳細更新
  useEffect(() => {
    changeCategoryDetail(productForm.categoryNo);
  }, [productForm.categoryNo, changeCategoryDetail])

  // RY報告パタンの表示切替
  const [ryp, setRyp] = useState(royaltyMst[0]);
  const changeRypId = (value) => {
    setRyp(royaltyMst.find(ry => ry.rypId === value));
    formatInput(value);
    calcAmount(value);
  };
  // RY報告パターンの初期化
  useEffect(() => {
    if (product == null) {
      changeRypId(royaltyMst[0].rypId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const periodDataList = [];
  const productionList = [];
  const salesList = [];
  const [sumPlanProduction, setSumPlanProduction] = useState(0);
  const [sumPlanSales, setSumPlanSales] = useState(0);
  for (let step = 1; step <= periodList.length; step++) {
    const ym = formatPeriodYM(periodList[step - 1].ryStartDate, periodList[step - 1].ryEndDate);
    // 予定生産数量
    periodDataList.push(<th key={"th__"+step} scope="col" style={{"height": "50px", "minWidth": "304px"}}>第{step}期<br />{ym}</th>);
    productionList.push(
      <td key={"production__"+step}>
        <div className="input-form wdt200 mlrauto cost">
          <NumberInput type="text" name="planProduction" aria-label="第N期" title="第N期予定生産数量を必ず入力してください" required
          inputRef={planProductionRefs?.current?.[step - 1]}
          extraChars={[',']}
          value={planProductionValues[step] ?? productForm?.periodList[step-1]?.planProduction ?? ''}
          onChange={val => handleChangePeriod('planProduction', step, val)}
          onBlur={event => updatePricePeriod('planProduction', step, insertComma(event.target.value))}
          onFocus={event => updatePricePeriod('planProduction', step, deleteComma(event.target.value))}
          disabled={!ryp?.inputPlanProduction || !isEditable}/>
        </div>
        <ErrorMessageList messages={messages.planProduction?.[step]} />
      </td>
    );
    // 予定販売数量
    salesList.push(
      <td key={"sales__"+step}>
        <div className="input-form wdt200 mlrauto cost">
          <NumberInput type="text" name="planSales" aria-label="第N期" title="第N期予定販売数量を必ず入力してください" required
          inputRef={planSalesRefs?.current?.[step - 1]}
          extraChars={[',']}
          value={planSalesValues[step] ?? productForm?.periodList[step-1]?.planSales ?? ''}
          onChange={val => handleChangePeriod('planSales', step, val)}
          onBlur={event => updatePricePeriod('planSales', step, insertComma(event.target.value))}
          onFocus={event => updatePricePeriod('planSales', step, deleteComma(event.target.value))}
          disabled={!ryp?.inputPlanSales || !isEditable}/>
        </div>
        <ErrorMessageList messages={messages.planSales?.[step]} />
      </td>
    );
  }
  // 期の予定数量の合計列
  productionList.push(
    <td key="production__cost" className="cost">{insertComma(sumPlanProduction)}</td>
  );
  salesList.push(
    <td key="sales__cost" className="cost">{insertComma(sumPlanSales)}</td>
  );

  // 予定生産数・予定販売数の集計
  useEffect(() => {
    if (Object.keys(planProductionValues).length > 0) {
      setSumPlanProduction(
        Object.values(planProductionValues).reduce((prev, cur) => Number(deleteComma(prev??0)) + Number(deleteComma(cur??0)))
      )
    } else {
      setSumPlanProduction(0);
    }
    if (Object.keys(planSalesValues).length > 0) {
      setSumPlanSales(
        Object.values(planSalesValues).reduce((prev, cur) => Number(deleteComma(prev??0)) + Number(deleteComma(cur??0)))
      )
    } else {
      setSumPlanSales(0);
    }
  }, [planProductionValues, planSalesValues]);

  /** RY報告パタンに応じた金額計算 */
  const [planRyAmount, setPlanRyAmount] = useState(0);
  let BN = BigNumber.clone();
  /**
   * 金額計算
   * @param {*} rypId ロイヤリティ報告パタン
   * @param {*} updName 更新項目
   * @param {*} updValue 更新値
   */
  const calcAmount = useCallback((rypId, updName, updValue) => {
    let temp = new BN(1);

    Object.entries(royaltyMst.find(ry => ry.rypId === rypId)).forEach(([key, value]) => {
      if (value && /^(inputPlan.*|inputRy.*)$/.test(key)) {
        // ロイヤリティ料率（マスタ情報の値からパラメータ名に変換）
        const str = 'input';
        const name = key.replace(str, '')[0].toLowerCase() + key.replace(str, '').substring(1);
        switch(name) {
          // 3桁区切りを排除して計算
          case updName:
            temp = temp.times(deleteComma(updValue));
            if (updName === 'ryRate') temp = temp.times(0.01);
            break;
          case 'planProduction':
            temp = temp.times(deleteComma(sumPlanProduction));
            break;
          case 'planSales':
            temp = temp.times(deleteComma(sumPlanSales));
            break;
          case 'ryRate':
            temp = temp.times(productForm[name]).times(0.01);
            break;
          default:
            temp = temp.times(deleteComma(productForm[name]));
            break;
        }
      }
    });
    const amount = deleteComma(Number(temp).toLocaleString('en-US', {maximumFractionDigits:0}));
    setPlanRyAmount(Number(amount) || 0);
  },[BN, productForm, royaltyMst, sumPlanProduction, sumPlanSales]);

  useEffect(() => {
    calcAmount(ryp.rypId);
  }, [ryp, sumPlanProduction, sumPlanSales, calcAmount]);


  // RY報告パタンによる表示出し分け
  const formatInput = (rypId) => {
    const resetObj = { rypId: rypId };
    let isPlanProduction = true;
    let isPlanSales = true;
    // 入力不要な項目はリセット
    Object.entries(royaltyMst.find(ry => ry.rypId === rypId)).forEach(([key, value]) => {
      if (!value && /^(inputPlan.*|inputRy.*)$/.test(key)) {
        // ロイヤリティ料率（マスタ情報の値からパラメータ名に変換）
        const str = 'input';
        const name = key.replace(str, '')[0].toLowerCase() + key.replace(str, '').substring(1);
        switch(name){
          case 'planProduction':
            isPlanProduction = false;
            setPlanProductionValues({});
            break;
          case 'planSales':
            isPlanSales = false;
            setPlanSalesValues({});
            break;
          case 'planRyAmount':
            break;
          default:
            resetObj[name] = null;
            break;
        }
      }
    });

    // 予定数量のリセット
    const dataList = [];
    for (let step = 1; step <= periodList.length; step++) {
      const obj = {};
      obj.period = Number(step);
      // RY報告パターンの設定値
      if (isPlanProduction) obj.planProduction = Number(planProductionValues[step]) || 0;
      if (isPlanSales) obj.planSales = Number(planSalesValues[step]) || 0;
      dataList.push(obj);
    }
    resetObj.periodList = dataList;
    setProductForm({
      ...productForm,
      ...resetObj
    });
  };

  /**
   * 商品申請前にデータ整形
   * @param {*} event
   */
  const onSubmitProduct = (event) => {
    const dataList = [];
    for (let step = 1; step <= periodList.length; step++) {
      const obj = {};
     // RY報告パターンに関わらず期のリストは送信する
      obj.period = Number(step);
      // 「対象売上金額×ロイヤリティ料率」「ロイヤリティ金額（直接入力）」以外のパターンの場合は期ごとの数量設定する
      if (!ryp.inputPlanProceeds && !ryp.inputPlanRyAmount) {
        // RY報告パターンの設定値
        obj.planProduction = ryp.inputPlanProduction && planProductionValues[step] ? Number(deleteComma(planProductionValues[step])) : null;
        obj.planSales =  ryp.inputPlanSales && planSalesValues[step] ? Number(deleteComma(planSalesValues[step])) : null;
      }
      dataList.push(obj);
    }

    // 商品申請用に整形
    const targetObj = {
      ...productForm,
      planRyAmount: Number(planRyAmount),
      periodList: dataList,
      renderingImageList: [...uploadedFileNoList, ...renderingImageList]
    };

    // 商品申請前に3桁区切り除去
    commaItems.forEach((value) => {
      targetObj[value] = (targetObj[value]) ? Number(deleteComma(targetObj[value])) : null;
    });

    // パラメータ全体バリデート
    const productError = productValidate(targetObj, reqRypFunc);
    if (productError.apply && Object.keys(productError.apply).length > 0) {
      setMessages({...messages, ...productError.apply});
      setNeedFocusError(true);
    } else {
      // ポップアップ表示前の位置に戻す
      window.scrollTo(0, preY);
      onClose('submit', targetObj);
    }
  };

  // キャラクターラインナップのプレースホルダー表示フラグ
  useEffect(() => {
    setShowCcharacterLineupPlaceHolder(!productForm.characterLineup);
  }, [productForm.characterLineup]);

  return (
    <div className="l-popup active">
    <div className="popup-content">
      <div className="popup-body">
        <div className="wrap">
          <div className="title-border mb20">
            <h2 className="title">商品詳細</h2>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">商品名等</dt>
              <dd className="form-body">
                <div className="input-form wdt400">
                  <input type="text" name="productName" title="商品名等は必ず入力してください" required aria-label="商品名等"
                  ref={formRefs.current.productName}
                  value={productForm.productName ?? ''}
                  onChange={event => handleChange('productName', event.target.value)}
                  disabled={!isEditable} />
                </div>
                <span className="attention">例：B2タペストリー、1/7フィギュア</span>
                <ErrorMessageList messages={messages.productName} />
            </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">カテゴリー</dt>
              <dd className="form-body">
                <div className="form-select wdt250">
                  <select name="categoryNo" required title="カテゴリーは必ず選択してください"
                  ref={formRefs.current.categoryNo}
                  defaultValue={productForm.categoryNo ?? ''}
                  onChange={event => handleChangeCategory('categoryNo', event.target.value)}
                  disabled={!isEditable}>
                    <option value=""></option>
                    {categoryOption}
                  </select>
                </div>
                <ErrorMessageList messages={messages.categoryNo} />
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">カテゴリー詳細</dt>
              <dd className="form-body">
                <div className="form-select wdt300">
                  <select name="categoryDetailNo" required title="カテゴリー詳細は必ず選択してください"
                  ref={formRefs.current.categoryDetailNo}
                  value={productForm.categoryDetailNo ?? ''}
                  onChange={event => handleChange('categoryDetailNo', event.target.value)}
                  disabled={!isEditable}>
                    <option value=""></option>
                    {categoryDetailOption}
                  </select>
                </div>
                <ErrorMessageList messages={messages.categoryDetailNo} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name">
                <HelpMessage
                    title={'キャラクター'}
                    description={characterHelpMessage}/>
              </dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="character" title="キャラクターを入力してください" aria-label="キャラクター"
                  ref={formRefs.current.character}
                  placeholder='キャラクターA'
                  value={productForm.character ?? ''}
                  onChange={event => handleChange('character', event.target.value)}
                  disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.character} />
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name">バージョン</dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="version" title="バージョンを入力してください" aria-label="バージョン"
                  ref={formRefs.current.version}
                  value={productForm.version ?? ''}
                  onChange={event => handleChange('version', event.target.value)}
                  disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.version} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">ロイヤリティ報告パターン</dt>
              <dd className="form-body">
                <div className="form-select wdt300">
                  <select name="rypId" title="ロイヤリティ報告パターンは必ず選択してください" required
                  value={productForm.rypId ?? ''}
                  onChange={event => changeRypId(event.target.value)}
                  disabled={!isEditable}>
                    {royaltyOption}
                  </select>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">発売希望日</dt>
              <dd className="form-body">
                <div className="input-form wdt300">
                  <input type="text" name="launchDate" title="発売希望日は必ず選択してください"
                  ref={formRefs.current.launchDate}
                  value={productForm.launchDate ?? ''}
                  onChange={event => handleChange('launchDate', event.target.value)}
                  required disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.launchDate} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required">上代（税抜き）</dt>
              <dd className="form-body">
                <div className="input-form cost wdt100">
                  <NumberInput type="text" name="planPrice" aria-label="上代（税抜き）" title="上代（税抜き）は必ず入力してください" required
                  inputRef={formRefs.current.planPrice}
                  value={productForm.planPrice ?? ''}
                  extraChars={[',']}
                  onChange={val => handleChange('planPrice', val)}
                  onBlur={event => updatePrice('planPrice', insertComma(event.target.value))}
                  onFocus={event => updatePrice('planPrice', deleteComma(event.target.value))}
                  disabled={!ryp?.inputPlanPrice || !isEditable}/>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">製造原価</dt>
              <dd className="form-body">
                <div className="input-form cost wdt140">
                  <NumberInput type="text" name="planCost" aria-label="製造原価" title="製造原価を入力してください"
                  inputRef={formRefs.current.planCost}
                  extraChars={[',']}
                  value={productForm.planCost ?? ''}
                  onChange={val => handleChange('planCost', val)}
                  onBlur={event => updatePrice('planCost', insertComma(event.target.value))}
                  onFocus={event => updatePrice('planCost', deleteComma(event.target.value))}
                  disabled={!ryp?.inputPlanCost || !isEditable}/>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">予定売上金額</dt>
              <dd className="form-body">
                <div className="input-form cost wdt140">
                  <NumberInput type="text" name="planProceeds" aria-label="予定売上金額" title="予定売上金額を入力してください"
                  inputRef={formRefs.current.planProceeds}
                  extraChars={[',']}
                  value={productForm.planProceeds ?? ''}
                  onChange={val => handleChange('planProceeds', val)}
                  onBlur={event => updatePrice('planProceeds', insertComma(event.target.value))}
                  onFocus={event => updatePrice('planProceeds', deleteComma(event.target.value))}
                  disabled={!ryp?.inputPlanProceeds || !isEditable}/>
                </div>
              </dd>
            </dl>
          </div>
          {/** input-form単位だと表示幅が狭すぎるので行切り替え */}
          <ErrorMessageList messages={messages.planPrice} />
          <ErrorMessageList messages={messages.planCost} />
          <ErrorMessageList messages={messages.planProceeds} />

          {/** 選択済みの期に応じたリストを自動生成 */}
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "124px"}}>予定生産数量</dt>
              <dd className="form-body">
                <div className="l-table scroll" style={{ 'width': 1200 }}>
                  <table className="bb-none">
                    <thead>
                      <tr>
                        {periodDataList}
                        <th scope="col" style={{"height": "40px", "minWidth": "182px"}}>期間合計</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {productionList}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>
          </div>
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "124px"}}>予定販売数量</dt>
              <dd className="form-body">
                <div className="l-table scroll" style={{ 'width': 1200 }}>
                  <table className="bb-none">
                    <thead>
                      <tr>
                        {periodDataList}
                        <th scope="col" style={{"height": "40px", "minWidth": "182px"}}>期間合計</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {salesList}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>

          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "124px"}}>ロイヤリティ</dt>
              <dd className="form-body">
                <div className="dif a-center">
                  <div className="input-form wdt50 mr10 cost">
                    <NumberInput type="text" name="ryRate" title="ロイヤリティの%を必ず入力してください" aria-label="ロイヤリティ" maxLength="4" required
                    inputRef={formRefs.current.ryRate}
                    value={productForm.ryRate ?? ''}
                    onChange={val => handleChange('ryRate', val)}
                    disabled={!ryp?.inputRyRate || !isEditable}/>
                  </div>
                  <p>％ ／ 固定単価</p>
                  <div className="input-form wdt140 ml5 mr5 cost">
                    <NumberInput type="text" name="ryPrice" aria-label="固定単価" title="固定単価を必ず入力してください" required
                    inputRef={formRefs.current.ryPrice}
                    extraChars={[',']}
                    value={productForm.ryPrice ?? ''}
                    onChange={val => handleChange('ryPrice', val)}
                    onBlur={event => updatePrice('ryPrice', insertComma(event.target.value))}
                    onFocus={event => updatePrice('ryPrice', deleteComma(event.target.value))}
                    disabled={!ryp?.inputRyPrice || !isEditable}/>
                  </div>
                  <p>円</p>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">ロイヤリティ額</dt>
              <dd className="form-body">
                <div className="dif a-center">
                  <div className="input-form wdt140 mr5 cost">
                    <NumberInput type="text" name="planRyAmount" aria-label="ロイヤリティ額" title="ロイヤリティ額を必ず入力してください" required
                    inputRef={formRefs.current.planRyAmount}
                    extraChars={[',']}
                    value={ productForm.rypId === '7' ? productForm.planRyAmount :
                      insertComma(planRyAmount) ?? '' }
                    onChange={val => handleChange('planRyAmount', val)}
                    onBlur={event => updatePrice('planRyAmount', insertComma(event.target.value))}
                    onFocus={event => updatePrice('planRyAmount', deleteComma(event.target.value))}
                    disabled={!isEditable}
                    />
                  </div>
                  <p>円</p>
                </div>
              </dd>
            </dl>

          </div>
          {/** input-form単位だと表示幅が狭すぎるので行切り替え */}
          <ErrorMessageList messages={messages.ryRate} />
          <ErrorMessageList messages={messages.ryPrice} />
          <ErrorMessageList messages={messages.planRyAmount} />

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{"width": "124px"}}>販売方式</dt>
              <dd className="form-body">
                <div className="form-select wdt200">
                  <select name="salesMethod" title="販売方式を必ず選択してください" required
                  ref={formRefs.current.salesMethod}
                  onChange={event => handleChange('salesMethod', event.target.value)}
                  defaultValue={productForm.salesMethod}
                  disabled={!isEditable}>
                    <option value="1">個別商品</option>
                    <option value="2">トレーディング商品</option>
                  </select>
                </div>
              </dd>
            </dl>

            <dl className="form-set">
              <dt className="form-name required">生産方式</dt>
              <dd className="form-body">
                <div className="form-select wdt140">
                  <select name="productMethod" title="生産方式を必ず選択してください" required
                  ref={formRefs.current.productMethod}
                  onChange={event => handleChange('productMethod', event.target.value)}
                  defaultValue={productForm.productMethod}
                  disabled={!isEditable}>
                    <option value="1">見込み生産</option>
                    <option value="2">受注生産</option>
                  </select>
                </div>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>素材他仕様</dt>
              <dd className="form-body">
                <label className="form-textarea wdt1200">
                  <SafeCharTextArea rows="4" name="material" aria-label="素材他仕様" title="素材他仕様を入力してください"
                  ref={formRefs.current.material}
                  defaultValue={productForm.material}
                  value={productForm.material}
                  onChange={val => handleChange('material', val)}
                  disabled={!isEditable} />
                </label>
                <ErrorMessageList messages={messages.material} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name required" style={{width: '124px', lineHeight: '20px'}}>
                <HelpMessage
                  title={'キャラクター\nラインナップ'}
                  description={characterLineupHelpMessage}/>
              </dt>
              <dd className="form-body">
                <label className="form-textarea wdt1200">
                  <div className="form-textarea-box">
                    <SafeCharTextArea name="characterLineup" rows="6"
                      aria-label="キャラクターラインナップ"
                      ref={formRefs.current.characterLineup}
                      value={productForm.characterLineup ?? ''}
                      onChange={val => handleChange('characterLineup', val)}
                      disabled={!isEditable} />
                    {
                      showCcharacterLineupPlaceHolder ? (
                        <div>
                          {characterLineupPlaceholder}
                        </div>
                      ) : null
                    }
                  </div>
                </label>
                <ErrorMessageList messages={messages.characterLineup} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>備考</dt>
              <dd className="form-body">
                <div className="form-textarea wdt1200">
                  <SafeCharTextArea rows="4" name="productRemarks" aria-label="備考" title="備考を入力してください"
                  ref={formRefs.current.productRemarks}
                  defaultValue={productForm.productRemarks}
                  value={productForm.productRemarks}
                  onChange={val => handleChange('productRemarks', val)}
                  disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.productRemarks} />
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>商品イメージ</dt>
              <dd className="form-body wdt1200">
              {
                isEditable ? (
                  <UploadImage
                  uploadedFileNoList={uploadedFileNoList4Uploader}
                  onChangeFileNoList={onChangeFileNoList}
                  onChangeHasError={setHasError}
                  onChangeHasProgress={setHasProgress}
                  onChangeUploaded={onChangeUploaded}
                  onChangeHasExceeded={setHasExceeded}
                  maxFileSize={1 * 1024 * 1024}
                  maxFileSizeErrMsg='ファイルサイズは1MBまでです。' />
                  ) :
                  <UploadImageShowOnly fileNoList={uploadedFileNoList4Uploader} />
              }
              {
                isEditable && (hasError || hasProgress || hasExceeded) && (
                  <ul className="error_list" style={{ textAlign: 'center', paddingBottom: '20px' }}>
                    {/* エラーがあった場合の表示 */}
                    {hasError && (
                      <li className="error_list-item">許諾商品画像にエラーがあります。</li>
                    )}
                    {/* アップロード中画像があった場合の表示 */}
                    {hasProgress && (
                      <li className="error_list-item">アップロード中の画像があります。</li>
                    )}
                    {/** アップロード画像が上限超過している場合の表示 */}
                    {hasExceeded && (
                      <li className="error_list-item">商品イメージは{Config.ProductImageLimit}点まで登録できます。</li>
                    )}
                  </ul>
                )
              }
              <p className="mt10">
                ファイル形式： JPEG, PNG, GIF<br />
                ファイルサイズ：1MB以内<br />
                ファイル数：10個以内
              </p>
              </dd>
            </dl>
          </div>

          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>自由記入欄1</dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption1" title="自由記入欄1を入力してください" aria-label="自由記入欄1"
                    ref={formRefs.current.productOption1}
                    value={productForm.productOption1 ?? ''}
                    onChange={event => handleChange('productOption1', event.target.value)}
                    disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.productOption1} />
              </dd>
            </dl>
          </div>
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>自由記入欄2</dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption2" title="自由記入欄2を入力してください" aria-label="自由記入欄2"
                    ref={formRefs.current.productOption2}
                    value={productForm.productOption2 ?? ''}
                    onChange={event => handleChange('productOption2', event.target.value)}
                    disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.productOption2} />
              </dd>
            </dl>
          </div>
          <div className="l-form">
            <dl className="form-set">
              <dt className="form-name" style={{"width": "124px"}}>自由記入欄3</dt>
              <dd className="form-body">
                <div className="input-form wdt500">
                  <input type="text" name="productOption3" title="自由記入欄3を入力してください" aria-label="自由記入欄3"
                    ref={formRefs.current.productOption3}
                    value={productForm.productOption3 ?? ''}
                    onChange={event => handleChange('productOption3', event.target.value)}
                    disabled={!isEditable} />
                </div>
                <ErrorMessageList messages={messages.productOption3} />
              </dd>
            </dl>
          </div>
        </div>

        <div className="btn label bg-pink mt15 mb20 mlrauto" style={{"width": "190px"}}>
        {
          isEditable ? (
            <button onClick={event => onSubmitProduct(event)}
            disabled={hasError || hasProgress || hasExceeded}>
              商品を登録する
            </button>
          ) : null
        }
          </div>
      </div>

      <p className="btn-close" onClick={
        () => {window.scrollTo(0, preY); onClose('close')}
        }>close</p>
    </div>
    <div className="overlay"></div>
    </div>
  );
}

/**
 * バリデート共通処理
 * @param {*} name
 * @param {*} value
 * @param {array} reqList
 * @param {*} period
 */
function validate(name, value, reqList = [], period = null, tempFlg = false) {
  const errors = [];
  const validItems = {
    'productName': ["isEmpty", "maxLength100"],
    'categoryNo': ["isEmpty"],
    'categoryDetailNo': ["isEmpty"],
    'character': ["maxLength100"],
    'version': ["maxLength100"],
    'rypId': [],
    'launchDate': ["isEmpty", "maxLength20"],
    'planPrice': ["maxValue9d2"],
    'planCost': ["maxValue9d2"],
    'planProceeds': ["maxValue12d"],
    //予定生産数量・予定販売数量は個別チェック
    'planProduction': ['periodNumber'],
    'planSales': ['periodNumber'],
    'ryRate': ["maxValue100d1"],
    'ryPrice': ["maxValue9d2"],
    'planRyAmount': ["maxValue12d"],
    'salesMethod': [],
    'productMethod': [],
    'characterLineup': ["isEmpty", "maxLength500"],
    'material': ["maxLength500"],
    'productRemarks': ["maxLength500"],
    'productOption1': ["maxLength100"],
    'productOption2': ["maxLength100"],
    'productOption3': ["maxLength100"],
  };
  if (!validItems[name]) return;
  // エラー設定
  errors[name] = [];

  // RY報告パタンによって必須かどうか確認する
  !tempFlg && reqList.includes(name)
  && !['planProduction', 'planSales'].includes(name)
  && reqList.forEach((value) => {
    // 必須チェック追加
    validItems[value].push('isEmpty');
  });


  validItems[name].forEach((param) => {
    switch(param) {
      case 'isEmpty':
        if (!tempFlg && isEmpty(value)) {
          errors[name].push(getMessage("isNotEmpty"));
        }
        break;
      case 'maxLength20':
        if (value && !maxLength(value, 20)) {
          errors[name].push(getMessage("maxLength", {max: 20}));
        }
        break;
      case 'maxLength100':
        if (value && !maxLength(value, 100)) {
          errors[name].push(getMessage("maxLength", {max: 100}));
        }
        break;
      case 'maxLength500':
        if (value && !maxLength(value, 500)) {
          errors[name].push(getMessage("maxLength", {max: 500}));
        }
        return errors;
      case 'isMonth':
        if (value && !isMonth(value)) {
          errors[name].push(getMessage("isNotMonth"));
        }
        break;
      case 'maxValue100d1':
        if (value && !isDecimalWithin(value, 1)) {
          errors[name].push(getMessage("isDecimalValueWithin", {int:3, dec:1}));
        } else if (value && !maxValueComma(value, 100)) {
          errors[name].push(getMessage("maxValue", {max: 100}));
        }
        break;
      case 'maxValue9d2':
        if (value && !isDecimalWithin(value, 2)) {
          errors[name].push(getMessage("isDecimalValueWithin", {int:9, dec:2}));
        } else if (value && !maxValueComma(value, 100000000)) {
          errors[name].push(getMessage("maxValue", {max: 100000000}));
        }
        break;
      case 'maxValue12d':
        if (value && !maxValueComma(value, 100000000000)) {
          errors[name].push(getMessage('maxValue', {max: 100000000000}));
        }
        break;
      case 'periodNumber':
        errors[name][period] = [];
        if (!tempFlg && isEmpty(value)) {
          errors[name][period].push(getMessage('isNotEmpty'));
        } else if (!isEmpty(value) && !maxValueComma(value, 1000000000)) {
          errors[name][period].push(getMessage('maxValue', {max: 1000000000}));
        }
        break;
      default:
        break;
    }
  });
  return errors;
}

/**
 * 商品一括バリデート
 * @param {*} product 商品データ
 * @param {*} reqRypFunc
 * @returns {array} エラー文言リスト
 */
export function productValidate(product, reqRypFunc) {
  // パラメータ全体バリデート
  const productError = {
    apply: {}, // 申請用
    tmp : {}  // 一時保存用（必須チェックなし）
  };
  const reqList = reqRypFunc(product.rypId);
  for(const[key, value] of Object.entries(product)) {
    let app, tmp  = [];
    if (key === 'periodList') {
      for (const[, param] of Object.entries(value)) {
        app = tmp = [];
        let target = '';
        const period = param.period;
        if (reqList.includes('planProduction')) {
          target = 'planProduction';
        } else if (reqList.includes('planSales')) {
          target = 'planSales';
        }
        if (target) {
          app = validate(target, param[target], reqList, period);
          if (app && app[target]) {
            // 必須有でエラーがあった時だけ一時保存用エラーチェックする
            tmp = validate(target, param[target], reqList, period, true);
            if (app[target][period] && app[target][period].length) {
              productError.apply[target] = Object.assign(productError.apply[target] ?? {}, app[target]);
            }
            if (tmp[target][period] && tmp[target][period].length) {
              productError.temp[target] = Object.assign(productError.temp[target] ?? {}, tmp[target]);
            }
          }
        }
      }
    } else {
      app = validate(key, value, reqList, null);
      if (app && app[key].length) {
        productError.apply[key] = app[key];
        // 必須有でエラーがあった時だけ一時保存用エラーチェックする
        tmp = validate(key, value, reqList, null, true);
        if (tmp && tmp[key].length) productError.tmp[key] = tmp[key];
      }
    }
  }
  return productError;
}

//#region typedef
/**
 * @typedef {import('../../slices/licensee/proposalsSlice').ProposalPeriod} Period
 */
/**
 * @typedef {import('../../slices/licensee/proposalsSlice').ProposalProduct} Product
 */
//#endregion typedef
