//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProposalListSearchForm, dateParams, defaultParams } from './ProposalListSearchForm.jsx';
import { TableVisible } from '../../common/table/TableVisible.jsx';
import { proposalAniplex, statusListAniplex } from '../../common/headerList.jsx';
import { PagingTable } from '../../common/table/PagingTable.jsx';
import { fetchAll, fetchAllProposals, selectApiStatus, clearApiStatus, clearFetchAll } from '../../../slices/aniplex/proposalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay } from '../../common/LoadingOverlay';
import { TableCellButton } from '../../common/table/TableCellButton';
import { immutableSort } from "../../../lib/util";
import { Config } from '../../../config.js';
import { Constants } from '../../../Constants.js';
import { emptyToUndefined, formatDate } from '../../../lib/listPageUtil.js';
import { useSaveFormData } from '../../../lib/hooks/common.js';

/** ページャーの1ページあたりの行数 */
const pageSize = 20;

/** チェック状態の初期値(すべてtrue) */
const checkParamDefault = {};
proposalAniplex.forEach(prop => {
  checkParamDefault[prop.id] = true;
});

/** 強調表示する申請ステータス */
const ProposalStatusEmphasis = Constants.Aniplex.ProposalStatusEmphasis;

/** 企画状況ページを表示可能な企画ステータス */
const StatusVisibleProposalStatus = [
  Constants.Aniplex.ProposalStatus.Approved,
  Constants.Aniplex.ProposalStatus.Suspended,
  Constants.Aniplex.ProposalStatus.FinishNotReported,
  Constants.Aniplex.ProposalStatus.Finished,
];

/**
 * Aniplex向け企画一覧画面
 * @returns
 */
export const ProposalListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiStatus = useSelector(selectApiStatus).fetchAll;
  const proposalList = useSelector(fetchAllProposals);
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState(proposalAniplex);
  const [checkParam, setCheckParam] = useState(checkParamDefault);
  const [current, setCurrent] = useState(1);
  const [sortInfo, setSortInfo] = useState({ sortKey: 'updateDate', order: 'desc' });

  /**
   * 検索フォームの初期値
   * @type {SearchFormData}
   */
  const defaultSearchParam = useMemo(() => ({
    ...defaultParams,
    filterCondition: 'PSO',
  }), []);

  // 検索フォームの内容
  const [searchParams, setSearchParams] = useState(defaultSearchParam)

  // 検索フォーム保存処理
  const {
    isRestored,
    savedData,
    saveData,
  } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.ProposalList,
    dataType: searchParams,
    dateParams: dateParams
  });

  // 検索パラメータ復元済みフラグ
  const [paramRestored, setParamRestored] = useState(false);
  // 初回検索済フラグ
  const [initialFetched, setInitialFetched] = useState(false);

  // 申請中の件数
  const reqCount = useMemo(() =>
    proposalList?.result?.data?.filter(p => p.proposalStatus === Constants.Aniplex.ProposalStatus.Requesting).length ?? 0
  , [proposalList?.result?.data])

  // 申請中の件数表示をするかのフラグ
  const showReqCount = useMemo(() => (reqCount ?? 0) > 0, [reqCount]);

  //マウント時に企画一覧を取得
  useEffect(() => {
    if (!paramRestored) {
      return;
    }
    if (initialFetched) {
      return;
    }
    dispatch(fetchAll(emptyToUndefined({
      ...searchParams,
      applicationDateFrom: formatDate(searchParams.applicationDateFrom),
      applicationDateTo:   formatDate(searchParams.applicationDateTo),
      startDateFrom:       formatDate(searchParams.startDateFrom),
      startDateTo:         formatDate(searchParams.startDateTo),
      endDateFrom:         formatDate(searchParams.endDateFrom),
      endDateTo:           formatDate(searchParams.endDateTo),
      ryRemindFlag:        searchParams.ryRemindFlag ? true : undefined,
    })));
    setInitialFetched(true);
  }, [dispatch, initialFetched, paramRestored, searchParams]);

  // 画面離脱時にAPI通信状況をクリアする
  useEffect(() => {
    return () => {
      dispatch(clearApiStatus('fetchAll'));
      dispatch(clearFetchAll());
    }
  }, [dispatch]);

  // 画面突入時に保存済み検索パラメータを復元する
  useEffect(() => {
    if (paramRestored) {
      return;
    }
    if (!isRestored) {
      return;
    }
    if (savedData == null) {
      setParamRestored(true);
      return;
    }

    setSearchParams(savedData);
    setParamRestored(true);
  }, [isRestored, paramRestored, savedData]);

  // API通信中はローディング表示
  const loading = useMemo(() => {
    return apiStatus === 'loading' ? (
      <LoadingOverlay />
    ) : null;
  }, [apiStatus]);

  // 検索
  const onSearch = useCallback(
    /** @param {SearchFormData} params */
    params => {
      dispatch(fetchAll(emptyToUndefined({
        ...params,
        applicationDateFrom: formatDate(params.applicationDateFrom),
        applicationDateTo:   formatDate(params.applicationDateTo),
        startDateFrom:       formatDate(params.startDateFrom),
        startDateTo:         formatDate(params.startDateTo),
        endDateFrom:         formatDate(params.endDateFrom),
        endDateTo:           formatDate(params.endDateTo),
        ryRemindFlag:        params.ryRemindFlag ? true : undefined,
      })));
    }
  , [dispatch]);

  // 申請中のみを検索
  const onSearchReq = useCallback(() => {
    const param = {
      ...searchParams,
      proposalStatus: Constants.Aniplex.ProposalStatus.Requesting,
    };
    setSearchParams(param);
    // NOTE: フォームのコンポーネントに渡らないのでこっち側で保存
    saveData(param);
    onSearch(param);
  }, [onSearch, saveData, searchParams]);

  // 「表示を切り替える」押下時
  const onCheckApply = useCallback(() => {
    setHeaders(proposalAniplex.filter(prop => checkParam[prop.id]));
    setCurrent(1);
  }, [checkParam]);

  // 再検索時とソート変更時は1ページ目に戻る
  useEffect(() => {
    setCurrent(1);
  }, [proposalList, sortInfo]);

  // テーブル用のレコードを生成
  const records = useMemo(() => {
    const proposals = proposalList?.result?.data ?? [];

    return proposals.map(proposal => {
      return {
        proposalId: proposal.proposalId,
        propertyIcon: proposal.iconFilename,
        propertyName: proposal.propertySummaryName,
        proposalStatus: proposal.proposalStatus,
        proposalNo: proposal.proposalNo,
        proposalTitle: proposal.proposalTitle,
        licenseeCode: proposal.licenseeCode,
        licenseeNameKanji: proposal.licenseeNameKanji,
        applicantUserName: proposal.applicantUserName,
        applicationDate: proposal.applicationDatetime,
        kikakuEmployeeNo: proposal.kikakuEmployeeName,
        sDecision: proposal.sDecisionNo,
        sTitle: proposal.sTitle,
        startDate: proposal.startDate,
        endDate: proposal.endDate,
        updateDate: proposal.updateDatetime,
        ryRemindFlag: proposal.ryRemindFlag,
      };
    });
  }, [proposalList]);

  // ソート関数
  const sortFunc = useMemo(() => {
    const sortKey = sortInfo.sortKey;
    // 運用順のソート
    if (sortKey === 'proposalStatus') {
      return (a, b) => statusListAniplex?.[a[sortKey]]?.index - statusListAniplex?.[b[sortKey]]?.index;
    }
    // 文字順ソート
    return (a, b) => {
      if ((a[sortKey] || '') > (b[sortKey] || '')) { return 1; }
      if ((a[sortKey] || '') < (b[sortKey] || '')) { return -1; }
      return 0;
    };
  }, [sortInfo.sortKey]);

  // ソート処理
  const sortedRecords = useMemo(() => {
    // ソートする
    const newer = immutableSort(records, sortFunc);
    // 降順なら逆にする
    if (sortInfo.order === 'desc') {
      newer.reverse();
    }
    return newer;
  }, [records, sortInfo.order, sortFunc]);

  // 表示用レコード
  const viewRecords = useMemo(() => {
    return sortedRecords.map(record => {

      // ステータスが強調表示の対象か
      const statusEmphasis = ProposalStatusEmphasis.includes(record.proposalStatus);
      const statusText = statusListAniplex[record.proposalStatus].text;

      /** 企画状況画面を表示可能か */
      const statusPageVisible = StatusVisibleProposalStatus.includes(record.proposalStatus);

      return {
        ...record,
        id: record.proposalId,
        propertyIcon: record.propertyIcon ? (
          <span className="db transition-img work-logo">
            <img src={Config.PropertyIconPath + record.propertyIcon} alt="作品ロゴ" style={{width: '60px'}} />
          </span>
        ) : '',
        proposalStatus: statusEmphasis ? (
          <span className="attention">{statusText}</span>
        ) : statusText,
        // @ts-expect-error
        proposalNo: (<Link to={'../proposalDetail/' + record.proposalId}>{record.proposalNo}</Link>),
        // @ts-expect-error
        licenseeCode: (<Link to={'../licenseeDetail/' + record.licenseeCode}>{record.licenseeCode}</Link>),
        applicationDate: String(record.applicationDate || '').substring(0, 10),
        updateDate: String(record.updateDate || '').substring(0, 10),
        statuses: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../proposalStatus/' + record.proposalId)} className="bg-yellow">参照</TableCellButton>
        ) : '',
        labels: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../labelRequestList/' + record.proposalNo)} className="bg-yellow">参照</TableCellButton>
        ) : '',
        royalties: statusPageVisible ? (
          <TableCellButton onClick={() => navigate('../royaltyReportList/' + record.proposalNo)} className="bg-yellow">参照</TableCellButton>
        ) : '',
        ryRemindFlag: record.ryRemindFlag ? '督促中' : '',
      };
    });
  }, [sortedRecords, navigate]);

  return (
    <div className="wrap">
      <ProposalListSearchForm
        onSearch={onSearch}
        params={searchParams}
        setParams={setSearchParams} />
      <TableVisible
        isOpen={isOpen}
        headers={proposalAniplex}
        checkParam={checkParam}
        onToggleClick={() => setIsOpen(isOpen => !isOpen)}
        onParamChange={setCheckParam}
        onApply={onCheckApply} />
      {Boolean(proposalList) && (
        <>
          {
            showReqCount && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '35px', marginBottom: '-10px' }}>
                <span className='attention' style={{ fontWeight: 'bold', marginRight: '10px' }}>申請中が{reqCount}件あります</span>
                <p className="btn bg-yellow" style={{ width: '120px' }}>
                  <button onClick={onSearchReq}>申請中を検索</button>
                </p>
              </div>
            )
          }
          <PagingTable
            headers={headers}
            records={viewRecords}
            current={current}
            pageSize={pageSize}
            sortParam={sortInfo}
            emptyMessage="検索条件と一致する企画情報がありません。"
            onSortChange={setSortInfo}
            onPageChange={setCurrent}
            verticalScrollable
            scrollable
            resizable />
        </>
      )}

      {loading}

    </div>
  );
};

//#region typedef
/**
 * @typedef {import('./ProposalListSearchForm').SearchFormData} SearchFormData
 */
//#endregion typedef
