//@ts-check
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { statusListRoyaltyAniplex } from '../../common/headerList';
import { selectPropertyMst } from '../../../slices/aniplex/masterSlice';
import { SearchableListBox } from '../../common/SearchableListBox';
import { useSaveFormData } from '../../../lib/hooks/common';
import { Constants } from '../../../Constants';

/**
 * 検索パラメーターの初期値
 * @type {SearchFormData}
 */
export const defaultParams = {
  proposalNo: '',
  proposalTitle: '',
  reportStatus: '',
  reportUserName: '',
  reportDateFrom: undefined,
  reportDateTo: undefined,
  propertySummaryCode: '',
  licenseeCode: '',
  licenseeNameKanji: '',
  filterCondition: 'ALL',
};

/**
 * クリア時の検索パラメータの値
 * @type {SearchFormData}
 */
const clearedParams = {
  ...defaultParams,
  filterCondition: 'PSO'
}

/**
 * 日付型のパラメータ
 * @satisfies {(keyof SearchFormData)[]}
 */
export const dateParams = [
  'reportDateFrom',
  'reportDateTo',
];

/**
 * [A]ロイヤリティ報告一覧の検索フォーム
 * @param {object} props
 * @param {(params: SearchFormData) => void} props.onSearch 検索実行時のコールバック
 * @param {SearchFormData} props.params 検索フォームデータ
 * @param {React.Dispatch<React.SetStateAction<SearchFormData>>} props.setParams 検索フォームデータ設定処理
 */
export const RoyaltyReportListSearchForm = ({ onSearch, params, setParams }) => {

  // 検索パラメータ保存機能
  const { saveData } = useSaveFormData({
    saveKey: Constants.Aniplex.SearchFormSaveKey.RoyaltyReportList,
    dataType: params,
    dateParams,
  });

  /** 作品マスタ */
  const propertyMst = useSelector(selectPropertyMst);

  /** 作品マスタ(申請可能) */
  const propertyMstShown = useMemo(() => {
    if (!propertyMst) {
      return propertyMst;
    }
    return propertyMst.filter(property => {
      return property.proposalFlag;
    });
  }, [propertyMst]);

  /** 検索項目の変更時 */
  const onParamUpdate = useCallback(({ target }) => {
    setParams(params => ({ ...params, [target.name]: target.value || '' }));
  }, [setParams]);

  /** 作品の変更時 */
  const onPropertyChange = useCallback((name, value) => {
    setParams(params => ({ ...params, [name]: value }));
  }, [setParams]);

  /** 日付型の検索項目の変更時 */
  const onDateChange = useCallback((name, date) => {
    setParams(params => ({ ...params, [name]: date }));
  }, [setParams]);

  /** 選択された作品 */
  const selectedProperty = useMemo(() => {
    const found = propertyMstShown.find(i => i.propertySummaryCode === params.propertySummaryCode);
    if (!found) {
      return null;
    }
    return {
      label: found.propertySummaryName,
      value: found.propertySummaryCode,
    };
  }, [propertyMstShown, params]);

  /** 検索ボタン押下時 */
  const onSearchClick = useCallback(() => {
    // 現在のパラメータを保存
    saveData(params);
    if (typeof onSearch !== 'function') {
      return;
    }
    // 検索パラメーターを整形(空文字列はundefined・日付はフォーマットする)
    onSearch(params);
  }, [saveData, params, onSearch]);

  /** クリアボタン押下時 */
  const onClearClick = useCallback(() => {
    const params = {...clearedParams};
    setParams(params);
  }, [setParams]);

  return (
    <>
      <div className="mt30 l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>企画書No</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="proposalNo" title="企画書Noを入力してください" aria-label="企画書No"
                value={params.proposalNo}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">企画件名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="proposalTitle" title="企画件名を選択してください" aria-label="企画書No"
                value={params.proposalTitle}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>ロイヤリティ報告ステータス</dt>
          <dd className="form-body">
            <div className="form-select wdt140">
              <select name="reportStatus" title="ロイヤリティ報告ステータスを選択してください"
                onChange={onParamUpdate}
                value={params.reportStatus}>
                <option value=""></option>
                {Object.keys(statusListRoyaltyAniplex).map(i => (
                  <option value={i} key={i}>{statusListRoyaltyAniplex[i].text}</option>
                ))}
              </select>
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告者</dt>
          <dd className="form-body">
            <div className="input-form wdt200">
              <input type="text" name="reportUserName" title="報告者を入力してください" aria-label="報告者"
                value={params.reportUserName}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">報告日</dt>
          <dd className="form-body df a-center">
            <div className="input-form input-calendar mr10">
              <DatePicker
                name="reportDateFrom"
                title="報告開始日を入力してください"
                aria-label="申請開始日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.reportDateFrom}
                onChange={date => onDateChange('reportDateFrom', date)} />
            </div>
            <p>〜</p>
            <div className="input-form input-calendar ml10">
              <DatePicker
                name="reportDateTo"
                title="報告終了日を入力してください"
                aria-label="報告終了日"
                dateFormat='yyyy/MM/dd'
                locale='ja'
                selected={params.reportDateTo}
                onChange={date => onDateChange('reportDateTo', date)} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>作品名称</dt>
          <dd className="form-body">
            <div className="input-form wdt650">
              {/* @ts-expect-error */}
              <SearchableListBox
                // @ts-expect-error
                isDisabled={false}
                value={selectedProperty}
                data={propertyMstShown}
                onChange={item => onPropertyChange('propertySummaryCode', item?.value)}
                labelKey='propertySummaryName'
                valueKey='propertySummaryCode'
                katakanaKey='propertySummaryNameKana'
                hankakuKanaKey='propertySummaryNameHankakuKana'
                hiraganaKey='propertySummaryNameHiragana' />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{width: '200px'}}>取引先コード</dt>
          <dd className="form-body">
            <div className="input-form wdt140">
              <input type="text" name="licenseeCode" title="取引先コードを入力してください" aria-label="取引先コード"
                value={params.licenseeCode}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>

        <dl className="form-set">
          <dt className="form-name">取引先名</dt>
          <dd className="form-body">
            <div className="input-form wdt600">
              <input type="text" name="licenseeNameKanji" title="取引先名を入力してください" aria-label="取引先名"
                value={params.licenseeNameKanji}
                onInput={onParamUpdate} />
            </div>
          </dd>
        </dl>
      </div>

      <div className="l-form">
        <dl className="form-set">
          <dt className="form-name" style={{ width: "140px" }}>絞り込み</dt>
          <dd className="form-body">
            <ul className="list-form df">
              <li>
                <input
                  id="filterConditionALL"
                  type="radio"
                  name="filterCondition"
                  aria-label="すべて"
                  value="ALL"
                  checked={params.filterCondition === 'ALL'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionALL" className="form-radio">すべて</label>
              </li>
              <li>
                <input
                  id="filterConditionPSO"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当作品のみ表示"
                  value="PSO"
                  checked={params.filterCondition === 'PSO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPSO" className="form-radio">自分の担当作品のみ表示</label>
              </li>
              <li>
                <input
                  id="filterConditionPP0"
                  type="radio"
                  name="filterCondition"
                  aria-label="自分の担当企画のみ表示"
                  value="PPO"
                  checked={params.filterCondition === 'PPO'}
                  onChange={onParamUpdate}
                />
                <label htmlFor="filterConditionPP0" className="form-radio">自分の担当企画のみ表示</label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="l-buttons mt20">
        <p className="btn label bg-yellow" style={{width: '160px'}}>
          <button type="button" onClick={onSearchClick}><i className="icn search"></i>検索</button>
        </p>
        <p className="btn label c-aniplex" style={{width: '160px'}}>
          <button type="button" onClick={onClearClick}><i className="icn cross"></i>クリア</button>
        </p>
      </div>
    </>
  );
};

//#region typedef
/**
 * @typedef {object} SearchFormData 検索フォームデータ
 * @property {string} proposalNo 企画No※部分一致検索
 * @property {string} proposalTitle 企画件名※部分一致検索
 * @property {valueOf<Constants['Aniplex']['reportStatus']>|''} reportStatus ロイヤリティ報告ステータス
 * TMP: 修正中
 * REQ: 申請中
 * REJ: 差し戻し中
 * APP: 0円報告済み
 * EXP: 売上送信済み
 * @property {string} reportUserName 報告者氏名 ※部分一致検索
 * @property {Date|null|undefined} reportDateFrom 報告日From
 * @property {Date|null|undefined} reportDateTo 報告日To
 * @property {string} propertySummaryCode 作品コード
 * @property {string} licenseeCode 取引先コード※部分一致検索
 * @property {string} licenseeNameKanji 取引先名（漢字）※部分一致検索
 * @property {'ALL'|'PSO'|'PPO'} filterCondition 絞り込み条件
 * ALL: すべて
 * PSO: 自分の担当作品のみ表示
 * PPO: 自分の担当企画のみ表示
 */
/**
 * @typedef {typeof import('../../../Constants').Constants} Constants
 */
/**
 * @typedef {T[keyof T]} valueOf
 * @template T
 */
//#endregion typedef
