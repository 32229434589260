//@ts-check
import React, { forwardRef, useCallback } from "react";

/** 制御文字の正規表現 */
// eslint-disable-next-line no-control-regex
const ctrlRegex = /[\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/g;

/**
 * 制御文字を自動的に除去するテキストエリア
 */
export const SafeCharTextArea = forwardRef(
  /**
   * @param {ParamType} params
   * @param {React.ForwardedRef<any>} ref
   * @returns
   */
  ({ value, onChange, ...props }, ref) => {

  /** changeイベントのハンドラ */
  const handleChange = useCallback(val => {
    if (ctrlRegex.test(val)) {
      val = val.replace(ctrlRegex, '');
    }
    onChange(val);
  }, [onChange]);

  return (
    <textarea
      value={value}
      ref={ref}
      onChange={ev => handleChange(ev.target.value)}
      {...props} />
  )
})

/**
 * @typedef {React.DetailedHTMLProps<React.TextareaHTMLAttributes, HTMLTextAreaElement> & { onChange: OnChange }} ParamType
 */
/**
 * @callback OnChange
 * @param {string} value
 */
